import React, { useState, useEffect } from "react";
import "./index.scss";
import Logo from "../../../assets/toyota-logo.svg";
import { useData } from "../../../utils/dataProvider";
import axios from "axios";
export default function Login() {
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const { doLogin, setError, setLoading, error } = useData();
    function submitForm(e) {
        setError(null);
        setLoading(true);
        const form = new FormData();
        form.append("username", email);
        form.append("password", password);
        const url = "https://api.toyota-fusion.de/v1/authenticate";
        axios({
            method: "post",
            url: url,
            data: form,
        })
            .then((response) => {
                doLogin(response);
            })
            .catch((error) => {
                setError("Die eingegebenen Daten sind nicht korrekt.");
                setLoading(false);
            });
    }

    useEffect(() => {
        const keyDownHandler = (event) => {
            if (event.key === "Enter") {
                event.preventDefault();
                submitForm();
            }
        };

        document.addEventListener("keydown", keyDownHandler);

        return () => {
            document.removeEventListener("keydown", keyDownHandler);
        };
    }, [email, password]);

    return (
        <form className='login' onSubmit={submitForm}>
            <div className='login-box'>
                <div className='login-title'>
                    <img className='toyota-logo' src={Logo}></img>
                    <div>Kampagnenmanagement</div>
                </div>
                <div className='login-label'>E-Mail Adresse</div>
                <input
                    className='login-input'
                    value={email}
                    name='email'
                    onChange={(e) => setEmail(e.target.value)}></input>
                <div className='login-label'>Passwort</div>
                <input
                    type='password'
                    className='login-input'
                    name='password'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}></input>

                <div
                    onClick={submitForm}
                    style={{ padding: "12px 0" }}
                    className='toyota-button-red toyota-button login-button'>
                    Anmelden
                </div>

                <div
                    style={{
                        marginTop: 4,
                        textAlign: "center",
                        color: "#ff0022",
                    }}>
                    {error}
                </div>
                <div className='login-password-forget'>Passwort vergessen</div>
            </div>
        </form>
    );
}
