import React from "react";
import Questionmark from "../images/question.svg";

export default function Tooltip({ text, small }) {
  return (
    <div className={`Tooltip${small ? " small" : ""}`}>
      <div className="questionmark">
        <img src={Questionmark} alt="Tooltip" />
      </div>
      <div className="info">{text}</div>
    </div>
  );
}
