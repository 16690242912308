import React, { useEffect, useState } from "react";
import { Formik, Field, Form, useFormikContext } from "formik";
import axios from "axios";
import { url } from "../../../utils/configs";
import { useParams } from "react-router-dom";
export default function Input({
    label,
    name,
    type,
    placeholder,
    number,
    required,
    value,
    ...props
}) {
    const { setFieldValue } = useFormikContext();
    const { id } = useParams();
    useEffect(() => {
        let token = localStorage.getItem("token");

        axios
            .get(url + "/detail/files/id", {
                headers: {
                    Authorization: "Bearer " + token,
                },
            })
            .then((res) => {});
    }, []);

    return (
        <div
            className={`aktion-input ${
                required && !value ? "required-field" : ""
            }`}>
            <label htmlFor={name}>{label}</label>
            <input
                id={name}
                name={name}
                type='file'
                onChange={(event) => {
                    setFieldValue(name, event.currentTarget.files[0]);
                }}
            />
        </div>
    );
}
