const requiredFields = [
    "detailName",
    "unitCampaignTarget",
    "valueCampaignTarget",
    "unitPreviousTarget",
    "valuePreviousTarget",
    "unitPreviousGoal",
    "valuePreviousGoal",
    "forecastSubmittedDate",
    "forecastReportedBack",
    "forecastNumber",
    "npaCampaignCreationDate",
    "npaCampaignCode",
    "unitDiscount",
    "valueDiscount",
    "tipPoints",
    "tipDescription",
];
export { requiredFields };
