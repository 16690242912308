import React from "react";

export default function Acc({ data }) {
  if (!data) return "";
  return (
    <table className="info-table">
      <tr>
        <td>Teilenummer</td>
        <td>{data.number}</td>
      </tr>
      <tr>
        <td>Name</td>
        <td>{data.partname}</td>
      </tr>
      <tr>
        <td>Kategorie</td>
        <td>{data.category} </td>
      </tr>
      <tr>
        <td>Inhalt</td>
        <td>{data.content} </td>
      </tr>
      <tr>
        <td>Beschreibung</td>
        <td>{data.description} </td>
      </tr>
      <tr>
        <td>Hinweis</td>
        <td>{data.remark} </td>
      </tr>
      <tr>
        <td>Abmessungen</td>
        <td>{data.dimensions} </td>
      </tr>
      <tr>
        <td>Gewicht</td>
        <td>{data.weight} </td>
      </tr>
      <tr>
        <td>Farbe</td>
        <td>{data.colour} </td>
      </tr>
    </table>
  );
}
