import React, { useState } from "react";
import "./style.scss";
import { useData } from "../../../../utils/dataProvider";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import Loader from "../../../commonComponents/Loading";
import ReactTooltip from "react-tooltip";
import Modal from "./hochladenModal";
import Delete from "../../../../assets/delete.svg";
import Copy from "../../../../assets/copy.svg";
import Tools from "../../../../assets/tools.svg";
import LoscheModal from "../../../commonComponents/LoscheModal";
import { FileIcon, defaultStyles } from "react-file-icon";

export default function Item({ data, id, update, setUpdate, location }) {
  const [modalVisible, setModalVisible] = useState();
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  function getFileExtension(name) {
    return name?.split(".")?.pop();
  }

  function copyToClipboard(text) {
    window.prompt("Copy to clipboard: Ctrl+C, Enter", text);
  }

  useEffect(() => {
    if (copySuccess) {
      setTimeout(() => {
        setCopySuccess(false);
      }, 3000);
    }
  }, [copySuccess]);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [copySuccess]);

  function isImage(name) {
    const extension = getFileExtension(name);
    if (
      extension.includes("png") ||
      extension.includes("jpg") ||
      extension.includes("svg") ||
      extension.includes("gif") ||
      extension.includes("jpeg")
    ) {
      return true;
    }
  }

  return (
    <div className="item-container">
      <ReactTooltip effect="solid" scrollHide={true} />
      <div className="item-info">
        <div>
          Typ:{" "}
          <b>
            {data.fileType}
            {" (" + data.allowedExtensions + ")"}
          </b>
        </div>
        <div>
          Bezeichnung: <b>{data.Content}</b>
        </div>
        <div>
          Beschreibung: <b>{data.Description}</b>
        </div>

        <div>
          Dateiname: <b>{data.originalName}</b>
        </div>
        <div>
          Gültig von:{" "}
          <b>{data.validFrom == "null" ? "" : formatDate(data.validFrom)}</b>
        </div>
        <div>
          Gültig bis:{" "}
          <b>{data.validTo == "null" ? "" : formatDate(data.validTo)}</b>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          alignItems: "flex-end",
        }}
      >
        <div className="icon-set">
          <div className="tools">
            <img
              src={Delete}
              data-tip={"Datei löschen"}
              onClick={() => setDeleteModalVisible(true)}
            ></img>
            <img
              src={Tools}
              data-tip={"Datei bearbeiten"}
              onClick={() => {
                setModalVisible(true);
              }}
            ></img>
            <img
              src={Copy}
              data-tip={copySuccess ? "Success" : "Copy zu clipboard"}
              onClick={() => {
                navigator.clipboard.writeText(data.fileUrl);
                setCopySuccess(true);
              }}
            ></img>
          </div>
        </div>
        <div className="vorschau">
          {isImage(data.originalName) ? (
            <img
              src={data.fileUrl}
              style={{ maxHeight: 80, maxWidth: 200, marginTop: 12 }}
            ></img>
          ) : (
            <FileIcon
              extension={getFileExtension(data.originalName)}
              height={26}
              {...defaultStyles[getFileExtension(data.originalName)]}
            />
          )}
        </div>
      </div>

      {modalVisible && (
        <Modal
          data={data}
          setModalVisible={setModalVisible}
          id={id}
          update={update}
          location={location}
          setUpdate={setUpdate}
        ></Modal>
      )}
      {deleteModalVisible && (
        <LoscheModal
          setDeleteModalVisible={setDeleteModalVisible}
          kat={location == "campaign" ? "Kampagnen Files" : "Detail Files"}
          name={data.Content}
          entryID={data.fileID}
          updated={update}
          setUpdated={setUpdate}
        />
      )}
    </div>
  );
}
