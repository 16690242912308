import React, { useEffect, useState } from "react";
import { useData } from "../../../../../utils/dataProvider";
import Save from "../../../../../assets/save.svg";
import Close from "../../../../../assets/close.svg";
import * as XLSX from "xlsx";
import Loading from "../../../../commonComponents/Loading";

export default function ArticleModal({
  setShowArticleModal,
  setShowTable,
  modelID,
  getArticles,
  discount,
}) {
  const [importing, setImporting] = useState(false);
  const [tempArticles, setTempArticles] = useState([]);
  const [number, updateNumber] = useState("");
  const { makeApiPostRequest, makeApiRequest } = useData();
  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState(null);

  async function uploadArticles() {
    if (!number.length) return;

    const success = await makeApiPostRequest(`/item/${modelID}`, {
      number: number,
      discountPercent: parseFloat(discount).toFixed(2),
    });

    if (success) {
      setImporting(false);
      setShowArticleModal(false);
      getArticles();
    } else {
      alert("Fehler beim Upload");
      setShowArticleModal(false);
    }
  }

  async function getDetailModels() {
    setLoading(true);
    const modelRequest = await makeApiRequest(`/accessories/search/${number}`);
    const modelRequest2 = await makeApiRequest(`/parts/partinfo/${number}`);
    const modelRequest3 = await makeApiRequest(`/parts/priceinfo/${number}`);
    let itemInfo = {};
    itemInfo.priceInfo = modelRequest3;
    itemInfo.originalName = modelRequest2.originalName;
    itemInfo.category = modelRequest2.pfcDescription;

    if (itemInfo) {
      setItem(itemInfo);
    }

    setLoading(false);
  }

  useEffect(() => {
    if (number.length > 7) {
      getDetailModels();
    } else {
      setItem(null);
    }
  }, [number]);

  function doMoney(float) {
    return float?.toLocaleString("de-DE", {
      style: "currency",
      currency: "EUR",
    });
  }

  return (
    <div className="modal-wrapper">
      <div className="modal-window">
        <img
          className="close"
          onClick={() => setShowArticleModal(false)}
          src={Close}
        ></img>
        <div
          className="aktion-formular-content"
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <h1>Artikel importieren</h1>
          <div></div>
          <div className="fields">
            <div className="section-block">
              <div className="section-title">Daten</div>
              <div className="section-form">
                <div className={`aktion-input`}>
                  <label htmlFor={"article-upload"}>Artikelnummer:</label>
                  <input
                    name="article-upload"
                    type="text"
                    value={number}
                    onChange={(e) => updateNumber(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
          {item && (
            <table>
              <thead>
                <tr>
                  <th>Kategorie</th>
                  <th>Bezeichnung</th>
                  <th>UPE</th>
                  <th>WAP</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{item?.category}</td>
                  <td>{item?.originalName}</td>
                  <td>{doMoney(item?.priceInfo?.grossSalePrice)}</td>
                  <td>{doMoney(item?.priceInfo?.retailPrice)}</td>
                </tr>
              </tbody>
            </table>
          )}

          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            {importing ? (
              <Loading height="20px" width="20px" />
            ) : (
              <button
                type="button"
                onClick={() => uploadArticles()}
                className="toyota-button toyota-button-red"
                style={{
                  marginLeft: 16,
                  marginTop: 16,
                  alignSelf: "end",
                  opacity: number.length > 3 ? 1 : 0.5,
                }}
              >
                <img
                  style={{
                    marginRight: "12px",
                    height: "20px",
                  }}
                  src={Save}
                ></img>
                Artikel erstellen
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
