import React, { useState, useEffect } from "react";
import "./style.scss";
import { useData } from "../../../../utils/dataProvider";
import * as XLSX from "xlsx";
import { url } from "../../../../utils/configs";
import axios from "axios";
import Loading from "../../../commonComponents/Loading";

export default function AllExport({ models }) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    if (models) {
      let array = [];
      models.forEach((element) => {
        array.push(element.articles);
      });

      setData(array);
      setLoading(false);
    }
  }, [models]);
  function ExportExcel() {
    let workbook = XLSX.utils.book_new();

    data.map((articles) => {
      let sheetArray = [
        [
          "Bezeichnung",
          "Teilennummer",
          "Aktionsrabatt",
          "UPE",
          "WAP",
          "Aktion",
          "Preisvorteil",
        ],
      ];
      articles.map((article) => {
        sheetArray.push([
          article.name ? article.name : article.originalName,
          article.number,
          article.discountPercent,
          article.grossSalePrice,
          article.retailPrice,
          article.retailPrice -
            (parseFloat(article.retailPrice) *
              parseFloat(article.discountPercent)) /
              100,
          (parseFloat(article.retailPrice) *
            parseFloat(article.discountPercent)) /
            100,
        ]);
      });
      var worksheet = XLSX.utils.aoa_to_sheet(sheetArray);
      XLSX.utils.book_append_sheet(workbook, worksheet, articles.parentEntry);
    });

    XLSX.writeFile(workbook, "AlleArtikel.xlsx");
  }

  return (
    <div
      onClick={ExportExcel}
      className="toyota-button toyota-button-blue"
      style={{ marginRight: 12, fontSize: 14 }}
    >
      {loading ? (
        <Loading height="20px" width="20px" />
      ) : (
        "Alle Artikel Exportieren"
      )}
    </div>
  );
}
