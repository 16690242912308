import React, { useEffect, useState } from "react";
import SaveGrey from "../../../../assets/saveGrey.svg";
import Delete from "../../../../assets/delete.svg";

import { useData } from "../../../../utils/dataProvider";
import StaffelModal from "./ArticleModal/StaffelModal";
import LoscheModal from "../../../commonComponents/LoscheModal";
import TeilnummerModal from "./TeilnummerModal";
import Warning from "../../../../assets/warning.svg";
import ArtikelChangedModal from "./ArticleModal/ArtikelChangedModal";
export default function Article({
  item,
  update,
  setUpdate,
  index,
  articleDiscountArray,
  setArticleDiscountArray,
  articleChecked,
  setArticleChecked,
  sortedArtikel,
  setSortedArtikel,
  setArticles,
}) {
  const [value, setValue] = useState(parseFloat(item.discountPercent));
  const [oldValue, setOldValue] = useState(parseFloat(item.discountPercent));
  const [discounted, setDiscounted] = useState(
    item.retailPrice - (value * item.retailPrice) / 100
  );
  const [staffels, setStaffels] = useState(null);

  const [showStaffelModal, setShowStaffelModal] = useState(false);
  const [staffelChanged, setStaffelChanged] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [changeModalVisible, setChangeModalVisible] = useState(false);
  const [warningModal, setWarningModal] = useState(false);

  useEffect(() => {
    const newPrice = item.retailPrice - (value * item.retailPrice) / 100;
    setDiscounted(newPrice);
  }, [value, sortedArtikel]);

  const { makeApiPutRequest, makeApiRequest } = useData();

  async function updateArticle() {
    const upload = await makeApiPutRequest(`/item/${item.entryID}`, {
      discountPercent: parseFloat(value).toFixed(2),
    });
    if (upload) {
      setOldValue(value);
      sortedArtikel[index].discountPercent = value;
    } else {
      alert("Fehler beim Uploaden");
    }
  }
  function doMoney(float) {
    return float?.toLocaleString("de-DE", {
      style: "currency",
      currency: "EUR",
    });
  }

  async function fetchStaffels() {
    const apiData = await makeApiRequest("/itemScales/" + item.entryID);
    setStaffels(apiData.data.length > 0 ? apiData.data.length : null);
  }

  useEffect(() => {
    fetchStaffels();
  }, [staffelChanged]);

  useEffect(() => {
    setValue(parseFloat(item.discountPercent));
    setOldValue(parseFloat(item.discountPercent));
  }, [item]);

  useEffect(() => {
    sortedArtikel[index].aktion = discounted;
  }, [discounted, sortedArtikel]);

  return (
    <>
      <tr key={item.entryID}>
        <td>
          <input
            type="checkbox"
            value={value}
            checked={articleChecked[index]}
            onChange={() => {
              let array = [...articleChecked];
              if (articleChecked[index]) {
                array[index] = false;
              } else {
                array[index] = true;
              }
              setArticleChecked([...array]);
            }}
          />
        </td>
        <td>{item.name ? item.name : item.originalName}</td>
        {item.substituteNumber ? (
          <td style={{ fontSize: 14 }}>
            <div style={{ display: "flex" }}>
              <div>
                <div
                  style={{
                    width: "fit-content",
                    borderBottom: "1px solid #ff0022",
                    lineHeight: 0.1,
                  }}
                >
                  {item.number}
                </div>
                <div style={{ marginTop: 4, color: "#ff0022" }}>
                  {item.substituteNumber}
                </div>
              </div>
              <img
                style={{ cursor: "pointer" }}
                onClick={() => setChangeModalVisible(true)}
                src={SaveGrey}
              ></img>
            </div>
          </td>
        ) : (
          <td>{item.number}</td>
        )}

        <td>
          <div style={{ display: "flex", alignItems: "center" }}>
            <input
              onChange={(e) => {
                setValue(e.target.value);
                let array = [...articleDiscountArray];
                array[index] = e.target.value;
                setArticleDiscountArray([...array]);
              }}
              className="rabatt-edit"
              type="number"
              value={value}
            />
            %
            {oldValue != value && (
              <button type="button" onClick={() => updateArticle()}>
                <img src={SaveGrey} />
              </button>
            )}
          </div>{" "}
        </td>
        <td>{doMoney(item.grossSalePrice)}</td>
        <td>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              onClick={() => setShowStaffelModal(true)}
              data-tip={"Staffel hinzufügen"}
              style={{
                height: 26,
                width: 26,
                borderRadius: 32,
                paddingBottom: 4,
                backgroundColor: "#eee",
                fontSize: 20,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              {staffels ? staffels : "+"}
            </div>
          </div>
        </td>
        <td>{doMoney(item.retailPrice)}</td>
        <td>{doMoney(item.tdg_ek)}</td>
        <td>{doMoney(discounted)}</td>
        <td>{doMoney(item.retailPrice - discounted)}</td>

        <td>
          {item.r_a_code && (
            <div className="ra_code" data-tip={item.racDescription}>
              {item.r_a_code}
            </div>
          )}
        </td>

        <td>
          <div
            className="tos-status"
            style={{
              background: item.inTOS == "false" ? "#ff0022" : "#48EC05",
              height: 12,
              width: 12,
              borderRadius: "50%",
            }}
          ></div>
        </td>
        <td>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
            }}
          >
            <button type="button">
              <img
                src={Delete}
                alt="Löschen"
                onClick={() => setDeleteModalVisible(true)}
              />
            </button>

            {item.changed && item.changed !== "null" && (
              <button type="button">
                <img
                  src={Warning}
                  alt="Löschen"
                  onClick={() => setWarningModal(true)}
                  style={{ marginLeft: 2 }}
                />
              </button>
            )}
          </div>
        </td>
      </tr>
      {showStaffelModal && (
        <StaffelModal
          setShowStaffelModal={setShowStaffelModal}
          item={item}
          staffelChanged={staffelChanged}
          setStaffelChanged={setStaffelChanged}
        />
      )}
      {deleteModalVisible && (
        <LoscheModal
          setDeleteModalVisible={setDeleteModalVisible}
          kat="Artikel Item"
          name={item.name ? item.name : item.originalName}
          entryID={item.entryID}
          updated={update}
          setUpdated={setUpdate}
        />
      )}
      {changeModalVisible && (
        <TeilnummerModal
          setDeleteModalVisible={setChangeModalVisible}
          kat="Artikel Item"
          name={item.name ? item.name : item.originalName}
          entryID={item.entryID}
          updated={update}
          setUpdated={setUpdate}
          item={item}
        />
      )}
      {warningModal && (
        <ArtikelChangedModal
          setDeleteModalVisible={setWarningModal}
          kat="Artikel Item"
          name={item.name ? item.name : item.originalName}
          entryID={item.entryID}
          updated={update}
          setUpdated={setUpdate}
          item={item}
        />
      )}
    </>
  );
}
