import React, { useEffect, useState } from "react";
import {
  ActionForm,
  DatePicker,
  DateRangePicker,
  Input,
  MutiSelect,
  Select,
  TextArea,
  UrlID,
  ArticleUpload,
  SetUpload,
  Checkbox,
  Upload,
} from "./index";
import { Formik, Field, Form, useFormikContext } from "formik";
import { requiredFields } from "../data/requiredFIeld";
import { useLocation } from "react-router-dom";

export default function FieldGroup({ data, values, noValidate }) {
  const [toggle, setToggle] = useState(true);
  const [relevant, setRelevant] = useState(false);
  const [relevantName, setRelevantName] = useState("");

  const location = useLocation();
  const { setFieldValue } = useFormikContext();
  const checkRequired = (name) => {
    if (noValidate) {
      return false;
    }
    if (location.pathname.indexOf("detail")) {
      return requiredFields.detail.indexOf(name) > -1;
    } else if (location.pathname.indexOf("kampagne")) {
      return requiredFields.campaign.indexOf(name) > -1;
    } else {
      return false;
    }
  };
  useEffect(() => {
    data &&
      data.subfields.map((field, index) => {
        if (field.name.indexOf("Relevant") > 0) {
          setRelevant(true);
          setRelevantName(field.name);
          if (values?.[field.name] == true || values?.[field.name] == "true") {
            setToggle(true);
          } else {
            setToggle(false);
          }
        }
      });
  }, [data]);

  const FieldComponent = (field, index) => {
    let required = checkRequired(field.name);

    if (field.name.indexOf("Relevant") > 0) {
      return;
    }
    switch (field.type) {
      case "text":
        return (
          <Input
            key={`field-sub-${index}`}
            value={values[field.name] ? values[field.name] : ""}
            label={field.label}
            name={field.name}
            required={required}
          ></Input>
        );
        break;
      case "input":
        return (
          <Input
            key={`field-sub-${index}`}
            value={values[field.name] ? values[field.name] : ""}
            label={field.label}
            name={field.name}
            required={required}
          ></Input>
        );
        break;
      case "number":
        return (
          <Input
            key={`field-sub-${index}`}
            value={values[field.name] ? values[field.name] : ""}
            required={required}
            label={field.label}
            name={field.name}
            type={"number"}
          ></Input>
        );
        break;
      case "checkbox":
        return (
          <Checkbox
            key={`field-sub-${index}`}
            value={values?.[field.name]}
            required={required}
            label={field.label}
            name={field.name}
            type={"checkbox"}
          ></Checkbox>
        );
        break;
      case "urlid":
        return (
          <UrlID
            key={`field-sub-${index}`}
            required={required}
            label={field.label}
            name={field.name}
          ></UrlID>
        );
        break;
      case "select":
        return (
          <Select
            field={field}
            values={values}
            key={`field-sub-${index}`}
            value={values?.[field.name]}
            required={required}
            label={field.label}
            name={field.name}
            options={field.options}
          ></Select>
        );
        break;
      case "daterange":
        return (
          <DateRangePicker
            key={`field-sub-${index}`}
            value={values?.[field.name]}
            required={required}
            label={field.label}
            name={field.name}
          ></DateRangePicker>
        );
        break;
      case "datetime":
        return (
          <DatePicker
            key={`field-sub-${index}`}
            value={values?.[field.name]}
            values={values}
            required={required}
            label={field.label}
            name={field.name}
          ></DatePicker>
        );
        break;
      case "textarea":
        return (
          <TextArea
            key={`field-sub-${index}`}
            value={values?.[field.name]}
            required={required}
            id={field.name}
            label={field.label}
            name={field.name}
          ></TextArea>
        );
        break;
      case "fieldgroup":
        return <FieldGroup key={`field-sub-${index}`} data={field} />;
        break;
      case "multiselect":
        return (
          <MutiSelect
            key={`field-sub-${index}`}
            source={field.dataSource}
            value={values?.[field.name]}
            required={required}
            label={field.label}
            options={field.options}
            name={field.name}
          ></MutiSelect>
        );
        break;

      default:
        return null;
    }
  };

  return (
    <div className="field-group">
      <div className="field-group-title">
        <div>{data.label}</div>
        {relevant && (
          <div>
            <input
              type="checkbox"
              name="rev"
              id={`rev-${data.label}`}
              checked={toggle}
              onChange={() => {
                setToggle(!toggle);
                setFieldValue(relevantName, !toggle);
              }}
            />
            <label htmlFor={`rev-${data.label}`}>Relevant</label>
          </div>
        )}
      </div>
      <div className="field-group-content">
        {data.subfields &&
          toggle &&
          data.subfields.map((field, index) => {
            return FieldComponent(field, index);
          })}
      </div>
    </div>
  );
}
