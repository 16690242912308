import React, { useState, useRef } from "react";
import "./style.scss";
import ModelModal from "./ModelModal/ModelModal";
import { useData } from "../../../../utils/dataProvider";
import { useParams } from "react-router-dom";
import Articles from "./Articles";
import Preview from "./Preview";
import { useEffect } from "react";
import Loader from "../../../commonComponents/Loading";
import ReactTooltip from "react-tooltip";
import AllExport from "./AllExport";
import { TabelHeadArray } from "./TabelHeadArray";
import PreviewSummary from "./Preview/PreviewSummary";
import { useArticleStore } from "./Articlestore";
export default function ArticleUpload({ detail }) {
  const countref = useRef();
  const [showModelModal, setShowModelModal] = useState(false);
  const { makeApiRequest } = useData();
  const [view, setView] = useState(0);
  const { models, fetchData, loading } = useArticleStore();

  const [updated, setUpdated] = useState(false);
  const { id } = useParams();
  async function getDetailModels() {
    const modelRequest = await makeApiRequest(`/models/${id}`);
  }

  useEffect(() => {
    fetchData(id);
  }, [updated]);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [models]);

  if (!detail) {
    return;
  }

  return (
    <div className="fusion-article-upload">
      <ReactTooltip effect="solid" scrollHide={true} />

      <div className="action-header" style={{ padding: "1rem" }}>
        <h2>Artikel</h2>
        <div style={{ display: "flex" }}>
          <div className="view-switch">
            <button
              type="button"
              className={`toyota-button toyota-button-blue ${
                view === 0 ? "active" : ""
              }`}
              onClick={() => setView(0)}
            >
              Bearbeitung
            </button>
            <button
              type="button"
              className={`toyota-button toyota-button-blue ${
                view === 1 ? "active" : ""
              }`}
              onClick={() => setView(1)}
            >
              Vorschau
            </button>
          </div>
          <AllExport models={models}></AllExport>
          <button
            type="button"
            onClick={() => setShowModelModal(true)}
            className="toyota-button toyota-button-blue"
          >
            Modell hinzufügen
          </button>
        </div>
      </div>
      {showModelModal && (
        <ModelModal
          getDetailModels={fetchData}
          setShowModelModal={setShowModelModal}
        />
      )}
      {view === 0 && (
        <div>
          {models && !loading ? (
            models.map((model, idx) => {
              return (
                <Articles
                  model={model}
                  discount={detail.valueDiscount}
                  key={model.entryID}
                  modelID={model.entryID}
                  modelName={model.modelName}
                  artikelUpdated={updated}
                  setArtikelUpdated={setUpdated}
                  getDetailModels={fetchData}
                />
              );
            })
          ) : (
            <div style={{ paddingLeft: 16, margin: "12px 0" }}>
              <Loader />
            </div>
          )}
        </div>
      )}
      {view === 1 && (
        <div>
          <PreviewSummary countref={countref} />
          {models ? (
            models.map((model, idx) => {
              return (
                <Preview
                  countref={countref}
                  model={model}
                  discount={detail.valueDiscount}
                  key={model.entryID}
                  modelID={model.entryID}
                  modelName={model.modelName}
                  artikelUpdated={updated}
                  setArtikelUpdated={setUpdated}
                  getDetailModels={fetchData}
                />
              );
            })
          ) : (
            <div style={{ paddingLeft: 16, margin: "12px 0" }}>
              <Loader />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
