import React, { useState, useEffect } from "react";
import Close from "../../assets/close.svg";
import { useData } from "../../utils/dataProvider";
import axios from "axios";
import { url } from "../../utils/configs";
import { useLocation } from "react-router-dom";
export default function LoscheModal({
    setDeleteModalVisible,
    kat,
    name,
    entryID,
    setData,
    updated,
    setUpdated,
    refreshfunction,
    currentPlan,
}) {
    const location = useLocation();
    const deleteSubmit = () => {
        let katurl = "";
        switch (kat) {
            case "Kampagnen":
                katurl = "/campaign/";
                break;
            case "Aktion":
                katurl = "/action/";
                break;
            case "Detail":
                katurl = "/detail/";
                break;
            case "Artikel Item":
                katurl = "/item/";
                break;
            case "Kampagnen Files":
                katurl = "/campaign/files/";
                break;
            case "Detail Files":
                katurl = "/detail/files/";
                break;
            case "Set Item":
                katurl = "/itemsetCart/";
                break;
            case "Artikel Model":
                katurl = "/model/";
                break;
            case "Set Model":
                katurl = "/itemset/";
                break;
        }
        const token = localStorage.getItem("token");

        axios
            .delete(url + katurl + entryID, {
                headers: {
                    Authorization: "Bearer " + token,
                },
            })
            .then((res) => {
                setDeleteModalVisible(false);
                if (setUpdated) {
                    setUpdated(!updated);
                }
                if (refreshfunction) {
                    refreshfunction();
                }

                if (currentPlan) {
                    axios
                        .get(url + "/msplan/" + currentPlan.value, {
                            headers: {
                                Authorization: "Bearer " + token,
                            },
                        })
                        .then((response) => {
                            if (setData) {
                                setData(response.data?.data[0]?.child);
                            }
                        });
                }
            });
    };

    return (
        <div className='modal-wrapper'>
            <div className='modal-window'>
                <img
                    className='close'
                    src={Close}
                    onClick={() => setDeleteModalVisible(false)}></img>
                <h2>{kat} löschen</h2>
                Möchten Sie <b>{name}</b> löschen?
                <div
                    style={{
                        display: "flex",
                        marginTop: 32,
                        justifyContent: "flex-end",
                    }}>
                    <div
                        className='toyota-button toyota-button-grey'
                        onClick={() => setDeleteModalVisible(false)}
                        style={{ marginRight: 16, width: 120 }}>
                        Abbrechen
                    </div>
                    <div
                        onClick={deleteSubmit}
                        className='toyota-button toyota-button-red'
                        style={{ width: 120 }}>
                        Löschen
                    </div>
                </div>
            </div>
        </div>
    );
}
