import React, { useEffect } from "react";
import Tooltip from "./Tooltip";
import { useDataStore } from "../utils/ZutsandStore";
export default function Top() {
  const data = useDataStore((state) => state.data);
  const previousData = useDataStore((state) => state.previousData);
  const reporting = data?.content?.top?.data;
  const reportingPrev = previousData?.content?.top?.data;

  const toolbox = data?.content?.top?.toolbox;
  const toolboxPrev = previousData?.content?.top?.toolbox;

  const CompareValues = ({ value1, value2 }) => {
    let result = 0;
    let color = "black";
    if (value1 === 0) result = -100;
    if (value2 === 0) result = 100;
    if (value1 > 0 && value2 > 0) {
      result = 100 - (value2 / value1) * 100;
    }
    color = result < 0 ? "red" : "green";
    result = result.toFixed(2) + "%";

    return (
      <p className="compare" style={{ color }}>
        {result}
      </p>
    );
  };
  function formatEuro(value) {
    return new Intl.NumberFormat("de-DE", {
      style: "currency",
      currency: "EUR",
    }).format(value);
  }

  return (
    <div>
      {" "}
      <div className="kpi-section top">
        <h2>TOP Reporting</h2>
        <div className="report-table">
          <div className="table-row table-row-header table-row-top">
            <div>Nr</div>
            <div>Titel</div>
            <div>Aufrufe</div>
            <div>Eindeutig</div>
            <div>Händler</div>
            <div>Bestellungen</div>
            <div>Conversion</div>
            <div>Bestellmenge</div>
            <div>Bestellwert</div>
            <div>Ziel</div>
          </div>

          <div className="table-row table-row-top">
            <div></div>
            <div>Allgemein</div>
            <div>
              {toolbox.views}{" "}
              <CompareValues
                value1={toolbox.views}
                value2={toolboxPrev.views}
              />
            </div>
            <div>
              {toolbox.unique}{" "}
              <CompareValues
                value1={toolbox.unique}
                value2={toolboxPrev.unique}
              />
            </div>
            <div>
              {toolbox.dealers}{" "}
              <CompareValues
                value1={toolbox.dealers}
                value2={toolboxPrev.dealers}
              />
            </div>
            <div>
              {
                reporting.find(
                  (campaign) => campaign.campaign === "all campaigns"
                ).orders
              }{" "}
              <CompareValues
                value1={
                  reporting.find(
                    (campaign) => campaign.campaign === "all campaigns"
                  ).orders
                }
                value2={
                  reportingPrev.find(
                    (campaign) => campaign.campaign === "all campaigns"
                  ).orders
                }
              />
            </div>
            <div>
              {toolbox.views &&
                (
                  (reporting.find(
                    (campaign) => campaign.campaign === "all campaigns"
                  ).orders /
                    toolbox.views) *
                  100
                ).toFixed(2)}{" "}
              %{" "}
              <CompareValues
                value1={
                  toolbox.views &&
                  reporting.find(
                    (campaign) => campaign.campaign === "all campaigns"
                  ).orders / toolbox.views
                }
                value2={
                  toolboxPrev.views &&
                  reportingPrev.find(
                    (campaign) => campaign.campaign === "all campaigns"
                  ).orders / toolboxPrev.views
                }
              />
            </div>
            <div>
              {
                reporting.find(
                  (campaign) => campaign.campaign === "all campaigns"
                ).orderQuantity
              }
              <CompareValues
                value1={
                  reporting.find(
                    (campaign) => campaign.campaign === "all campaigns"
                  ).orderQuantity
                }
                value2={
                  reportingPrev.find(
                    (campaign) => campaign.campaign === "all campaigns"
                  ).orderQuantity
                }
              />
            </div>
            <div>
              {formatEuro(
                reporting.find(
                  (campaign) => campaign.campaign === "all campaigns"
                ).orderVolume
              )}{" "}
              <CompareValues
                value1={reporting.orderVolume}
                value2={reportingPrev.orderVolume}
              />
            </div>
          </div>
          {reporting &&
            reporting
              .filter((campaign) => campaign.campaign !== "all campaigns")
              .map((campaign, idx) => {
                return (
                  <div
                    style={{ paddingLeft: "3rem" }}
                    className="table-row table-row-top"
                  >
                    <div></div>
                    <div>{campaign.detailName}</div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div>{campaign.orders}</div>
                    <div></div>
                    <div>{campaign.orderQuantity}</div>{" "}
                    <div>{formatEuro(campaign.orderVolume)}</div>
                    {campaign?.unitCampaignTarget && (
                      <div>
                        {JSON.parse(campaign?.unitCampaignTarget)?.value === "€"
                          ? formatEuro(campaign.valueCampaignTarget)
                          : campaign.valueCampaignTarget}
                      </div>
                    )}
                  </div>
                );
              })}
        </div>
      </div>
    </div>
  );
}
