import React from "react";
import { Formik, Field, Form } from "formik";

export default function Input({
    label,
    name,
    placeholder,
    number,
    value,
    ...props
}) {
    return (
        <div style={{ marginBottom: 16 }}>
            <label>
                <Field
                    type='checkbox'
                    checked={value === "true" || value === true ? true : false}
                    name={name}
                    id={name}
                />
                {label}
            </label>
        </div>
    );
}
