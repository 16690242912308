import React, { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useData } from "../../../utils/dataProvider";
import Priceinfo from "./Priceinfo";
import Partinfo from "./Partinfo";
import Models from "./Models";
import Acc from "./Acc";
import Loader from "../../commonComponents/Loading";
import Articles from "./Articles";

import "./style.scss";

export default function Search() {
  const { string } = useParams();
  const { makeApiRequest } = useData();
  const [partInfo, setPartInfo] = useState();
  const [priceInfo, setPriceInfo] = useState();
  const [accInfo, setAccInfo] = useState();
  const [modelInfo, setModelInfo] = useState();
  const [images, setImages] = useState();
  const [articles, setArticles] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const searchTerm = decodeURI(string);

    async function fetchData() {
      setPartInfo(null);
      setPriceInfo(null);
      setAccInfo(null);
      setModelInfo(null);
      setImages(null);
      const pi = await makeApiRequest(`/parts/partinfo/${searchTerm}`);
      const price = await makeApiRequest(`/parts/priceinfo/${searchTerm}`);
      const ai = await makeApiRequest(`/accessories/search/${searchTerm}`);
      const mi = await makeApiRequest(`/accessories/models/${searchTerm}`);
      const img = await makeApiRequest(`/accessories/images/${searchTerm}`);

      setPartInfo(pi);
      setPriceInfo(price);
      setAccInfo(ai);
      setModelInfo(mi);
      setImages(img);
      setLoading(false);
    }
    async function fetchPFC() {
      setArticles([]);

      const art = await makeApiRequest(`/parts/pfc/${searchTerm}`);
      setArticles(art);
    }
    if (searchTerm?.length > 3) {
      fetchData();
    } else {
      fetchPFC();
    }
  }, [string]);

  return (
    <div>
      <h1>Suche für {decodeURI(string)}</h1>
      {loading && <Loader width="20px" height="20px" />}
      {decodeURI(string)?.length > 3 && partInfo?.originalName && (
        <div className="part-grid">
          <div>
            <h2>Zubehörinformation</h2>
            {accInfo ? (
              accInfo?.partname ? (
                <Acc data={accInfo} />
              ) : (
                "Keine Zubehördaten gefunden."
              )
            ) : (
              <Loader width="20px" height="20px" />
            )}
          </div>

          <div>
            <h2>Partinfo</h2>
            {partInfo ? (
              <Partinfo data={partInfo} />
            ) : (
              <Loader width="20px" height="20px" />
            )}
          </div>
          <div>
            <h2>PriceInfo</h2>

            {priceInfo ? (
              <Priceinfo data={priceInfo} />
            ) : (
              <Loader width="20px" height="20px" />
            )}
          </div>
          <div>
            <h2>Modell</h2>
            {Array.isArray(modelInfo) ? (
              <>
                {modelInfo.map((model) => {
                  return <Models data={model} />;
                })}
              </>
            ) : modelInfo ? (
              <Models data={modelInfo} />
            ) : (
              <Loader width="20px" height="20px" />
            )}
          </div>
          <div className="images">
            <h2>Bilder</h2>
            {Array.isArray(images) ? (
              images.map((image) => {
                return image?.imageUrl ? (
                  <img src={image?.imageUrl} alt="Image" />
                ) : (
                  ""
                );
              })
            ) : images?.imageUrl ? (
              <img src={images?.imageUrl} alt="Image" />
            ) : (
              ""
            )}
          </div>
        </div>
      )}
      {decodeURI(string).length <= 3 && articles && (
        <Articles data={articles} />
      )}
    </div>
  );
}
