import React, { useState, useEffect } from "react";
import Aktion from "./Aktion";
import { useNavigate } from "react-router-dom";
import ModalAktion from "./modals/Aktion";
import ModalDetail from "./modals/Detail";
import ReactTooltip from "react-tooltip";
import LoscheModal from "../../commonComponents/LoscheModal";
import { useData } from "../../../utils/dataProvider";
import CopyModal from "./modals/CopyModal";

export default function Kampagne({ data, setData, divisionID, kampagneID }) {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();
  const [ZeitraumFormularVisible, setZeitraumFormularVisible] = useState(false);
  const [copyModalVisible, setCopyModalVisible] = useState(false);
  const [owner, setOwner] = useState([]);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const {
    kampagnenOpenState,
    currentPlan,
    setKampagnenOpenState,
    setCopyKampagnen,
  } = useData();

  useEffect(() => {
    let idx = kampagnenOpenState.findIndex(
      (ele) => ele == currentPlan.label + divisionID + "." + kampagneID
    );

    if (idx >= 0) {
      setMenuOpen(true);
    }
  }, []);
  function countVacancies() {
    let size = 0;
    for (let k in data) {
      if (data[k]?.length) {
        if (
          data[k] == "entryID" ||
          data[k] == "child" ||
          data[k] == "childEndpointName"
        ) {
          continue;
        } else {
          size++;
        }
      }
    }
    return size;
  }
  const [tooltip, showTooltip] = useState(false);

  const generateKurzName = (name) => {
    if (name.split(" ").length > 1) {
      return name.split(" ")[0][0] + name.split(" ")[1][0];
    } else {
      return name.split(" ")[0][0] + name.split(" ")[0][1];
    }
  };

  function handelMenuOpen() {
    if (menuOpen) {
      setMenuOpen(false);
      let idx = kampagnenOpenState.findIndex(
        (ele) => ele == currentPlan.label + divisionID + "." + kampagneID
      );
      if (idx >= 0) {
        let copy = [...kampagnenOpenState];
        copy.splice(idx, 1);
        setKampagnenOpenState([...copy]);
      }
    } else {
      setMenuOpen(true);
      let copy = [...kampagnenOpenState];
      copy.push(currentPlan.label + divisionID + "." + kampagneID);
      setKampagnenOpenState([...copy]);
    }
  }

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [menuOpen]);

  useEffect(() => {
    getOwner();
  }, []);

  function isJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  function StatusColor(status) {
    switch (status) {
      case "Offen":
        return "#FC6969";
        break;
      case "in Arbeit":
        return "#FFC62B";
        break;
      case "Fertig":
        return "#52BA4A";
        break;
      default:
        return "#FC6969";
        break;
    }
  }
  if (data.cmpOwner == "null") {
    data.cmpOwner = null;
  }

  function getOwner() {
    let array = [];
    if (data.child) {
      data.child.forEach((aktion) => {
        if (aktion.child) {
          aktion.child.forEach((detail) => {
            if (
              detail.detailOwner &&
              isJsonString(detail.detailOwner) &&
              detail.detailOwner != "null"
            ) {
              JSON.parse(detail.detailOwner).forEach((person) => {
                let idx = array.findIndex((ele) => ele.label == person.label);
                if (idx < 0) {
                  array.push(person);
                }
              });
            }
          });
        }
      });
    }
    setOwner(array);
  }

  return (
    <div className="kampagnen-container">
      <div className="kampagnen-header">
        <div>{data.campaignName}</div>
        <div className="kampagnen-info-bar">
          <div className="name-bar">
            {owner &&
              owner.map((person, idx) => {
                return (
                  <div
                    key={person.label + data.entryID}
                    className="name-bar-name"
                    onMouseOver={() => {
                      showTooltip(true);
                    }}
                    onMouseLeave={() => {
                      showTooltip(false);
                    }}
                    data-tip={person.label}
                  >
                    {generateKurzName(person.label)}
                  </div>
                );
              })}
          </div>
          <div
            className="status-bar"
            style={{
              backgroundColor: StatusColor(
                isJsonString(data.currentStatusCampaign) &&
                  JSON.parse(data.currentStatusCampaign).value
              ),
            }}
          >
            {isJsonString(data.currentStatusCampaign)
              ? JSON.parse(data.currentStatusCampaign)?.value
              : "Offen"}
          </div>
          <div className="progress-bar">
            <div>
              {countVacancies()}/{Object.keys(data).length - 3}
            </div>
            <div className="progress">
              <div
                className="progress-done"
                style={{
                  width: `${
                    (countVacancies() / (Object.keys(data).length - 3)) * 100
                  }%`,
                }}
              ></div>
            </div>
          </div>
        </div>
        <div className="icon-bar" style={{ justifySelf: "end" }}>
          <div
            className="icon-bar-icon delete"
            data-tip={"Kampagnen löschen"}
            onClick={() => setDeleteModalVisible(true)}
          ></div>
          <div
            className="icon-bar-icon tools"
            data-tip={"Kampagnen bearbeiten"}
            onClick={() =>
              navigate(`/kampagne/${data.entryID}`, {
                state: { data: data },
              })
            }
          ></div>
          <div
            className="icon-bar-icon copy"
            data-tip={"Kampagnen Copy"}
            onClick={() => {
              setCopyKampagnen(data);
              setCopyModalVisible(true);
            }}
          ></div>
          {data.child?.length > 0 ? (
            <div
              className={`icon-bar-icon arrow ${menuOpen && "down"}`}
              data-tip={menuOpen ? "Zuklappen" : "Ausklappen"}
              onClick={handelMenuOpen}
            ></div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <div
                onClick={() => {
                  setZeitraumFormularVisible(true);
                }}
                data-tip={"Aktion hinzufügen"}
                style={{
                  height: 26,
                  width: 26,
                  borderRadius: 26,
                  margin: "0px 4px",
                  paddingBottom: 4,
                  backgroundColor: "#ccc",
                  fontSize: 24,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                +
              </div>
            </div>
          )}
        </div>
      </div>

      {menuOpen && (
        <>
          <div className="kampagnen-content">
            {data.child &&
              data.child.map((action, index) => (
                <Aktion
                  key={action.entryID}
                  data={action}
                  setData={setData}
                  currentPlan={currentPlan}
                />
              ))}
          </div>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 12,
              }}
            >
              <div
                onClick={() => {
                  setZeitraumFormularVisible(true);
                }}
                data-tip={"Aktion hinzufügen"}
                style={{
                  height: 32,
                  width: 32,
                  borderRadius: 32,
                  paddingBottom: 4,
                  backgroundColor: "#eee",
                  fontSize: 24,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                +
              </div>
            </div>
          </div>
        </>
      )}

      {ZeitraumFormularVisible && (
        <ModalAktion
          setZeitraumFormularVisible={setZeitraumFormularVisible}
          campaignID={data.entryID}
          setData={setData}
          currentPlan={currentPlan}
          setMenuOpen={setMenuOpen}
        />
      )}

      {copyModalVisible && (
        <CopyModal
          setCopyModalVisible={setCopyModalVisible}
          campaignID={data.entryID}
          setData={setData}
          currentPlan={currentPlan}
          setMenuOpen={setMenuOpen}
        />
      )}
      {deleteModalVisible && (
        <LoscheModal
          setDeleteModalVisible={setDeleteModalVisible}
          kat={"Kampagnen"}
          setData={setData}
          entryID={data.entryID}
          currentPlan={currentPlan}
          name={data.campaignName}
        ></LoscheModal>
      )}
    </div>
  );
}
