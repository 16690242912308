import React, { useState, useEffect } from "react";
import { Formik, Form, FieldArray } from "formik";
import Close from "../../../../../assets/close.svg";
import { useParams } from "react-router-dom";
import StaffelRow from "./StaffelRow";
import SaveIcon from "../../../../../assets/save.svg";
import { url } from "../../../../../utils/configs";
import axios from "axios";

export default function StaffelModal({
  setShowStaffelModal,
  item,
  setStaffelChanged,
  staffelChanged,
}) {
  const { id } = useParams();

  const [staffels, setStaffels] = useState({ staffels: [] });

  const [firstTime, setFirstTime] = useState(true);

  const submitForm = (values) => {
    const token = localStorage.getItem("token");

    values.staffels.forEach((staffel) => {
      staffel.itemID = item.entryID;
      var form_data = new FormData();
      for (var key in staffel) {
        form_data.append(key, staffel[key]);
      }
      axios
        .post(url + "/itemScale/" + item.entryID, form_data, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((res) => {
          setShowStaffelModal(false);
          setStaffelChanged(!staffelChanged);
        });
    });
  };

  const changeForm = (values) => {
    const token = localStorage.getItem("token");
    let putArray = [];
    let postArray = [];
    let deleteArray = [];
    values.staffels.forEach((staffel) => {
      let ID = staffels.staffels.findIndex(
        (ele) => ele.entryID == staffel.entryID
      );
      if (ID >= 0) {
        putArray.push(staffel);
      } else {
        postArray.push(staffel);
      }
    });
    staffels.staffels.forEach((staffel) => {
      let ID = values.staffels.findIndex(
        (ele) => ele.entryID == staffel.entryID
      );
      if (ID < 0) {
        deleteArray.push(staffel);
      }
    });

    let promises = [];
    putArray.forEach((staffel) => {
      var form_data_post = new FormData();
      for (var key in staffel) {
        form_data_post.append(key, staffel[key]);
      }
      promises.push(
        axios.put(url + "/itemScale/" + staffel.entryID, form_data_post, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
      );
    });

    postArray.forEach((staffel) => {
      var form_data_post = new FormData();
      for (var key in staffel) {
        form_data_post.append(key, staffel[key]);
      }
      promises.push(
        axios.post(url + "/itemScale/" + item.entryID, form_data_post, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
      );
    });
    deleteArray.forEach((staffel) => {
      var form_data_post = new FormData();
      for (var key in staffel) {
        form_data_post.append(key, staffel[key]);
      }
      promises.push(
        axios.delete(url + "/itemScale/" + staffel.entryID, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
      );
    });
    Promise.all(promises).then((res) => {
      setShowStaffelModal(false);
      setStaffelChanged(!staffelChanged);
    });
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    axios
      .get(url + "/itemScales/" + item.entryID, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        setStaffels({ staffels: res.data.data });
        if (res.data.data.length > 0) {
          setFirstTime(false);
        }
      });
  }, []);

  return (
    <div className="modal-wrapper">
      <div className="modal-window" style={{ overflowY: "scroll" }}>
        <img
          className="close"
          onClick={() => setShowStaffelModal(false)}
          src={Close}
        ></img>
        <Formik initialValues={staffels} enableReinitialize={true}>
          {({ values }) => (
            <Form>
              <FieldArray
                name="staffels"
                render={(arrayHelpers) => (
                  <>
                    <h1>Staffel bearbeiten</h1>
                    <h3>Für Teilnummer {item.number}</h3>
                    <div className="staffel-table">
                      <table>
                        <thead>
                          <tr>
                            <th>Min.Anzahl</th>
                            <th>Max.Anzahl</th>
                            <th>Aktionsrabbat</th>
                            <th>WAP</th>
                            <th>WAP Aktion</th>
                            <th>WAP Preisvorteil</th>
                            <th>Tools</th>
                          </tr>
                        </thead>
                        <tbody>
                          {values.staffels &&
                            values.staffels.map((staffel, idx) => (
                              <StaffelRow
                                idx={idx}
                                values={values}
                                arrayHelpers={arrayHelpers}
                                wap={item.retailPrice}
                              />
                            ))}
                        </tbody>
                      </table>
                      <div
                        style={{
                          marginTop: 32,
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <div
                          className="add-staffel-btn"
                          onClick={() => {
                            arrayHelpers.push({
                              quantityFrom: 0,
                              quantityTo: 0,
                              discountPercent: 0,
                            });
                          }}
                        >
                          Staffel Hinzufügen
                        </div>
                      </div>
                      <div
                        style={{
                          marginTop: 48,
                          display: "flex",

                          justifyContent: "flex-end",
                        }}
                      >
                        {firstTime ? (
                          <div
                            className="toyota-button toyota-button-red"
                            onClick={() => {
                              submitForm(values);
                            }}
                          >
                            <img src={SaveIcon}></img>
                            Staffel erstellen
                          </div>
                        ) : (
                          <div
                            className="toyota-button toyota-button-red"
                            onClick={() => {
                              changeForm(values);
                            }}
                          >
                            <img src={SaveIcon}></img>
                            Staffel speichern
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}
              />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
