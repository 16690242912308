import React, { useState, useEffect } from "react";
import "./style.scss";

import * as XLSX from "xlsx";

import Select from "react-select";

export default function Toolsbar({
  setDiscountAll,
  setDiscountModalVisible,
  discountAll,
  setDeleteArticleModalVisible,
  sortedArtikel,
  articleChecked,
}) {
  const [actionmode, setActionmode] = useState(null);

  const options = [
    { label: "Aktionsrabatt bearbeiten", value: "rabatt" },
    { label: "Ausgewählte Artikel exportieren", value: "export" },
    { label: "Ausgewählte Artikel löschen", value: "delete" },
  ];

  const customStyles1 = {
    control: (provided, state) => ({
      ...provided,
      width: 300,
      marginRight: 12,
    }),
  };

  function ExportExcel() {
    let workbook = XLSX.utils.book_new();

    let sheetArray = [
      [
        "Bezeichnung",
        "Teilennummer",
        "Aktionsrabatt",
        "UPE",
        "WAP",
        "Aktion",
        "Preisvorteil",
      ],
    ];
    sortedArtikel.map((article, idx) => {
      if (articleChecked[idx]) {
        sheetArray.push([
          article.name ? article.name : article.originalName,
          article.number,
          article.discountPercent,
          article.grossSalePrice,
          article.retailPrice,
          article.retailPrice -
            (parseFloat(article.retailPrice) *
              parseFloat(article.discountPercent)) /
              100,
          (parseFloat(article.retailPrice) *
            parseFloat(article.discountPercent)) /
            100,
        ]);
      }
    });
    var worksheet = XLSX.utils.aoa_to_sheet(sheetArray);
    XLSX.utils.book_append_sheet(workbook, worksheet, "test");

    XLSX.writeFile(workbook, "AlleArtikel.xlsx");
  }

  return (
    <div className="tool-bar">
      <div className="tools-bar-rabatt">
        <Select
          className="kanpagnen-header-select"
          defaultValue={"Auswählen ..."}
          options={options}
          placeholder={"Auswählen ..."}
          styles={customStyles1}
          value={actionmode}
          onChange={(val) => {
            setActionmode(val);
          }}
        />
        {actionmode?.value == "rabatt" ? (
          <>
            <input
              type="number"
              value={discountAll}
              onChange={(e) => setDiscountAll(e.target.value)}
            />
            %
            <div
              className="toyota-button toyota-button-grey"
              style={{ marginLeft: 12 }}
              onClick={() => setDiscountModalVisible(true)}
            >
              übernehmen
            </div>
          </>
        ) : actionmode?.value == "delete" ? (
          <div
            className="toyota-button toyota-button-red"
            onClick={() => setDeleteArticleModalVisible(true)}
          >
            Löschen
          </div>
        ) : actionmode?.value == "export" ? (
          <div
            className="toyota-button toyota-button-grey"
            onClick={() => ExportExcel()}
          >
            Exportieren
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
