import React, { useState } from "react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import * as locales from "react-date-range/dist/locale";
import { DateRange } from "react-date-range";
import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  isSameDay,
  differenceInCalendarDays,
} from "date-fns";
import { useDataStore } from "../utils/ZutsandStore";
export default function Picker() {
  const [pickerShow, setPickerShow] = useState(false);
  const startDate = useDataStore((state) => state.startDate);
  const endDate = useDataStore((state) => state.endDate);
  const handelTime = useDataStore((state) => state.handelTime);
  function handlePickerChange(data) {
    if (data?.startDate !== data?.endDate) {
      setPickerShow(false);
    }
    handelTime(data);
  }
  const defineds = {
    startOfWeek: startOfWeek(new Date()),
    endOfWeek: endOfWeek(new Date()),
    startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
    endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
    startOfToday: startOfDay(new Date()),
    endOfToday: endOfDay(new Date()),
    startOfYesterday: startOfDay(addDays(new Date(), -1)),
    endOfYesterday: endOfDay(addDays(new Date(), -1)),
    startOfMonth: startOfMonth(new Date()),
    endOfMonth: endOfMonth(new Date()),
    startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
    endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
  };

  return (
    <React.Fragment>
      {pickerShow && (
        <div className="date-overlay">
          <DateRangePicker
            onChange={(item) => handlePickerChange(item.range1)}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            ranges={[{ startDate: startDate, endDate: endDate }]}
            locale={locales["de"]}
            months={2}
            direction={"horizontal"}
            showMonthAndYearPickers={true}
            preventSnapRefocus={true}
            calendarFocus="backwards"
            staticRanges={[
              {
                label: "Heute",
                range: () => ({
                  startDate: defineds.startOfToday,
                  endDate: defineds.endOfToday,
                }),
                isSelected() {
                  return true;
                },
              },
              {
                label: "Gestern",
                range: () => ({
                  startDate: defineds.startOfYesterday,
                  endDate: defineds.endOfYesterday,
                }),
                isSelected() {
                  return true;
                },
              },
              {
                label: "Diese Woche",
                range: () => ({
                  startDate: defineds.startOfWeek,
                  endDate: defineds.endOfWeek,
                }),
                isSelected() {
                  return true;
                },
              },
              {
                label: "Letzte Woche",
                range: () => ({
                  startDate: defineds.startOfLastWeek,
                  endDate: defineds.endOfLastWeek,
                }),
                isSelected() {
                  return true;
                },
              },
              {
                label: "Diese Monat",
                range: () => ({
                  startDate: defineds.startOfMonth,
                  endDate: defineds.endOfMonth,
                }),
                isSelected() {
                  return true;
                },
              },
              {
                label: "Letzt Monat",
                range: () => ({
                  startDate: defineds.startOfLastMonth,
                  endDate: defineds.endOfLastMonth,
                }),
                isSelected() {
                  return true;
                },
              },
            ]}
            inputRanges={[
              {
                label: "days up to today",
                range(value) {
                  return {
                    startDate: addDays(
                      defineds.startOfToday,
                      (Math.max(Number(value), 1) - 1) * -1
                    ),
                    endDate: defineds.endOfToday,
                  };
                },
                getCurrentValue(range) {
                  if (!isSameDay(range.endDate, defineds.endOfToday))
                    return "-";
                  if (!range.startDate) return "∞";
                  return (
                    differenceInCalendarDays(
                      defineds.endOfToday,
                      range.startDate
                    ) + 1
                  );
                },
              },
              {
                label: "days starting today",
                range(value) {
                  const today = new Date();
                  return {
                    startDate: today,
                    endDate: addDays(today, Math.max(Number(value), 1) - 1),
                  };
                },
                getCurrentValue(range) {
                  if (!isSameDay(range.startDate, defineds.startOfToday))
                    return "-";
                  if (!range.endDate) return "∞";
                  return (
                    differenceInCalendarDays(
                      range.endDate,
                      defineds.startOfToday
                    ) + 1
                  );
                },
              },
            ]}
          />
        </div>
      )}
      <div
        className="picker-preview"
        onClick={() => setPickerShow(!pickerShow)}
      >
        {startDate.toLocaleDateString("de-DE")} –{" "}
        {endDate.toLocaleDateString("de-DE")}
      </div>
    </React.Fragment>
  );
}
