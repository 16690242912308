import React, { useState, useEffect } from "react";
import Logo from "../../assets/toyota-logo-white.svg";
import "./index.scss";
import LeftMenu from "./LeftMenu";
import RightMenu from "./RightMenu";
import { motion, AnimatePresence } from "framer-motion";
import { useLocation, useNavigate } from "react-router-dom";
import { useData } from "../../utils/dataProvider";
import Loader from "./Loading";
import Search from "./Search";

export default function Layout({ children }) {
  const [menuOpen, setMenuOpen] = useState(true);
  const [rightMenuOpen, setRightMenuOpen] = useState(false);
  const [RightMenuContent, setRightMenuContent] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const { userData } = useData();
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  function handleSubmit() {
    let search = searchTerm.replace("-", "");
    search = search.replace("-", "");

    navigate(`search/${encodeURI(search)}`);
    setSearchTerm("");
  }
  return (
    <div className="layout">
      <div className="header">
        <div className="logo-container">
          <img src={Logo}></img>
          <div>Kampagnenmanagement</div>
        </div>
        <Search />
        <div className="header-icon-container">
          <div className="user-name">
            {userData ? (
              userData.userName
            ) : (
              <Loader height="24px" width="24px"></Loader>
            )}
          </div>
          <div
            className="icon user-icon"
            onClick={() => {
              setRightMenuContent("User");
              setRightMenuOpen(true);
            }}
          ></div>

          <div
            className="icon faq-icon"
            onClick={() => {
              setRightMenuContent("FAQ");
              setRightMenuOpen(true);
            }}
          ></div>
        </div>
      </div>

      <div layout className={`left-menu `}>
        <LeftMenu menuOpen={menuOpen} setMenuOpen={setMenuOpen}></LeftMenu>
      </div>

      {rightMenuOpen && (
        <div
          className={`right-menu `}
          initial="collapsed"
          animate="open"
          exit="collapsed"
          variants={{
            open: { x: 0 },
            collapsed: { x: "100%" },
          }}
          transition={{
            duration: 0.5,
          }}
        >
          <RightMenu
            content={RightMenuContent}
            setRightMenuOpen={setRightMenuOpen}
          ></RightMenu>
        </div>
      )}

      <div
        layout
        style={{ paddingLeft: menuOpen ? "320px" : "96px" }}
        className="content"
      >
        {children}
      </div>
    </div>
  );
}
