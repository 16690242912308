import React, { useEffect, useState } from "react";
import { Formik, Field, useFormikContext, useField } from "formik";
import Select from "react-select";
import { url } from "../../../utils/configs";
import axios from "axios";
export default function MultiSelect({
  name,
  label,
  options,
  value,
  required,
  source,
  ...props
}) {
  const { setFieldValue } = useFormikContext();
  const [selectOptions, setSelectOptions] = useState(options);
  const fieldStyles = {
    control: () => ({
      width: "100%",
      height: "42px",
      display: "flex",
      padding: "0px 3px",
      border:
        required && (!value || value.length == 0)
          ? "1px solid #ff0022"
          : "1px solid #ccc",
    }),
    dropdownIndicator: () => ({ color: "black", paddingLeft: "8px" }),
  };
  useEffect(() => {
    let token = localStorage.getItem("token");
    switch (name) {
      case "cmpOwner":
      case "actOwner":
      case "detailOwner": {
        axios
          .get(url + "/employees", {
            headers: {
              Authorization: "Bearer " + token,
            },
          })
          .then((res) => {
            let array = [];
            res.data.forEach((item) => {
              array.push({
                value: item.MitarbeiterID,
                label: item.Vorname + " " + item.Name,
              });
            });
            setSelectOptions(array);
          });
      }
      default:
        break;
    }
  }, []);
  function isJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
  return (
    <div
      className={`aktion-input ${
        required && (!value || value.length == 0) ? "required-field" : ""
      }`}
    >
      <label htmlFor={name}>{label}</label>
      <Select
        isMulti
        name={name}
        id={name}
        placeholder={""}
        styles={fieldStyles}
        options={selectOptions}
        className="basic-multi-select"
        value={isJsonString(value) ? JSON.parse(value) : null}
        onChange={(val) => {
          let string = [];
          val.map((item) => {
            string.push({ value: item.value, label: item.label });
          });
          setFieldValue(name, JSON.stringify(string));
        }}
      />
    </div>
  );
}
