import React from "react";
import { Field } from "formik";
import Delete from "assets/delete.svg";

export default function SetRow({ arrayHelpers, wap, idx, values }) {
  function doMoney(float) {
    if (!float) {
      return "";
    }
    return float.toLocaleString("de-DE", {
      style: "currency",
      currency: "EUR",
    });
  }
  function calculateNetto(brutto) {
    return brutto - brutto * 0.19;
  }

  function doMoney(float) {
    return float.toLocaleString("de-DE", {
      style: "currency",
      currency: "EUR",
    });
  }

  function WAPAktion(wap) {
    if (values.staffels) {
      return wap - wap * values.staffels[idx].discountPercent * 0.01;
    }
  }

  return (
    <tr>
      <td>
        <Field name={`staffels.${idx}.${"quantityFrom"}`} />
      </td>
      <td>
        <Field name={`staffels.${idx}.${"quantityTo"}`} />
      </td>
      <td style={{ display: "flex" }}>
        <Field name={`staffels.${idx}.${"discountPercent"}`} />
        <div style={{ paddingLeft: "4px" }}>%</div>
      </td>

      <td>{doMoney(wap)}</td>

      <td>{doMoney(WAPAktion(wap))}</td>
      <td>{doMoney(wap - WAPAktion(wap))}</td>
      <td>
        <button type="button" onClick={() => arrayHelpers.remove(idx)}>
          <img src={Delete} alt="Löschen" />
        </button>
      </td>
    </tr>
  );
}
