import React, { useState, useEffect, useRef } from "react";
import {
  VictoryChart,
  VictoryBar,
  VictoryTheme,
  VictoryAxis,
  VictoryLabel,
  VictoryTooltip,
} from "victory";
import Compare from "./Compare";
import Tooltip from "./Tooltip";
import { useDataStore } from "../utils/ZutsandStore";

export default function UserActivity() {
  const { data, previousData, startDate, endDate } = useDataStore();

  const [chartData, setChartData] = useState();
  const [max, setMax] = useState(300);

  const chartContainer = useRef();

  const dayCount = Math.floor(
    (Date.parse(endDate) - Date.parse(startDate)) / 86400000 + 1
  );

  function addDays(thisday, days) {
    let date = new Date(thisday);
    date.setDate(date.getDate() + days);
    return date;
  }

  const dealerPercentage = Math.round((data.dealers?.active / 560) * 100);
  const prevDealerPercentage = Math.round((data.dealers?.active / 560) * 100);

  const dailyLoginCount = data.users?.dailyActivity?.reduce((cv, pv) => {
    return parseFloat(pv["count"]) + parseFloat(cv);
  }, 0);

  const prevDailyLoginCount = previousData.users?.dailyActivity?.reduce(
    (cv, pv) => {
      return parseFloat(pv["count"]) + parseFloat(cv);
    },
    0
  );

  const dailyLogins = Math.floor((dailyLoginCount / dayCount) * 100) / 100;
  const prevDailyLogin =
    Math.floor((prevDailyLoginCount / dayCount) * 100) / 100;

  function getDates(startDate, stopDate) {
    let dateArray = new Array();
    let currentDate = startDate;
    while (currentDate <= stopDate) {
      dateArray.push(new Date(currentDate));
      currentDate = addDays(currentDate, 1);
    }

    return dateArray;
  }

  function formatEuro(value) {
    return Math.round(value).toLocaleString("de-DE") + " €";
  }

  useEffect(() => {
    let max = 50;
    let new_chartData = getDates(startDate, endDate).map((activity, idx) => {
      let value = 0;
      const day = data.users?.dailyActivity?.find((item) => {
        return (
          new Date(item.day).toLocaleDateString("de-DE") ==
          new Date(activity).toLocaleDateString("de-DE")
        );
      });

      if (day) {
        value = day["count"];
        if (max <= day["count"]) max = day["count"];
      }
      return {
        x: new Date(activity).toLocaleDateString("de-DE"),
        y: parseInt(value),
        label: "Nutzer: " + parseInt(value),
      };
    });
    setMax(max);
    setChartData(new_chartData);
  }, [startDate, endDate, data]);

  return (
    <div className="kpi-section">
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr",
          gridGap: "1rem",
        }}
      >
        <div>
          <h2>
            Nutzeraktivität
            <Tooltip text="Die Grafik zeigt die Gesamtaktivität der Nutzer und bildet alle Aktionen aller Nutzer im Zeitraum ab" />
          </h2>
          <div className="box" ref={chartContainer}>
            <VictoryChart
              height={400}
              domainPadding={100}
              width={
                chartContainer.current?.getBoundingClientRect().width || 500
              }
              theme={VictoryTheme.material}
            >
              <VictoryBar
                labelComponent={<VictoryTooltip renderInPortal />}
                domain={{ y: [0, max] }}
                style={{
                  data: {},
                  parent: { border: "1px solid #ccc" },
                }}
                data={chartData}
              />
              <VictoryAxis
                crossAxis
                style={{ tickLabels: { angle: 0 }, grid: { display: "none" } }}
                fixLabelOverlap={true}
              />
              <VictoryAxis
                crossAxis
                dependentAxis
                style={{ tickLabels: { angle: 0 }, grid: { display: "none" } }}
                fixLabelOverlap={true}
              />
            </VictoryChart>
          </div>
        </div>
      </div>
      <div className="kpi-grid grid-5">
        <div className="grid-item">
          <div className="item-label">
            Aktive Nutzer{" "}
            <Tooltip
              small
              text="Anzahl der Nutzer, die im ausgewählten Zeitraum eingeloggt waren."
            />
          </div>
          <div className="item-value">{data.users?.active}</div>
          <Compare
            prevValue={previousData.users?.active}
            currentValue={data.users?.active}
          />
        </div>
        <div className="grid-item">
          <div className="item-label">
            Tägliche Nutzer{" "}
            <Tooltip
              small
              text="Anzahl der Nutzer, die täglich eingeloggt waren. (Daily Active Users)"
            />
          </div>
          <div className="item-value">{dailyLogins}</div>
          <Compare prevValue={prevDailyLogin} currentValue={dailyLogins} />
        </div>
        <div className="grid-item">
          <div className="item-label">
            Aktive Händler
            <Tooltip small text="Anzahl der individuellen Händlernummern." />
          </div>
          <div className="item-value">{data.dealers?.active}</div>
          <Compare
            prevValue={previousData.dealers?.active}
            currentValue={data.dealers?.active}
          />
        </div>
        <div className="grid-item">
          <div className="item-label">
            Gesamthändler
            <Tooltip small text="Anzahl der individuellen Händlernummern." />
          </div>
          <div className="item-value">{dealerPercentage} %</div>
          <Compare
            prevValue={prevDealerPercentage}
            currentValue={dealerPercentage}
          />
        </div>
        <div className="grid-item">
          <div className="item-label">
            TOP-Umsatz
            <Tooltip small text="Anzahl der individuellen Händlernummern." />
          </div>
          <div className="item-value">
            {formatEuro(
              data?.content?.top?.data?.find(
                (campaign) => campaign.campaign == "all campaigns"
              ).orderVolume
            )}
          </div>
          <Compare
            prevValue={
              previousData?.content?.top?.data?.find(
                (campaign) => campaign.campaign == "all campaigns"
              ).orderVolume
            }
            currentValue={
              data?.content?.top?.data?.find(
                (campaign) => campaign.campaign == "all campaigns"
              ).orderVolume
            }
          />
        </div>
      </div>
    </div>
  );
}
