import React, { useState, useEffect } from "react";
import Close from "../../assets/close.svg";
import { useData } from "../../utils/dataProvider";
import axios from "axios";
import { url } from "../../utils/configs";
import Loader from "../commonComponents/Loading";
export default function ChainLoscheModal({
    setDeleteModalVisible,
    kat,
    name,
    items,
    update,
    setUpdate,
    articleChecked,
    setArticleChecked,
}) {
    const { makeApiPost, currentPlan } = useData();

    const [loading, setLoading] = useState(false);

    const deleteSubmit = () => {
        setLoading(true);
        let katurl = "";
        switch (kat) {
            case "Ausgewählte Artikel Items":
                katurl = "/item/";
                break;
            case "Alle Sets Items":
                katurl = "/itemsetCart/";
                break;
        }
        const token = localStorage.getItem("token");
        let promises = [];
        if (kat == "Ausgewählte Artikel Items") {
            items.forEach((element, index) => {
                if (articleChecked[index]) {
                    promises.push(
                        axios.delete(url + katurl + element.entryID, {
                            headers: {
                                Authorization: "Bearer " + token,
                            },
                        })
                    );
                }
            });
        } else {
            items.forEach((element, index) => {
                promises.push(
                    axios.delete(url + katurl + element.entryID, {
                        headers: {
                            Authorization: "Bearer " + token,
                        },
                    })
                );
            });
        }

        Promise.all(promises).then(() => {
            setUpdate(!update);
            setLoading(false);
            setDeleteModalVisible(false);
            setArticleChecked([]);
        });
    };

    return (
        <div className='modal-wrapper'>
            <div className='modal-window'>
                <img
                    className='close'
                    src={Close}
                    onClick={() => setDeleteModalVisible(false)}></img>
                <h2>{kat} löschen</h2>
                Möchten Sie Items in <b>{name}</b> löschen?
                <div
                    style={{
                        display: "flex",
                        marginTop: 32,
                        justifyContent: "flex-end",
                    }}>
                    <div
                        className='toyota-button toyota-button-grey'
                        onClick={() => setDeleteModalVisible(false)}
                        style={{ marginRight: 16, width: 120 }}>
                        Abbrechen
                    </div>
                    <div
                        onClick={deleteSubmit}
                        style={{ width: 120 }}
                        className='toyota-button toyota-button-red'>
                        {loading ? (
                            <Loader height={"20px"} width={"20px"}></Loader>
                        ) : (
                            "Löschen"
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
