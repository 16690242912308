import React, { useState, useEffect } from "react";
import "./index.scss";
import Loader from "../../commonComponents/Loading";
import { useData } from "../../../utils/dataProvider";
import KampagnenBox from "./kampagnenBox";
import { Link } from "react-router-dom";
import Changes from "./Changes";
export default function Dashboard() {
  const [data, setData] = useState([]);
  const [changes, setChanges] = useState([]);
  const [loading, setLoading] = useState(true);
  const [changeLoading, setChangeLoading] = useState(true);
  const { makeApiRequest } = useData();
  useEffect(() => {
    setLoading(true);
    async function fetchData() {
      const apiData = await makeApiRequest(
        "/msplan/0000-6D73-706C-616E-0262-5A07"
      );
      setData(apiData?.data[0].child);
      setLoading(false);
      const apiChanges = await makeApiRequest("/markedChanges");
      setChanges(apiChanges.data);
      setChangeLoading(false);
    }
    fetchData();
  }, []);

  return (
    <div>
      <h1>Dashboard</h1>
      {changeLoading ? (
        <div
          style={{
            width: "100%",
            height: "16px",
            marginBottom: 30,
          }}
        >
          <Loader height={20} width={20} />
        </div>
      ) : (
        <Changes
          changes={changes}
          setChangeLoading={setChangeLoading}
          setChanges={setChanges}
        />
      )}

      <div className="page-sub-title">
        Aktuell laufende Kampagnen und Aktionen
      </div>
      {loading ? (
        <div
          style={{
            width: "100%",
            height: "70vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loader />
        </div>
      ) : (
        <div className="Dashboard-ubersicht">
          {data?.length > 0 &&
            data.map(
              (division, index) =>
                division.child &&
                division.child.map((campaign) => {
                  return (
                    campaign.child &&
                    campaign.child
                      .filter((action) => {
                        const start = new Date(action.actFrom);
                        const end = new Date(action.actTo);

                        return start <= new Date() && end >= new Date();
                      })
                      .map((action, idx) => {
                        return (
                          <KampagnenBox
                            data={action}
                            key={"kampagnen-box" + idx}
                          ></KampagnenBox>
                        );
                      })
                  );
                })
            )}
        </div>
      )}
    </div>
  );
}
