import { getByLabelText } from "@testing-library/react";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Creatable from "react-select/creatable";
import { useData } from "../../utils/dataProvider";

export default function Search() {
    const { userData, makeApiRequest } = useData();

    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState("");
    const [options, setOptions] = useState([]);
    function handleSubmit(e) {
        let search = e?.value.replace("-", "");
        search = search.replace("-", "");
        navigate(`search/${encodeURI(search)}`);
        setSearchTerm(null);
    }

    function handleCreate(term) {
        let search = term?.replace("-", "");
        search = search.replace("-", "");
        setSearchTerm(null);
        navigate(`search/${encodeURI(search)}`);
    }

    async function getPFCs() {
        const articleRequest = await makeApiRequest(`/pfc`);
        const data = articleRequest.map((item) => {
            return {
                value: item.pfc,
                label: item.pfc + " " + item.pfc_description,
            };
        });
        setOptions(data);
    }
    useEffect(() => {
        getPFCs();
    }, []);

    function formatLabel(e) {
        return `Nach ${e} suchen`;
    }

    return (
        <div className='search-input-container'>
            <form onSubmit={handleSubmit}>
                <Creatable
                    isClearable
                    width={800}
                    value={searchTerm}
                    onChange={handleSubmit}
                    options={options}
                    placeholder='Teilenummer oder PFC finden'
                    onCreateOption={handleCreate}
                    formatCreateLabel={(e) => formatLabel(e)}
                />
            </form>
        </div>
    );
}
