import React, { useState, useEffect } from "react";
import { useData } from "../../../../../utils/dataProvider";
import Save from "../../../../../assets/save.svg";
import Close from "../../../../../assets/close.svg";
import Loading from "../../../../commonComponents/Loading";
import RSelect from "react-select";

export default function ModalAktion({
  setShowPFCModal,
  setShowTable,
  modelID,
  discount,
  getArticles,
}) {
  const [importing, setImporting] = useState(false);
  const [tempArticles, setTempArticles] = useState([]);
  const [functionCodes, setFunctionCodes] = useState([]);
  const [selected, setSelected] = useState(null);
  const { makeApiPostRequest, makeApiRequest } = useData();
  async function getPFCs() {
    const articleRequest = await makeApiRequest(`/pfc`);
    const data = articleRequest.map((item) => {
      return {
        value: item.pfc,
        label: item.pfc + " " + item.pfc_description,
      };
    });
    setFunctionCodes(data);
  }
  useEffect(() => {
    getPFCs();
  }, []);

  async function getItems() {
    if (selected?.value) {
      const data = await makeApiRequest(`/parts/pfc/${selected.value}`);
      setTempArticles(data);
    }
  }

  useEffect(() => {
    getItems();
  }, [selected]);

  const fieldStyles = {
    control: () => ({
      width: "100%",
      height: "42px",
      width: "300px",
      display: "flex",
      padding: "0px 3px",
      border: "1px solid #ccc",
    }),
    dropdownIndicator: () => ({ color: "black", paddingLeft: "8px" }),
  };

  async function uploadArticles() {
    setImporting(true);
    const articleList = tempArticles.map((article) => ({
      number: article.partNumber,
      discountPercent: parseFloat(discount).toFixed(2),
    }));

    const upload = await makeApiPostRequest(`/item/${modelID}`, {
      items: JSON.stringify(articleList),
    });
    if (upload) {
      setImporting(false);
      setShowPFCModal(false);
      getArticles();
    } else {
      alert("Fehler beim Upload");
      setShowPFCModal(false);
    }
  }

  return (
    <div className="modal-wrapper">
      <div className="modal-window">
        <img
          className="close"
          onClick={() => setShowPFCModal(false)}
          src={Close}
        ></img>
        <div
          className="aktion-formular-content"
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <h1>Artikel importieren</h1>
          <div></div>
          <div className="fields">
            <div className="section-block">
              <div className="section-title">Daten</div>
              <div className="section-form">
                <RSelect
                  options={functionCodes}
                  styles={fieldStyles}
                  className="basic-multi-select"
                  placeholder="Auswählen"
                  onChange={(val) => setSelected(val)}
                />
              </div>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            {importing ? (
              <Loading height="20px" width="20px" />
            ) : (
              <button
                type="button"
                onClick={() => uploadArticles()}
                className="toyota-button toyota-button-red"
                style={{
                  marginLeft: 16,
                  marginTop: 16,
                  alignSelf: "end",
                }}
              >
                <img
                  style={{
                    marginRight: "12px",
                    height: "20px",
                  }}
                  src={Save}
                ></img>
                {tempArticles?.length} Artikel importieren
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
