import React, { useContext, Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { SubPlandata } from "./subplan";
import EventModal from "./eventmodal";
import DOMPurify from "dompurify";

function Event() {
  const { events, select, startDatum } = useContext(SubPlandata);
  const [showModal, setShowModal] = useState([]);
  function getOffset(date0, date1) {
    const diffTime = Math.abs(date0 * 1000 - date1 * 1000);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  }
  function getTime(start, end) {
    let startDate = new Date(start * 1000);
    let endDate = new Date(end * 1000);

    let startDay = startDate.getDate();
    let startMonth = startDate.getMonth() + 1;
    let startYear = startDate.getFullYear();

    let endDay = endDate.getDate();
    let endMonth = endDate.getMonth() + 1;
    let endYear = endDate.getFullYear();

    return `${startDay}.${startMonth}.${startYear} - ${endDay}.${endMonth}.${endYear}`;
  }

  function deviceRedirect() {
    if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
      return true;
    } else {
      return false;
    }
  }

  const modalHandel = (idx, show) => {
    let modal = showModal;
    modal[idx] = show;
    setShowModal([...modal]);
  };

  function formatDate(input) {
    try {
      const date = new Date(input);
      return date.toLocaleDateString("de-DE");
    } catch {
      return "";
    }
  }

  if (events.child) {
    return (
      <Fragment>
        {events.child &&
          events.child.map((periods, index) => {
            let startPosition = getOffset(
              startDatum,
              new Date(periods.actFrom).getTime() / 1000
            );
            let endPosition = getOffset(
              startDatum,
              new Date(periods.actTo).getTime() / 1000
            );
            let eventStyle = {
              gridColumnStart: startPosition > 0 ? startPosition : 1,
              gridColumnEnd:
                endPosition - startPosition < 14
                  ? startPosition + 14
                  : endPosition,
              background: periods.colourAction,
            };
            let startMonth = Math.floor(startPosition / 31);
            let endMonth = Math.floor(endPosition / 31);
            let iconUrl = "";
            if (periods.link) {
              iconUrl =
                '<svg fill="#fff"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="15pt" height="15pt" viewBox="0 0 30 30" version="1.1"><g id="surface43003"><path style="fill:none;stroke-width:2;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(100%,100%,100%);stroke-opacity:1;stroke-miterlimit:10;" d="M 22 14 L 22 24 C 22 24.550781 21.550781 25 21 25 L 6 25 C 5.449219 25 5 24.550781 5 24 L 5 9 C 5 8.449219 5.449219 8 6 8 L 16 8 "/><path style="fill:none;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke:rgb(100%,100%,100%);stroke-opacity:1;stroke-miterlimit:10;" d="M 26 10 L 26 4 L 20 4 M 26 4 L 14 16 "/><path style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;" d="M 23 16 L 21 16 L 21 13.421875 L 23 11.421875 Z M 14 7 L 14 9 L 16.578125 9 L 18.578125 7 Z M 14 7 "/></g></svg>';
            }
            if (
              select
                .slice(startMonth, endMonth + 1)
                .some((value) => value === true)
            ) {
              return (
                <Fragment key={`event-${index}`}>
                  <Link
                    style={eventStyle}
                    to={"/kampagne/" + events.entryID}
                    onMouseOver={() => modalHandel(index, true)}
                    onMouseLeave={() => modalHandel(index, false)}
                    className="event"
                  >
                    {periods.actFrom == periods.actTo ? (
                      <div
                        style={{
                          background: `url(${iconUrl}) no-repeat center`,
                          height: "100%",
                          width: "100%",
                        }}
                        key={index}
                      ></div>
                    ) : (
                      <>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(periods.actionName),
                          }}
                          key={index}
                        ></div>
                        <div className="event-time">
                          {formatDate(periods.actFrom) +
                            " - " +
                            formatDate(periods.actTo)}
                        </div>
                      </>
                    )}
                  </Link>
                  {showModal[index] ? (
                    deviceRedirect() ? null : (
                      <EventModal
                        startDate={new Date(periods.actFrom).getTime() / 1000}
                        endDate={new Date(periods.actTo).getTime() / 1000}
                      />
                    )
                  ) : null}
                </Fragment>
              );
            } else if (select.every((value) => value === false)) {
              return (
                <Fragment key={`event-2-${index}`}>
                  <Link
                    style={eventStyle}
                    to={"/kampagne/" + events.entryID}
                    onMouseOver={() => modalHandel(index, true)}
                    onMouseLeave={() => modalHandel(index, false)}
                    className="event"
                  >
                    {periods.actFrom == periods.actTo ? (
                      <div
                        style={{
                          background: `url(${iconUrl}) no-repeat center`,
                          height: "100%",
                          width: "100%",
                        }}
                        key={index}
                      ></div>
                    ) : (
                      <>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(periods.actionName),
                          }}
                          key={index}
                        ></div>
                        <div className="event-time">
                          {formatDate(periods.actFrom) +
                            " - " +
                            formatDate(periods.actTo)}
                        </div>
                      </>
                    )}
                  </Link>
                  {showModal[index] ? (
                    deviceRedirect() ? null : (
                      <EventModal
                        startDate={periods.actFrom}
                        endDate={periods.actTo}
                      />
                    )
                  ) : null}
                </Fragment>
              );
            } else {
              return null;
            }
          })}
      </Fragment>
    );
  } else {
    return <div></div>;
  }
}

export default Event;
