import React, { useState, useEffect } from "react";

export default function EventModal({ startDate, endDate }) {
  const [time, setTime] = useState("");
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);
  useEffect(() => {
    function formatDate(input) {
      try {
        const date = new Date(input);
        return date.toLocaleDateString("de-DE");
      } catch {
        return "";
      }
    }
    setTime(formatDate(startDate) + " - " + formatDate(endDate));
  }, [startDate, endDate]);

  useEffect(() => {
    document.addEventListener("mousemove", showMousePosition);
    return () => {
      document.removeEventListener("mousemove", showMousePosition);
    };
  }, [x, y]);

  const showMousePosition = (e) => {
    setX(e.clientX || window.event.clientX);
    setY(e.clientY || window.event.clientY);
  };
  function getTime(start, end) {
    let startDate = new Date(start * 1000);
    let endDate = new Date(end * 1000);

    let startDay = startDate.getDate();
    let startMonth = startDate.getMonth() + 1;
    let startYear = startDate.getFullYear();

    let endDay = endDate.getDate();
    let endMonth = endDate.getMonth() + 1;
    let endYear = endDate.getFullYear();

    return `${startDay}.${startMonth}.${startYear} - ${endDay}.${endMonth}.${endYear}`;
  }

  return (
    <div
      initial="collapsed"
      className="event-modal-container"
      style={{
        top: y,
        left: x,
      }}
    >
      <span className="bot"></span>
      <span className="top"></span>
      {time}
    </div>
  );
}
