import React, { useEffect, useState } from "react";
import Select from "react-select";
import "./index.scss";
import PlanText from "../../../assets/plantext.svg";
import FilterText from "../../../assets/filterText.svg";
import Loader from "../../commonComponents/Loading";
import { useData } from "../../../utils/dataProvider";
import Eye from "../../../assets/eye.svg";
import Plus from "../../../assets/plus.svg";
import { Link, useNavigate, useLocation } from "react-router-dom";
import ModalCampaign from "./modals/Kampagne";
import DivisionItem from "./DivisionItem";
import ReactTooltip from "react-tooltip";

export default function Kampagnen({ project }) {
  const {
    data,
    setData,
    currentPlan,
    setCurrentPlan,
    plans,
    generalPlans,
    generalData,
    currentGeneralPlan,
    setCurrentGeneralPlan,
    setGeneralData,
  } = useData();
  const location = useLocation();
  const [campaignFormVisible, setCampaignFormVisible] = useState(false);
  const navigate = useNavigate();

  const [kampagnenData, setKampagnenData] = useState();

  const planData =
    location.pathname == "/generalrelease"
      ? generalPlans &&
        generalPlans.map((plan, idx) => {
          return {
            value: plan.entryID,
            label: plan.msplanName,
            idx: idx,
          };
        })
      : plans &&
        plans.map((plan, idx) => {
          return {
            value: plan.entryID,
            label: plan.msplanName,
            idx: idx,
          };
        });

  const customStyles1 = {
    control: (provided, state) => ({
      ...provided,
      paddingLeft: "54px",
      backgroundImage: `url("${PlanText}")`,
      backgroundPosition: "8px center",
      backgroundRepeat: "no-repeat",
    }),
  };

  const customStyles2 = {
    control: (provided, state) => ({
      ...provided,
      paddingLeft: "54px",
      backgroundImage: `url("${FilterText}")`,
      backgroundPosition: "8px center",
      backgroundRepeat: "no-repeat",
    }),
  };

  return (
    <div>
      <div className="kampagnen-header">
        <h1>
          {location.pathname == "/generalrelease"
            ? "General Releases"
            : "Kampagnen"}
        </h1>
        <div className="kampagnen-header-toolbar">
          <Select
            className="kanpagnen-header-select"
            options={planData}
            placeholder={"Auswählen"}
            styles={customStyles1}
            value={
              location.pathname == "/generalrelease"
                ? currentGeneralPlan
                : currentPlan
            }
            onChange={(val) => {
              if (location.pathname == "/generalrelease") {
                setCurrentGeneralPlan(val);
                localStorage.setItem("currentGeneralPlan", val.idx);
              } else {
                setCurrentPlan(val);
                localStorage.setItem("currentPlan", val.idx);
              }
            }}
          />

          <Link
            to={`/vorschau/${currentPlan?.value}`}
            className="toyota-button toyota-button-grey"
            style={{
              marginLeft: 16,
            }}
          >
            <img
              style={{ marginRight: "12px", height: "20px" }}
              src={Eye}
            ></img>
            <div> Vorschau</div>
          </Link>
          <div
            className="toyota-button toyota-button-red"
            onClick={() => setCampaignFormVisible(true)}
            style={{
              marginLeft: 16,
            }}
          >
            <img
              style={{ marginRight: "12px", height: "20px" }}
              src={Plus}
            ></img>
            <div>Kampagne erstellen</div>
          </div>
        </div>
      </div>

      {!data ? (
        <div
          style={{
            width: "100%",
            height: "70vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loader />
        </div>
      ) : (
        <div className="kampagnen-ubersicht">
          {location.pathname == "/generalrelease"
            ? generalData?.length > 0 &&
              generalData
                .sort((a, b) => parseInt(a.order) - parseInt(b.order))
                .map((division, index) => (
                  <DivisionItem
                    key={division.entryID}
                    setData={setGeneralData}
                    currentPlan={currentGeneralPlan}
                    data={division}
                    divisionID={"g" + index}
                  ></DivisionItem>
                ))
            : data?.length > 0 &&
              data
                .sort((a, b) => parseInt(a.order) - parseInt(b.order))
                .map((division, index) => (
                  <DivisionItem
                    key={division.entryID}
                    setData={setData}
                    currentPlan={currentPlan}
                    data={division}
                    divisionID={index}
                  ></DivisionItem>
                ))}
        </div>
      )}
      {campaignFormVisible && (
        <ModalCampaign
          setCampaignModalVisible={setCampaignFormVisible}
          divisions={
            location.pathname == "/generalrelease" ? generalData : data
          }
          currentPlan={
            location.pathname == "/generalrelease"
              ? currentGeneralPlan
              : currentPlan
          }
          setData={
            location.pathname == "/generalrelease" ? setGeneralData : setData
          }
        />
      )}
    </div>
  );
}
