import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

const isLocalhost = (url) => {
  return url.includes("localhost") || url.includes("127.0.0.1");
};

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
    });

    let data = JSON.stringify({
      token: "ST8NVLURQefw9Fbj",
      systemid: "fusion",
      crashmessage: error.toString(),
      crashstack: JSON.stringify(error.stack),
    });

    let config = {
      method: "post",
      url: "https://monitoring.12-05.com/wp-json/monitoring/v1/crash",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    if (isLocalhost(window.location.href)) {
    } else {
      axios(config)
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });
    }
  }

  render() {
    if (this.state.error) {
      return (
        <Fragment>
          <div style={{ margin: "200px auto" }}>
            <h1>Es gab einen Fehler.</h1>
            <div style={{ marginBottom: 12, color: "red" }}>
              {this.state.error && this.state.error.toString()}
            </div>
            <a href="/">
              <div className="toyota-button toyota-button-red">
                Zum Dashboard
              </div>
            </a>
          </div>
        </Fragment>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
