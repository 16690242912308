import React, { useEffect, useState } from "react";
import { Formik, Field, Form } from "formik";

import { useNavigate } from "react-router-dom";
import { useData } from "../../../utils/dataProvider";
import ActionModal from "../../pages/Kampagnen/modals/Aktion";
import ModalDetail from "../../pages/Kampagnen/modals/Detail";
import LoscheModal from "../../commonComponents/LoscheModal";
import ReactTooltip from "react-tooltip";

export default function ActionFormDetail({
    detail,
    item,
    index,
    updated,
    setUpdated,
}) {
    const navigate = useNavigate();

    const [deleteModalVisible, setDeleteModalVisible] = useState(false);

    return (
        <div
            className='action-container'
            style={{
                backgroundColor: "white",
                borderTop:
                    item.child.length > 1 && index != 0
                        ? "1px solid #ccc"
                        : "none",
            }}>
            <div className='kampagnen-header'>
                <div>{detail.detailName}</div>
                <div
                    className='kampagnen-info-bar'
                    style={{
                        display: "flex",
                        justifyContent: "space-around",
                    }}>
                    <div
                        style={{
                            backgroundColor: "#FFC62B",
                            height: 16,
                            width: 16,
                            borderRadius: 16,
                        }}></div>
                </div>
                <div
                    className='icon-bar'
                    style={{
                        justifySelf: "end",
                    }}>
                    <div
                        data-tip={"Detail löschen"}
                        className='icon-bar-icon delete'
                        onClick={() => setDeleteModalVisible(true)}></div>
                    <div
                        className='icon-bar-icon tools'
                        data-tip={"Detail bearbeiten"}
                        onClick={() =>
                            navigate("/detail/" + detail.entryID)
                        }></div>
                </div>
            </div>
            <ReactTooltip effect='solid' scrollHide={true} />
            {deleteModalVisible && (
                <LoscheModal
                    setDeleteModalVisible={setDeleteModalVisible}
                    kat={"Detail"}
                    entryID={detail.entryID}
                    updated={updated}
                    setUpdated={setUpdated}
                    name={detail.detailName}></LoscheModal>
            )}
        </div>
    );
}
