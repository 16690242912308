import React, { useState, useEffect } from "react";
import Close from "../../assets/close.svg";
import { useData } from "../../utils/dataProvider";
import axios from "axios";
import { url } from "../../utils/configs";
import { useLocation } from "react-router-dom";
export default function SaveAlertModal({ onConfirm, onCancel }) {
    const location = useLocation();

    return (
        <div className='modal-wrapper'>
            <div className='modal-window'>
                Wollen Sie die Seite wirklich verlassen? Nicht gespeicherte
                Änderungen gehen verloren.
                <div
                    style={{
                        display: "flex",
                        marginTop: 32,
                        justifyContent: "flex-end",
                    }}>
                    <div
                        className='toyota-button toyota-button-grey'
                        onClick={onCancel}
                        style={{ marginRight: 16, width: 120 }}>
                        Abbrechen
                    </div>
                    <div
                        onClick={onConfirm}
                        className='toyota-button toyota-button-red'
                        style={{ width: 120 }}>
                        Bestätigen
                    </div>
                </div>
            </div>
        </div>
    );
}
