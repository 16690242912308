import React, { useState } from "react";
import "../index.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import Downarrow from "../../../assets/downarrow.svg";
import SubItem from "./MenuSubItem";

export default function RightMenu({
  title,
  subItem,
  menuOpen,
  url,
  className,
}) {
  const location = useLocation();
  const navigate = useNavigate();

  const [subMenuOpen, setSubMenuOpen] = useState(false);

  return (
    <div style={{ marginTop: "12px" }}>
      <div
        className={`menu-item ${className}`}
        onClick={() => navigate(url, { replace: true })}
        style={{
          backgroundColor: location.pathname == url ? "#ebebeb" : "white",
        }}
      >
        <div className="icon"></div>
        {menuOpen && (
          <div
            key="modal2"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, width: "auto" },
              collapsed: { opacity: 0, width: 0 },
            }}
            transition={{
              duration: 0.5,
            }}
            className={`icon-text`}
          >
            <div
              variants={{
                collapsed: {
                  scaleX: 0.1,
                },
                open: { scaleX: 1 },
              }}
              className="text"
              transition={{ duration: 0.5 }}
            >
              {title}
            </div>
            {subItem && (
              <img
                src={Downarrow}
                variants={{
                  collapsed: {
                    scaleX: 0.1,
                  },
                  open: { scaleX: 1 },
                }}
                onClick={() => setSubMenuOpen(!subMenuOpen)}
                className="down-arrow"
                transition={{
                  duration: 0.5,
                }}
              ></img>
            )}
          </div>
        )}
      </div>
      {subMenuOpen && menuOpen && (
        <div
          key="content"
          initial="collapsed"
          animate="open"
          exit="collapsed"
          variants={{
            open: { opacity: 1, height: "auto" },
            collapsed: { opacity: 0, height: 0 },
          }}
          transition={{
            duration: 0.8,
            ease: [0.04, 0.62, 0.23, 0.98],
          }}
        >
          <SubItem title={"Sommerkampagnen & Olkampagnen"} />
          <SubItem title={"Olkampagnen"} />
          <SubItem title={"Aktionszeitraum"} />
          <SubItem title={"Sommerkampagnen"} />
        </div>
      )}
    </div>
  );
}
