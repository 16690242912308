import React, { useState } from "react";
import "../index.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import Downarrow from "../../../assets/downarrow.svg";
import MenuItem from "./MenuItem";

export default function RightMenu({ menuOpen, setMenuOpen }) {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div className={`right-menu-content`}>
      <div className="menu-item" onClick={() => setMenuOpen(!menuOpen)}>
        <div className="icon menu-icon"></div>
        {menuOpen && (
          <div className={`icon-text`}>
            <div
              className="text"
              variants={{
                collapsed: { scaleX: 0.1 },
                open: { scaleX: 1 },
              }}
              transition={{ duration: 0.5 }}
            >
              Menu verbergen
            </div>
          </div>
        )}
      </div>
      <MenuItem
        className="dashboard"
        url={`/`}
        title={"Dashboard"}
        menuOpen={menuOpen}
      ></MenuItem>
      <MenuItem
        className="campaign"
        url={`/kampagnen`}
        title={"Kampagnen & Aktionen"}
        menuOpen={menuOpen}
      ></MenuItem>
      <MenuItem
        className="general"
        url={`/generalrelease`}
        title={"General Releases"}
        menuOpen={menuOpen}
      ></MenuItem>
      <MenuItem
        className="order"
        url={`/top-orders`}
        title={"TOP-Bestellungen"}
        menuOpen={menuOpen}
      ></MenuItem>
      <MenuItem
        className="report"
        url={`/reporting`}
        title={"Reporting"}
        menuOpen={menuOpen}
      ></MenuItem>

      <MenuItem
        className="guide"
        url={`/guides`}
        title={"Guides"}
        menuOpen={menuOpen}
      ></MenuItem>
    </div>
  );
}
