import React from "react";
import { Formik, Field, Form } from "formik";

export default function Input({
    label,
    name,
    type,
    placeholder,
    number,
    ...props
}) {
    return (
        <div className='aktion-input'>
            <label htmlFor={name}>{label}</label>
            <div className='urlid-container'>
                <Field
                    id={name}
                    name={name}
                    type={type}
                    placeholder={placeholder}
                    {...props}
                />
                <div className='test-btn'>URL Testen</div>
            </div>
        </div>
    );
}
