import React, { useState, useEffect } from "react";

import { Formik, Form } from "formik";

import Save from "../../../../assets/save.svg";
import Close from "../../../../assets/close.svg";
import FieldRenderer from "../../../../components/forms/FieldRenderer";
import { useData } from "../../../../utils/dataProvider";

export default function ModalKampagne({
  setCampaignModalVisible,
  action,
  divisions,
  currentPlan,
  setData,
}) {
  const fields = [
    {
      name: "divisionID",
      label: "Bereich",
      type: "select",
      options: divisions.map((divison) => {
        return {
          value: divison.entryID,
          label: divison.divisionName,
        };
      }),
    },
    {
      name: "campaignName",
      label: "Bezeichnung",
      type: "text",
    },
    {
      name: "cmpFrom",
      label: "Startdatum",
      type: "datetime",
    },
    {
      name: "cmpTo",
      label: "Endatum",
      type: "datetime",
    },
  ];

  function addCampaign() {
    setCampaignModalVisible(false);
  }

  const { makeApiPost } = useData();

  const submitForm = (values) => {
    values.divisionID = JSON.parse(values.divisionID)["value"];
    makeApiPost(`/campaign/${values.divisionID}`, values, currentPlan, setData);
    setCampaignModalVisible(false);
  };
  return (
    <Formik
      initialValues={{}}
      onSubmit={(values) => {
        submitForm(values);
      }}
    >
      {({ values, errors }) => (
        <Form>
          <div className="modal-wrapper">
            <div className="modal-window">
              <img
                className="close"
                onClick={() => setCampaignModalVisible(false)}
                src={Close}
              ></img>
              <div
                className="aktion-formular-content"
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <h1>Neue Kampagne</h1>
                <div></div>
                <div className="fields">
                  <div className="section-block">
                    <div className="section-title">Stammdaten</div>
                    <div className="section-form">
                      <FieldRenderer
                        noValidate={true}
                        fields={fields}
                        values={values}
                      />
                    </div>
                  </div>
                </div>
                <button
                  type="submit"
                  className="toyota-button toyota-button-red"
                  style={{
                    marginLeft: 16,
                    marginTop: 16,
                    alignSelf: "end",
                  }}
                >
                  <img
                    style={{
                      marginRight: "12px",
                      height: "20px",
                    }}
                    src={Save}
                  ></img>
                  <div>Kampagne hinzufügen</div>
                </button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
