import React, { useState } from "react";
import { Formik, Field, useField, useFormikContext } from "formik";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css fileimport DatePicker from "react-datepicker";
import { DateRangePicker } from "react-date-range";
import { de } from "date-fns/locale";

export default function NeuDatePicker({ label, ...props }) {
    const { setFieldValue } = useFormikContext();
    const [field] = useField(props);
    const [visible, setVisible] = useState(false);
    const selectionRange = {
        startDate: new Date(),
        endDate: new Date(),
        key: field.name,
    };
    const handleInputChange = (val) => {
        setFieldValue(field.name, {
            startDate: val[field.name].startDate,
            endDate: val[field.name].endDate,
            key: field.name,
        });
    };
    return (
        <div className='aktion-input'>
            <label htmlFor={field.name}>{label}</label>
            <div
                className='input calendar-input'
                onClick={() => setVisible(!visible)}>
                {field.value?.startDate &&
                    field.value?.endDate &&
                    new Date(field.value?.startDate).toLocaleDateString() +
                        " - " +
                        new Date(field.value?.endDate).toLocaleDateString()}
            </div>
            {visible && (
                <DateRangePicker
                    locale={de}
                    {...field}
                    {...props}
                    ranges={[field.value ? field.value : selectionRange]}
                    onChange={(val) => handleInputChange(val)}
                />
            )}
        </div>
    );
}
