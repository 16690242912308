import React, { useState, useEffect } from "react";
import { useData } from "../../../utils/dataProvider";
import "./style.scss";

export default function Iframe({ url }) {
  const { makeApiPostRequest, userData } = useData();
  const [authUrl, setAuthUrl] = useState("");

  useEffect(() => {
    if (userData && url) {
      const fetchToken = async () => {
        var auth = new URL(url);

        const token = await makeApiPostRequest("/initiateUserSession", {
          dealerNumber: "99999",
          userID: userData.userEmail,
        });
        auth.searchParams.append("st", localStorage.getItem("token"));
        setAuthUrl(auth);
      };
      fetchToken();
    }
  }, [url, userData]);
  return (
    <div className="fusion-iframe-wrapper">
      <iframe src={authUrl} />
    </div>
  );
}
