import React, { useState } from "react";
import { useData } from "../../../../../utils/dataProvider";
import Save from "../../../../../assets/save.svg";
import Close from "../../../../../assets/close.svg";
import * as XLSX from "xlsx/xlsx";
import Loading from "../../../../commonComponents/Loading";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import Example from "../../../../../assets/excelexample.png";

export default function ModalAktion({
  setShowExcelModal,
  modelID,
  getArticles,
  discount,
}) {
  const [importing, setImporting] = useState(false);
  const [tempArticles, setTempArticles] = useState([]);
  const { makeApiPostRequest } = useData();

  function updateExcel({ file, meta }) {
    if (meta.status === "removed") {
      setTempArticles([]);
      return;
    }
    var f = file;

    var reader = new FileReader();
    reader.onload = function (e) {
      var data = e.target.result;
      let readedData = XLSX.read(data, { type: "binary" });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];

      /* Convert array to json*/
      const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
      dataParse.shift();

      var filtered = dataParse.filter(function (el) {
        return el.length > 0;
      });
      setTempArticles(filtered);
    };
    reader.readAsBinaryString(f);
  }

  async function uploadArticles() {
    setImporting(true);
    if (!tempArticles.length) {
      return;
    }
    const articleList = tempArticles.map((article) => {
      if (article[0]?.length > 0) {
        return {
          number: article[0].replaceAll("-", ""),
          discountPercent: parseFloat(discount).toFixed(2),
        };
      }
    });

    const upload = await makeApiPostRequest(`/item/${modelID}`, {
      items: JSON.stringify(articleList),
    });
    if (upload) {
      setImporting(false);
      setShowExcelModal(false);
      getArticles();
    } else {
      alert("Fehler beim Upload");
      setShowExcelModal(false);
    }
  }

  return (
    <div className="modal-wrapper">
      <div className="modal-window" style={{ overflow: "scroll" }}>
        <img
          className="close"
          onClick={() => setShowExcelModal(false)}
          src={Close}
        ></img>
        <div
          className="aktion-formular-content"
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <h1>Artikel importieren</h1>
          <div style={{ marginBottom: 12 }}>
            Die Teilenummern bitte in einer einspaltigen Excel-Datei hochladen.
            Die erste Zeile wird nicht mit ausgelesen und kann bspw. als
            Überschrift "Bestellnummern" genutzt werden.
          </div>
          <div style={{ marginBottom: 12 }}>Beispiel:</div>
          <img src={Example} />

          <div className="fields">
            <div
              className="section-block"
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div className="section-title">Daten</div>
              <div className="section-form" style={{ width: "100%" }}>
                <Dropzone
                  inputContent={"Datei auswählen"}
                  maxFiles={1}
                  width="300"
                  onChangeStatus={updateExcel}
                />
              </div>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            {importing ? (
              <Loading height="20px" width="20px" />
            ) : (
              <button
                type="button"
                onClick={() => uploadArticles()}
                className="toyota-button toyota-button-red"
                style={{
                  marginLeft: 16,
                  marginTop: 16,
                  alignSelf: "end",
                  opacity: tempArticles.length ? 1 : 0.5,
                }}
              >
                <img
                  style={{
                    marginRight: "12px",
                    height: "20px",
                  }}
                  src={Save}
                ></img>
                {tempArticles?.length} Artikel importieren
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
