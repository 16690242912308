import React, { useState, useEffect } from "react";
import "./index.scss";
import { useData } from "../../../utils/dataProvider";
import axios from "axios";
import { Link } from "react-router-dom";
import { url } from "../../../utils/configs";
import Arrow from "../../../assets/right-arrow.svg";
import { motion, AnimatePresence } from "framer-motion";

export default function ({ changes, setChanges, setChangeLoading }) {
  const { makeApiRequest, userData } = useData();
  const [articleChecked, setArticleChecked] = useState([false]);
  const [filterChanges, setFilterChanges] = useState(changes);
  const [view, setView] = useState(0);

  useEffect(() => {
    if (view == 1) {
      setFilterChanges([...changes]);
    } else if (view == 0) {
      let array = [];
      changes?.forEach((item, idx) => {
        let names = JSON.parse(item.detailOwner);
        if (names.findIndex((ele) => ele.label == userData.userName) < 0) {
        } else {
          array.push(item);
        }
      });
      setFilterChanges([...array]);
    }
  }, [view]);

  const generateKurzName = (name) => {
    if (name.split(" ").length > 1) {
      return name.split(" ")[0][0] + name.split(" ")[1][0];
    } else {
      return name.split(" ")[0][0] + name.split(" ")[0][1];
    }
  };
  const checkedNumer = () => {
    let sum = 0;
    articleChecked.forEach((ele) => {
      if (ele) {
        sum++;
      }
    });
    return sum;
  };

  async function fetchChanges() {
    const apiChanges = await makeApiRequest("/markedChanges");
    setChanges(apiChanges.data);
    setChangeLoading(false);
  }

  function userChanges() {
    let num = 0;
    if (changes) {
      for (let i = 0; i < changes.length; i++) {
        let names = JSON.parse(changes[i].detailOwner);
        if (names.findIndex((ele) => ele.label == userData.userName) >= 0) {
          num = num + 1;
        }
      }
    }
    return num;
  }
  const changeConfirm = () => {
    setChangeLoading(true);
    let submitData = [];
    filterChanges.forEach((item, idx) => {
      if (articleChecked[idx]) {
        submitData.push(item);
      }
    });
    let Promises = [];
    const token = localStorage.getItem("token");
    submitData.forEach((single, index) => {
      let data = single;
      data.changed = null;
      changeDataprocess(data);
      var form_data = new FormData();
      for (var key in data) {
        form_data.append(key, data[key]);
      }
      Promises.push(
        axios.put(url + "/item/" + single.itemID, form_data, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
      );
    });
    Promise.all(Promises)
      .then((res) => {
        fetchChanges();
      })
      .catch((error) => {
        setChangeLoading(false);
      });
  };

  function changeDataprocess(data) {
    data.changes.forEach((change) => {
      if (change.RAC_new) {
        data.regRAC = change.RAC_new;
      }
      if (change.NDC_new) {
        data.regNDC = change.NDC_new;
      }
      if (change.CIF_new) {
        data.regCIF = change.CIF_new;
      }
      if (change.SUBST_new) {
        data.regSUBST = change.SUBST_new;
      }
    });
  }

  return (
    <div className="update-container">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div className="update-title">Unbestätigte Änderungen</div>
        <div className="view-switch" style={{ height: 42 }}>
          <button
            type="button"
            className={`toyota-button toyota-button-blue ${
              view === 0 ? "active" : ""
            }`}
            onClick={() => {
              setView(0);
              setArticleChecked([false]);
            }}
          >
            Von mir ({userChanges()})
          </button>
          <button
            type="button"
            className={`toyota-button toyota-button-blue ${
              view === 1 ? "active" : ""
            }`}
            onClick={() => {
              setView(1);
              setArticleChecked([false]);
            }}
          >
            Alle ({changes.length})
          </button>
        </div>
      </div>
      <table border="0">
        <thead>
          <tr>
            <th>
              {filterChanges.length > 0 && (
                <input
                  type="checkbox"
                  checked={articleChecked.every((ele) => ele == true)}
                  onChange={() => {
                    let array = [];
                    if (articleChecked.every((ele) => ele == true)) {
                      filterChanges.map((item) => {
                        array.push(false);
                      });
                    } else {
                      filterChanges.map((item) => {
                        array.push(true);
                      });
                    }
                    setArticleChecked([...array]);
                  }}
                />
              )}
            </th>
            <th>Nummer</th>
            <th>Änderungswert</th>
            <th>Alter Wert</th>
            <th>Neuer Wert</th>
            <th>Verantwortlich</th>
            <th>Detail</th>
            <th>Prüfen</th>
          </tr>
        </thead>
        <tbody>
          {filterChanges &&
            filterChanges?.map((item, idx) => {
              return (
                <React.Fragment key={`anderung-tr-${idx}`}>
                  <tr style={{ background: idx % 2 == 0 ? "white" : "#eee" }}>
                    <td>
                      <input
                        type="checkbox"
                        checked={articleChecked[idx]}
                        onChange={() => {
                          let array = [...articleChecked];
                          if (articleChecked[idx]) {
                            array[idx] = false;
                          } else {
                            array[idx] = true;
                          }
                          setArticleChecked([...array]);
                        }}
                      />
                    </td>
                    <td>{item.number}</td>
                    <td>
                      {Object.keys(item.changes[0])[0]?.substring(
                        0,
                        Object.keys(item.changes[0])[0]?.indexOf("_")
                      )}
                    </td>
                    <td>{item.changes[0][Object.keys(item.changes[0])[0]]}</td>
                    <td>{item.changes[0][Object.keys(item.changes[0])[1]]}</td>
                    <td>
                      <div className="name-bar">
                        {item.detailOwner &&
                          JSON.parse(item.detailOwner).map((person, index) => (
                            <div
                              className="name-bar-name"
                              key={person.label + idx + index}
                              data-tip={person.label}
                            >
                              {generateKurzName(person.label)}
                            </div>
                          ))}
                      </div>
                    </td>
                    <td>{item.detailName}</td>
                    <td>
                      <Link
                        to={{
                          pathname: `/detail/${item.detailID}#Artikel`,
                        }}
                        state={{ itemNumber: item.number }}
                        style={{ color: "white" }}
                      >
                        <div className="button">
                          <img src={Arrow} style={{ height: 20 }} />
                        </div>
                      </Link>
                    </td>
                  </tr>
                  {item?.changes?.length > 1 &&
                    item.changes.map((extra, extraidx) => {
                      if (extraidx > 0) {
                        return (
                          <tr
                            key={`anderung-tr-${idx}-${extraidx}`}
                            style={{
                              background: idx % 2 == 0 ? "white" : "#eee",
                            }}
                          >
                            <td></td>
                            <td></td>
                            <td>
                              {Object.keys(extra)[0].substring(
                                0,
                                Object.keys(extra)[0].indexOf("_")
                              )}
                            </td>
                            <td>{extra[Object.keys(extra)[0]]}</td>
                            <td>{extra[Object.keys(extra)[1]]}</td>
                            <td>
                              <div className="name-bar">
                                {item.detailOwner &&
                                  JSON.parse(item.detailOwner).map(
                                    (person, index) => (
                                      <div
                                        className="name-bar-name"
                                        key={person.label + idx + index}
                                        data-tip={person.label}
                                      >
                                        {generateKurzName(person.label)}
                                      </div>
                                    )
                                  )}
                              </div>
                            </td>
                            <td>{item.detailName}</td>
                            <td>
                              <Link
                                to={{
                                  pathname: `/detail/${item.detailID}#Artikel`,
                                }}
                                state={{ itemNumber: item.number }}
                                style={{ color: "white" }}
                              >
                                <div className="button">
                                  <img src={Arrow} style={{ height: 20 }} />
                                </div>
                              </Link>
                            </td>
                          </tr>
                        );
                      }
                    })}
                </React.Fragment>
              );
            })}
        </tbody>
      </table>
      {articleChecked.includes(true) && (
        <div
          onClick={changeConfirm}
          className="toyota-button toyota-button-green"
          style={{ margin: 8 }}
        >
          {checkedNumer()} {checkedNumer() > 1 ? "Änderungen" : "Änderung"}{" "}
          übernehmen
        </div>
      )}
    </div>
  );
}
