import React, { useState, useEffect } from "react";

import { Formik, Form, Field, FieldArray } from "formik";
import { useParams, useLocation } from "react-router-dom";
import { useData } from "../../../../utils/dataProvider";
import Save from "../../../../assets/save.svg";
import Close from "../../../../assets/close.svg";
import mockSets from "./mockSets";
import SetRow from "./SetRow";
import { url } from "../../../../utils/configs";
import axios from "axios";
import Loader from "../../../commonComponents/Loading";

export default function ModalAktion({
  setShowArticleModal,
  setShowTable,
  modelName,
  modelID,
  item,
  setModels,
  data,
  setSetData,
  studenSatz,
  update,
  setUpdate,
  getItems,
}) {
  function importArticles() {
    setShowTable(true);
    setShowArticleModal(false);
  }
  const { id } = useParams();

  function doMoney(float) {
    if (float) {
      return parseFloat(float).toLocaleString("de-DE", {
        style: "currency",
        currency: "EUR",
      });
    }
  }

  const {
    makeApiRequest,
    makeApiPutRequest,
    makeApiPostRequest,
    makeApiDeleteRequest,
  } = useData();
  const [items, setItems] = useState([]);
  const [deletedItems, setDeletedItems] = useState([]);

  const [oldItems, setOldItems] = useState([]);
  const [name, setName] = useState(item ? item?.name : "");
  const [price, setPrice] = useState(item ? item?.itemsetSalePrice : "");
  const [setsNumber, setSetsNumber] = useState(item ? item?.number : "");
  const [StdSatz, setStdSatz] = useState(studenSatz.toLocaleString("de-DE"));
  const [setFittingTime, setSetFittingTime] = useState(
    parseFloat(item?.fittingTime).toFixed(2)
  );
  const [partsNumber, setPartsNumber] = useState(null);
  const [loading, setLoading] = useState(true);
  const [saveLoading, setSaveLoading] = useState(false);

  async function fetchItemCart() {
    if (item) {
      const apiData = await makeApiRequest("/itemsetCarts/" + item.entryID);
      for (let i = 0; i < apiData.data.length; i++) {
        const itemRequest = await makeApiRequest(
          `/accessories/models/${apiData.data[i].number}`
        );
      }
      setItems(apiData.data);
      setLoading(false);
    } else {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchItemCart();
  }, [update]);

  function getDiscounted(val, discount) {
    return val - (val * discount) / 100;
  }

  function sumUVP() {
    return items.reduce((acc, item) => {
      return acc + item.grossSalePrice;
    }, 0);
  }

  function sumNettoUVP() {
    return items.reduce((acc, item) => {
      return acc + item.grossSalePrice * 0.84;
    }, 0);
  }

  function sumWAP(parts = false) {
    return items.reduce((acc, item) => {
      return acc + item.retailPrice;
    }, 0);
  }

  function sumEB() {
    let sum = 0;
    sum = items.reduce((acc, item) => {
      return acc + parseFloat(item?.fitting_time ? item?.fitting_time : 0);
    }, 0);

    sum = sum + parseFloat(setFittingTime);
    return sum;
  }

  function sumEK() {
    return items.reduce((acc, item) => {
      return acc + item.tdg_ek;
    }, 0);
  }

  function sumTMEEK() {
    return items.reduce((acc, item) => {
      return acc + item.tme_ek;
    }, 0);
  }

  function sumEKNetto(items) {
    let sum = 0;
    sum = items.reduce((acc, set) => {
      return acc + set.fitting_time ? set.fitting_time * StdSatz : 0;
    }, 0);
    sum = sum + setFittingTime * StdSatz;
    return sum;
  }

  function sumEKBrutto(items) {
    let sum = 0;
    sum = items.reduce((acc, set) => {
      return acc + set.fitting_time ? set.fitting_time * StdSatz : 0;
    }, 0);
    sum = (sum + setFittingTime * StdSatz) * 1.19;
    return sum;
  }

  function removeRabattFromValue(rabatt, value) {
    if (typeof value === "number" && rabatt < 100) {
      return value - (value * rabatt) / 100;
    }
    return value;
  }

  function sumWAPNeu(items, parts) {
    return items?.reduce((acc, item) => {
      return (
        acc + removeRabattFromValue(item.discountPercent, item.retailPrice)
      );
    }, 0);
  }

  function getNetto(value) {
    return value - value * 0.19;
  }

  const deleteSinglePart = (index) => {
    let deleted = structuredClone(deletedItems);
    deleted.push(items[index]);
    setDeletedItems(deleted);
    let array = structuredClone(items);
    array.splice(index, 1);
    setItems([...array]);
  };

  async function getDetailModels() {
    const modelRequest = await makeApiRequest(`/models/${id}`);
    setModels(modelRequest?.data);
  }

  async function createSet() {
    setSaveLoading(true);

    let setData = {
      number: setsNumber,
      name: name,
      itemsetSalePrice: price,
      fittingTime: parseFloat(setFittingTime),
    };
    const createSetRequest = await makeApiPostRequest(
      `/itemset/${modelID}`,
      setData
    );
    let itemsetID = createSetRequest?.response?.entryID;
    for (const setCart of items) {
      await makeApiPostRequest(`/itemSetCart/${itemsetID}`, setCart);
    }

    setUpdate(!update);
    setSaveLoading(false);

    setShowArticleModal(false);
  }

  async function saveSet() {
    setSaveLoading(true);
    let setData = {
      number: setsNumber,
      name: name,
      itemsetSalePrice: parseFloat(price.replace(",", ".")),
      fittingTime: parseFloat(setFittingTime.replace(",", ".")),
    };
    await makeApiPutRequest(`/itemset/${item.entryID}`, setData);
    let itemsetID = item.entryID;
    for (const SingleItem of items) {
      if (SingleItem.entryID) {
        await makeApiPutRequest(
          `/itemSetCart/${SingleItem.entryID}`,
          SingleItem
        );
      } else {
        await makeApiPostRequest(`/itemSetCart/${itemsetID}`, SingleItem);
      }
    }
    for (const setItem of deletedItems) {
      await makeApiDeleteRequest(`/itemSetCart/${setItem.entryID}`);
    }

    setDeletedItems([]);
    setUpdate(!update);
    setSaveLoading(false);
    setShowArticleModal(false);
  }
  async function addArticle() {
    const token = localStorage.getItem("token");
    axios
      .get(url + "/parts/priceinfo/" + partsNumber, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        axios
          .get(url + "/accessories/models/" + partsNumber, {
            headers: {
              Authorization: "Bearer " + token,
            },
          })
          .then((res2) => {
            let item = res.data;
            item.name = res.data.name ? res.data.name : "Item";
            item.rabatt = res.data.discountPercent
              ? res.data.discountPercent
              : 0;
            item.number = partsNumber;

            if (res2.data.fitting_time) {
              item.fitting_time = res2.data?.fitting_time;
            } else if (res.data.length > 0) {
              item.fitting_time = res2.data[0]?.fitting_time;
            } else {
              item.fitting_time = 0;
            }
            setItems([...items, item]);
          });
      });
  }

  return (
    <div className="modal-wrapper set-modal">
      <div className="modal-window">
        <img
          className="close"
          onClick={() => setShowArticleModal(false)}
          src={Close}
        ></img>
        <div
          className="aktion-formular-content"
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: 24,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h1>Set {item ? "bearbeiten" : "erstellen"}</h1>
            <div
              type="button"
              onClick={() => {
                if (item?.entryID) {
                  saveSet();
                } else {
                  createSet();
                }
              }}
              className="toyota-button toyota-button-red"
              style={{
                marginLeft: 16,
                marginTop: 16,
                width: 180,
              }}
            >
              {saveLoading ? (
                <Loader height={20} width={20} />
              ) : (
                <>
                  <img
                    style={{
                      marginRight: "12px",
                      height: "20px",
                    }}
                    src={Save}
                  ></img>
                   Set {item ? "speichern" : "erstellen"}
                </>
              )}
            </div>
          </div>
          <div style={{ marginBottom: 12 }}>Für {modelName}</div>
          <div>
            {" "}
            <h3>Allgemeines</h3>
            <div className="fields">
              <div className="aktion-input">
                <label htmlFor="name">Bezeichnung</label>
                <input
                  type="text"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="aktion-input">
                <label>Setpreis Brutto</label>
                <div className="euro-field">
                  <input
                    type="number"
                    name="price"
                    lang="de-DE"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                  />
                  <div className="euro-sign">€</div>
                </div>
              </div>
              <div className="aktion-input">
                <label>Set Nummer</label>
                <div className="euro-field">
                  <input
                    number="text"
                    name="setsNumber"
                    value={setsNumber}
                    onChange={(e) => setSetsNumber(e.target.value)}
                  />
                </div>
              </div>
              <div className="aktion-input">
                <label htmlFor="StdSatz">Std-Satz netto</label>
                <div className="euro-field">
                  <input
                    lang="de-DE"
                    id="StdSatz"
                    type="number"
                    name="StdSatz"
                    value={StdSatz}
                    disabled
                  />
                </div>
              </div>
              <div className="aktion-input">
                <label>Einbauzeit (in Stunden)</label>
                <div className="euro-field">
                  <input
                    lang="de-DE"
                    type="number"
                    name="setfittingTime"
                    value={setFittingTime}
                    onChange={(e) => setSetFittingTime(e.target.value)}
                  />
                </div>
              </div>
              <div className=" setForm">
                <label>Teilenummer hinzufügen:</label>
                <div className="aktion-input">
                  <input
                    type="text"
                    name="partsNumber"
                    value={partsNumber}
                    onChange={(e) => setPartsNumber(e.target.value)}
                  />
                  <div
                    type="button"
                    style={{
                      marginTop: "1rem",
                    }}
                    onClick={addArticle}
                    className="toyota-button toyota-button-blue"
                  >
                    +
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <h3>Kalkulation</h3>
            <table>
              <thead>
                <tr>
                  <th>Bezeichnung</th>
                  <th>Artikel-Nr.</th>
                  <th>UVP</th>
                  <th>UVP netto</th>
                  <th>NDC</th>
                  <th>WAP</th>
                  <th>WAP %</th>
                  <th>WAP neu</th>
                  <th>TDG-EK</th>
                  <th>TME-EK</th>
                  <th>Einbauzeit</th>
                  <th>Einbau € netto</th>
                  <th>Einbau € brutto</th>
                  <th>TOS</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {items ? (
                  loading ? (
                    <Loader height={20} width={20} />
                  ) : items.length > 0 ? (
                    items.map((set, idx) => (
                      <SetRow
                        itemIndex={idx}
                        StdSatz={StdSatz}
                        set={set}
                        deleteSinglePart={deleteSinglePart}
                        setItems={setItems}
                        items={items}
                        EKTotal={sumEK}
                      />
                    ))
                  ) : null
                ) : null}
                {setFittingTime > 0 && (
                  <tr>
                    <th>Einbauzeit Set</th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>
                      {parseFloat(setFittingTime).toLocaleString("de-DE")}
                    </th>
                    <th>{doMoney(setFittingTime * StdSatz)}</th>
                    <th>{doMoney(setFittingTime * StdSatz * 1.19)}</th>
                    <th></th>
                  </tr>
                )}
                <tr className="sum">
                  <th>Set</th>
                  <th></th>
                  <th>{doMoney(sumUVP())}</th>
                  <th>{doMoney(sumNettoUVP())}</th>
                  <th></th>
                  <th>{doMoney(sumWAP())}</th>
                  <th></th>
                  <th>{doMoney(sumWAPNeu(items))}</th>
                  <th>{doMoney(sumEK())}</th>
                  <th>{doMoney(sumTMEEK())}</th>

                  <th>{sumEB().toLocaleString("de-DE")}</th>
                  <th>{doMoney(sumEKNetto(items))}</th>
                  <th>{doMoney(sumEKBrutto(items))}</th>
                  <th></th>
                </tr>
              </tbody>
            </table>
          </div>
          <div></div>
          <div className="calc-info">
            <div className="calc-column">
              <h4>TME-/TDG-Einkaufs- und Margenkalkulation</h4>
              <table>
                <tbody>
                  <tr>
                    <td>Verkauf (WAP)</td>
                    <td>{doMoney(sumWAP(true))}</td>
                    <td>(nur-TDG-Teile)</td>
                  </tr>
                  <tr>
                    <td>WAP-Rabatt</td>
                    <td>{doMoney(sumWAPNeu(items) - sumWAP(true))}</td>
                    <td>(nur-TDG-Teile)</td>
                  </tr>
                  <tr className="border-bottom">
                    <td>EK TDG</td>
                    <td>{doMoney(sumEK(true))}</td>
                  </tr>
                  <tr className="border-bottom">
                    <td>DDP</td>
                    <td>{doMoney(sumWAPNeu(items) - sumEK(true))}</td>
                    <td>
                      {(
                        ((sumWAPNeu(items) - sumEK(true)) / sumWAP(true)) *
                        100
                      ).toFixed(2)}
                      %
                    </td>
                  </tr>
                  <tr className="border-bottom">
                    <td>EK TME(CCM)</td>
                    <td>{doMoney(sumTMEEK())}</td>
                  </tr>
                  <tr className="border-bottom ">
                    <td>DDP-TME</td>
                    <td>{doMoney(sumEK(true) - sumTMEEK())}</td>
                    <td>
                      {(
                        ((sumEK(true) - sumTMEEK()) / sumWAP(true)) *
                        100
                      ).toFixed(2)}
                      %
                    </td>
                  </tr>
                  <tr
                    className="strong"
                    style={{
                      transform: "translateY(12px)",
                    }}
                  >
                    <td>GesamtMarge (TDG+TME)</td>
                    <td className="">
                      {sumTMEEK()
                        ? doMoney(
                            (
                              sumWAPNeu(items) -
                              sumEK(true) +
                              sumEK(true) -
                              sumTMEEK()
                            ).toFixed(2)
                          )
                        : doMoney((sumWAPNeu(items) - sumEK(true)).toFixed(2))}
                    </td>
                    <td>
                      {sumTMEEK()
                        ? (
                            ((sumWAPNeu(items) -
                              sumEK(true) +
                              sumEK(true) -
                              sumTMEEK()) /
                              sumWAP(true)) *
                            100
                          ).toFixed(2)
                        : (
                            ((sumWAPNeu(items) - sumEK(true)) / sumWAP(true)) *
                            100
                          ).toFixed(2)}
                      %
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="calc-column">
              <h4>Händler-Einkaufs- und Margenkalkulation</h4>
              <table>
                <tbody>
                  <tr>
                    <td>WAP Teile</td>
                    <td>{doMoney(sumWAPNeu(items, false))}</td>
                  </tr>
                  <tr>
                    <td>Einbau Netto</td>
                    <td>{doMoney(sumEKNetto(items))}</td>
                  </tr>
                  <tr className="border-bottom">
                    <td>Zw. Summe</td>
                    <td>
                      {doMoney(sumWAPNeu(items, false) + sumEKNetto(items))}
                    </td>
                  </tr>
                  <tr>
                    <td>MWSt.:</td>
                    <td>
                      {doMoney(
                        (sumWAPNeu(items, false) + sumEKNetto(items)) * 0.19
                      )}
                    </td>
                  </tr>
                  <tr className="strong border-bottom space-bottom">
                    <td>Summe</td>
                    <td className="">
                      {doMoney(
                        (sumWAPNeu(items, false) + sumEKNetto(items)) * 1.19
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Aktion-UVP</td>
                    <td className="">{doMoney(price)}</td>
                  </tr>
                  <tr className="space-bottom">
                    <td>Diff. Brutto</td>
                    <td className="">
                      {price > 0
                        ? doMoney(
                            price -
                              (sumWAPNeu(items, false) + sumEKNetto(items)) *
                                1.19
                          )
                        : doMoney("0")}
                    </td>
                  </tr>
                  <tr className="space-bottom">
                    <td>netto Marge zzgl. Lohnumsatz</td>
                    <td className="">
                      {price > 0
                        ? doMoney(
                            (price -
                              (sumWAPNeu(items, false) + sumEKNetto(items)) *
                                1.19) /
                              1.19
                          )
                        : doMoney("0")}
                      (
                      {price > 0
                        ? parseFloat(
                            (
                              ((price -
                                (sumWAPNeu(items, false) + sumEKNetto(items)) *
                                  1.19) /
                                1.19 /
                                sumWAPNeu(items, false)) *
                              100
                            ).toFixed(2)
                          ).toLocaleString("de-DE")
                        : "0"}{" "}
                      %)
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="calc-column">
              <h4>Kalkulation Kundenpreisvorteil</h4>
              <table>
                <tbody>
                  <tr>
                    <td>Teile Brutto</td>
                    <td>{doMoney(sumUVP())}</td>
                  </tr>
                  <tr className="space-bottom">
                    <td>Einbau brutto</td>
                    <td>{doMoney(sumEKBrutto(items))}</td>
                  </tr>
                  <tr className="space-bottom">
                    <td>Normalpreis</td>
                    <td>{doMoney(sumUVP() + sumEKBrutto(items))}</td>
                  </tr>
                  <tr className="space-bottom">
                    <td>Aktionspreis</td>
                    <td>{doMoney(price)}</td>
                  </tr>
                  <tr className="strong">
                    <td>Kundenvorteil</td>
                    <td>
                      {price > 0
                        ? doMoney(sumUVP() + sumEKBrutto(items) - price)
                        : doMoney("0")}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
