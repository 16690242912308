import React, { useState, useEffect } from "react";
import Close from "../../../../assets/close.svg";
import { useData } from "../../../../utils/dataProvider";
import axios from "axios";
import { url } from "../../../../utils/configs";
import Select from "react-select";
import PlanText from "../../../../assets/typText.svg";
import DatePicker, { registerLocale } from "react-datepicker";
import de from "date-fns/locale/de";
import Save from "../../../../assets/save.svg";
import Loader from "../../../commonComponents/Loading.jsx";
registerLocale("de", de);

const options = [
  {
    label: "Rundschreiben (pdf;doc;docx)",
    value: 1,
  },
  { label: "Anleitung (pdf;jpg;png)", value: 2 },
  {
    label: "Mailvorschlag (doc;docx;pdf)",
    value: 3,
  },
  {
    label: "Textvorschlag (doc;docx;pdf)",
    value: 4,
  },
  {
    label: "Sonst. Dokument (doc;docx;xls;xlsx;pdf;jpg;jpeg;png)",
    value: 5,
  },
  {
    label: "Grafik(jpg;jpeg;png)",
    value: 6,
  },

  { label: "Video (mp4;mov;avi)", value: 8 },
  { label: "Keyvisual (jpg;jpeg;png)", value: 9 },
];

function getOption(type) {
  switch (type) {
    case "Rundschreiben":
      return 0;
      break;
    case "Anleitung":
      return 1;
      break;
    case "Mailvorschlag":
      return 2;
      break;
    case "Textvorschlag":
      return 3;
      break;
    case "Sonst. Dokument":
      return 4;
      break;
    case "Grafik":
      return 5;
      break;
    case "Video":
      return 6;
      break;
    case "Keyvisual":
      return 7;
      break;
  }
}

export default function HochladenModal({
  setModalVisible,
  id,
  update,
  setUpdate,
  data,
  location,
}) {
  const [title, setTitle] = useState(data?.Content ? data?.Content : null);
  const [beschreibung, setBeschreibung] = useState(
    data?.Description ? data?.Description : null
  );
  const [type, setType] = useState(
    data?.fileType ? options[getOption(data?.fileType)] : null
  );
  const [from, setFrom] = useState(
    data?.validFrom && data?.validFrom !== "null"
      ? new Date(data?.validFrom)
      : null
  );
  const [to, setTo] = useState(
    data?.validTo && data?.validTo !== "null" ? new Date(data?.validTo) : null
  );
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  function checkUpdate() {
    if (file && title && type) {
      return false;
    } else {
      return true;
    }
  }

  const customStyles1 = {
    control: (provided, state) => ({
      ...provided,
      paddingLeft: "54px",
      backgroundImage: `url("${PlanText}")`,
      backgroundPosition: "8px center",
      backgroundRepeat: "no-repeat",
    }),
  };

  function fileTypeString(str) {
    const openParenIndex = str.indexOf("(");
    const closeParenIndex = str.indexOf(")");
    const subStr = str.substring(openParenIndex + 1, closeParenIndex);
    return "." + subStr.replaceAll(";", ", .");
  }

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  function fileUpdate() {
    setLoading(true);
    const token = localStorage.getItem("token");
    var form_data = new FormData();

    let newdata = {
      fileName: beschreibung,
      fileType: type.value,
      fileDescription: title,
      fileValidFrom: from ? formatDate(from) : null,
      fileValidTo: to ? formatDate(to) : null,
    };

    for (var key in newdata) {
      form_data.append(key, newdata[key]);
    }

    axios({
      method: "put",
      url: url + `/${location}/files/${data.fileID}`,
      data: form_data,
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        setUpdate(!update);
        setModalVisible(false);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  function fileupload() {
    setLoading(true);
    const token = localStorage.getItem("token");
    var form_data = new FormData();

    let data = {
      campaignID: id,
      fileName: title,
      fileType: type.value,
      fileDescription: beschreibung,
      fileValidFrom: from ? formatDate(from) : null,
      fileValidTo: to ? formatDate(to) : null,
    };

    for (var key in data) {
      form_data.append(key, data[key]);
    }
    form_data.append("fileContent", file);

    axios({
      method: "post",
      url: url + `/${location}/files/${id}`,
      data: form_data,
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        setUpdate(!update);
        setModalVisible(false);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  return (
    <div className="modal-wrapper">
      <div className="modal-window">
        <img
          className="close"
          src={Close}
          onClick={() => setModalVisible(false)}
        ></img>
        {data ? <h2>Datei zu bearbeiten</h2> : <h2>Datei zu hochladen</h2>}
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "flex-end",
          }}
        >
          <div className="upload-form">
            <label htmlFor="title">Titel</label>
            <input
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            ></input>
            <label htmlFor="beschreibung">Beschreibung</label>
            <textarea
              id="beschreibung"
              value={beschreibung}
              onChange={(e) => setBeschreibung(e.target.value)}
            ></textarea>

            <Select
              className="kanpagnen-header-select"
              placeholder={"Nicht relevant"}
              styles={customStyles1}
              options={options}
              value={type}
              onChange={(val) => {
                setType(val);
              }}
            />
            <label htmlFor="from" style={{ marginTop: 12 }}>
              Gültig von
            </label>
            <DatePicker
              className="calendar-input"
              placeholderText="Bitte Datum auswählen"
              dateFormat="P"
              locale="de"
              selected={from}
              onChange={(val) => {
                setFrom(val);
              }}
            />
            <label htmlFor="to">Gültig bis</label>
            <DatePicker
              className="calendar-input"
              placeholderText="Bitte Datum auswählen"
              dateFormat="P"
              locale="de"
              selected={to}
              onChange={(val) => {
                setTo(val);
              }}
            />
            {!data && (
              <>
                <label htmlFor="datei">Datei</label>
                <div>
                  <div>
                    <label
                      for="file-upload"
                      style={{ cursor: "pointer" }}
                      class="custom-file-upload"
                    >
                      <div className="upload1">Datei auswählen</div>
                      <div className="upload2">
                        {file
                          ? file.name.length > 32
                            ? file.name.substring(0, 32) + "..."
                            : file.name
                          : "Keine Datei ausgewählt"}
                      </div>
                    </label>
                    <input
                      id="file-upload"
                      accept={type && fileTypeString(type.label)}
                      type="file"
                      onChange={(e) => {
                        setFile(e.target.files[0]);
                      }}
                    />
                  </div>
                </div>
              </>
            )}
            {data ? (
              <div
                className="toyota-button toyota-button-green"
                onClick={fileUpdate}
                style={{
                  alignSelf: "flex-end",
                  marginTop: 16,
                }}
              >
                {loading ? (
                  <Loader height={16} width={16} />
                ) : (
                  <img src={Save}></img>
                )}
                <span style={{ marginLeft: 5 }}>Datei Aktualisieren</span>
              </div>
            ) : (
              <>
                {checkUpdate() ? (
                  <div
                    className="toyota-button"
                    style={{
                      alignSelf: "flex-end",
                      marginTop: 16,
                      backgroundColor: "#ccc",
                    }}
                  >
                    <img src={Save}></img>
                    <span style={{ marginLeft: 5 }}>Datei hochladen</span>
                  </div>
                ) : (
                  <div
                    className="toyota-button toyota-button-green"
                    onClick={fileupload}
                    style={{
                      alignSelf: "flex-end",
                      marginTop: 16,
                    }}
                  >
                    {loading ? (
                      <Loader height={16} width={16} />
                    ) : (
                      <img src={Save}></img>
                    )}
                    <span style={{ marginLeft: 5 }}>Datei hochladen</span>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
