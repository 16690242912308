import React, { useState } from "react";
import Logo from "../../assets/toyota-logo-white.svg";
import "./index.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import Close from "../../assets/close.svg";
import { useData } from "../../utils/dataProvider";

export default function RightMenu({ content, setRightMenuOpen }) {
    const location = useLocation();
    const navigate = useNavigate();
    const { userData, doLogout } = useData();

    function MenuContent() {
        switch (content) {
            case "User":
                return (
                    <div className='right-menu-title'>
                        <h1>Mein Konto</h1>
                        <div>{userData.userName}</div>
                        <div>{userData.userEmail}</div>
                        <div
                            onClick={doLogout}
                            style={{
                                cursor: "pointer",
                                marginTop: 48,
                                color: "#ff0022",
                            }}>
                            Abmelden
                        </div>
                    </div>
                );
                break;
            case "FAQ":
                return (
                    <div className='right-menu-title'>
                        <h1>{content}</h1>;
                    </div>
                );
        }
    }

    return (
        <div className={`right-menu-content`}>
            <img
                className='close'
                src={Close}
                onClick={() => setRightMenuOpen(false)}></img>
            {MenuContent()}
        </div>
    );
}
