import React, { useEffect, useRef } from "react";

export default function Compare({ prevValue, currentValue }) {
  const diff =
    Math.round(((currentValue - prevValue) / prevValue) * 100 * 100) / 100;

  if (prevValue == 0) {
    return (
      <div
        className="item-difference"
        style={{ color: diff >= 0 ? "green" : "red" }}
      >
        +{currentValue - prevValue}
      </div>
    );
  }
  return (
    <div
      className="item-difference"
      style={{ color: diff >= 0 ? "green" : "red" }}
    >
      {diff >= 0 ? "+" : ""}
      {diff}%
    </div>
  );
}
