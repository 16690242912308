import React, { useState, useEffect } from "react";

import { Formik, Form } from "formik";
import { useParams, useLocation } from "react-router-dom";
import { useData } from "../../../../utils/dataProvider";
import Save from "../../../../assets/save.svg";
import Close from "../../../../assets/close.svg";
import FieldRenderer from "../../../../components/forms/FieldRenderer";
import axios from "axios";
import Select from "react-select";
import PlanText from "../../../../assets/plantext.svg";
import FilterText from "../../../../assets/divisionText.svg";
import { url } from "../../../../utils/configs";
import Loader from "../../../commonComponents/Loading";

export default function CopyModal({
    setCopyModalVisible,
    campaignID,
    setData,
}) {
    const location = useLocation();

    const {
        currentPlan,
        setCurrentPlan,
        plans,
        makeApiRequest,
        copyKampagnen,
    } = useData();

    const [selectedPlan, setSelectPlan] = useState(currentPlan);
    const [selectDivision, setSelectDivision] = useState(null);
    const [pasteLoading, setPasteLoading] = useState(false);

    const planData =
        plans &&
        plans.map((plan, idx) => {
            return {
                value: plan.entryID,
                label: plan.msplanName,
                idx: idx,
            };
        });
    const customStyles1 = {
        control: (provided, state) => ({
            ...provided,
            paddingLeft: "84px",
            backgroundImage: `url("${PlanText}")`,
            backgroundPosition: "8px center",
            backgroundRepeat: "no-repeat",
        }),
    };

    const customStyles2 = {
        control: (provided, state) => ({
            ...provided,
            paddingLeft: "84px",
            backgroundImage: `url("${FilterText}")`,
            backgroundPosition: "8px center",
            backgroundRepeat: "no-repeat",
            marginTop: "16px",
        }),
    };

    function getDivisionOptions() {
        let idx = plans.findIndex((ele) => ele.entryID == selectedPlan.value);
        let divisionArray = [];
        plans[idx].child.map((division, index) => {
            divisionArray.push({
                value: division.entryID,
                label: division.divisionName,
                idx: index,
            });
        });
        return divisionArray;
    }

    function KampagnenPaste() {
        setPasteLoading(true);
        const token = localStorage.getItem("token");
        var form_data = new FormData();
        for (var key in copyKampagnen) {
            form_data.append(key, copyKampagnen[key]);
        }
        axios({
            method: "post",
            url: url + `/campaign/${selectDivision.value}`,
            data: form_data,
            headers: {
                Authorization: "Bearer " + token,
            },
        }).then((newKampagnen) => {
            if (copyKampagnen.child && copyKampagnen.child.length > 0) {
                let promises = [];

                copyKampagnen.child.forEach((aktionData) => {
                    promises.push(
                        AktionPaste(
                            aktionData,
                            newKampagnen.data.response.entryID
                        )
                    );
                });
                Promise.all(promises).then(() => {
                    if (currentPlan) {
                        axios
                            .get(url + "/msplan/" + currentPlan.value, {
                                headers: {
                                    Authorization: "Bearer " + token,
                                },
                            })
                            .then((response) => {
                                if (setData) {
                                    setData(response.data?.data[0]?.child);
                                }
                            });
                    }
                    setCopyModalVisible(false);
                    setPasteLoading(false);
                });
            } else {
                setPasteLoading(false);
            }
        });
    }

    function AktionPaste(aktionData, entryID) {
        const token = localStorage.getItem("token");
        var form_data = new FormData();
        for (var key in aktionData) {
            form_data.append(key, aktionData[key]);
        }
        axios({
            method: "post",
            url: url + `/action/${entryID}`,
            data: form_data,
            headers: {
                Authorization: "Bearer " + token,
            },
        }).then((newAktion) => {
            if (aktionData.child && aktionData.child.length > 0) {
                let promises = [];

                aktionData.child.forEach((detailData) => {
                    promises.push(
                        DetailPaste(detailData, newAktion.data.response.entryID)
                    );
                });
                Promise.all(promises).then(() => {});
            }
        });
    }

    function DetailPaste(detailData, entryID) {
        const token = localStorage.getItem("token");
        var form_data = new FormData();
        for (var key in detailData) {
            form_data.append(key, detailData[key]);
        }
        axios({
            method: "post",
            url: url + `/detail/${entryID}`,
            data: form_data,
            headers: {
                Authorization: "Bearer " + token,
            },
        }).then((res) => {
            ArtikelDivisionPaste(detailData.entryID, res.data.response.entryID);
        });
    }

    async function ArtikelDivisionPaste(DetailEntryID, newDetailEntryID) {
        const token = localStorage.getItem("token");
        const modelRequest = await makeApiRequest(`/models/${DetailEntryID}`);

        if (modelRequest.data && modelRequest.data.length > 0) {
            modelRequest.data.map((model) => {
                axios
                    .get(url + `/items/${model.entryID}`, {
                        headers: {
                            Authorization: "Bearer " + token,
                        },
                    })
                    .then((details) => {
                        let modelData = {
                            modelName: model.modelName
                                ? model.modelName
                                : "Alle Fahrzeuge",
                            modelID: model.modelID,
                            detailID: newDetailEntryID,
                        };
                        var model_data = new FormData();
                        for (var key in modelData) {
                            model_data.append(key, modelData[key]);
                        }
                        axios
                            .post(
                                url + `/model/${newDetailEntryID}`,
                                model_data,
                                {
                                    headers: {
                                        Authorization: "Bearer " + token,
                                    },
                                }
                            )
                            .then((newModal) => {
                                let articleList = details.data.data;
                                let items = {
                                    items: JSON.stringify(articleList),
                                };
                                let article_data = new FormData();
                                for (var key in items) {
                                    article_data.append(key, items[key]);
                                }
                                axios.post(
                                    url +
                                        `/item/${newModal.data.response.entryID}`,
                                    article_data,
                                    {
                                        headers: {
                                            Authorization: "Bearer " + token,
                                        },
                                    }
                                );
                            });
                    });
            });
        }
    }

    return (
        <div className='modal-wrapper'>
            <div className='modal-window'>
                <img
                    className='close'
                    onClick={() => {
                        setCopyModalVisible(false);
                    }}
                    src={Close}></img>
                <div
                    className='aktion-formular-content'
                    style={{
                        display: "flex",
                        flexDirection: "column",
                    }}>
                    <h1>Kampagnen kopieren</h1>
                    <div
                        style={{
                            marginTop: 24,
                            display: "grid",
                            gridTemplateColumns: "1fr 1.5fr",
                        }}>
                        <div
                            style={{
                                paddingLeft: 24,
                                fontSize: 20,
                                fontWeight: "bold",
                            }}>
                            Ort
                        </div>
                        <div>
                            <Select
                                className='kanpagnen-header-select'
                                options={planData}
                                placeholder={"Auswählen"}
                                styles={customStyles1}
                                value={selectedPlan}
                                onChange={(val) => {
                                    setSelectPlan(val);
                                }}
                            />
                            <Select
                                className='kanpagnen-header-select'
                                options={getDivisionOptions()}
                                placeholder={"Auswählen"}
                                styles={customStyles2}
                                onChange={(val) => {
                                    setSelectDivision(val);
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: 32,
                    }}>
                    {selectDivision ? (
                        <div
                            className='toyota-button toyota-button-red'
                            onClick={KampagnenPaste}>
                            {pasteLoading ? (
                                <div
                                    style={{
                                        marginRight: "12px",
                                        height: "20px",
                                    }}>
                                    <Loader height={20} width={20}></Loader>
                                </div>
                            ) : (
                                <img
                                    style={{
                                        marginRight: "12px",
                                        height: "20px",
                                    }}
                                    src={Save}></img>
                            )}
                            Kampagne kopieren
                        </div>
                    ) : (
                        <div className='toyota-button toyota-button-grey'>
                            Kampagne kopieren
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
