import React from "react";
import Close from "../../../../../assets/close.svg";
import axios from "axios";
import { url } from "../../../../../utils/configs";
import { useLocation } from "react-router-dom";
export default function ArtikelChangedModal({
  setDeleteModalVisible,
  kat,
  name,
  entryID,
  setData,
  updated,
  setUpdated,
  refreshfunction,
  currentPlan,
  item,
}) {
  const location = useLocation();
  const changeNumber = () => {
    let data = item;
    data.changed = null;
    var form_data = new FormData();
    for (var key in data) {
      form_data.append(key, data[key]);
    }
    const token = localStorage.getItem("token");
    axios
      .put(url + "/item/" + entryID, form_data, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        setDeleteModalVisible(false);

        if (setUpdated) {
          setUpdated(!updated);
        }
        if (refreshfunction) {
          refreshfunction();
        }

        if (currentPlan) {
          axios
            .get(url + "/msplan/" + currentPlan.value, {
              headers: {
                Authorization: "Bearer " + token,
              },
            })
            .then((response) => {
              if (setData) {
                setData(response.data?.data[0]?.child);
              }
            });
        }
      });
  };
  return (
    <div className="modal-wrapper">
      <div className="modal-window">
        <img
          className="close"
          src={Close}
          onClick={() => setDeleteModalVisible(false)}
        ></img>
        <h2>{name}</h2>
        <table>
          <thead>
            <tr>
              <th>Änderungen</th>
              <th>Alt</th>
              <th>Neu</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>R A Code</td>
              <td>{item.regRAC}</td>
              <td>{item.r_a_code}</td>
            </tr>
            <tr>
              <td>Teilnummer</td>
              <td>{item.number}</td>
              <td>{item.substituteNumber}</td>
            </tr>
            <tr>
              <td>NDC</td>
              <td>{item.regNDC}</td>
              <td>{item.ndc}</td>
            </tr>
            <tr>
              <td>CIF</td>
              <td>{item.regCIF}</td>
              <td>{item.tdg_ek}</td>
            </tr>
          </tbody>
        </table>
        <div style={{ marginTop: 16 }}>
          Bitte beachten Sie, dass die dargestellten Änderungen manuell in den
          entsprechenden Toyota-Systemen zu ändern sind.
        </div>
        <div
          style={{
            display: "flex",
            marginTop: 32,
            justifyContent: "flex-end",
          }}
        >
          <div
            className="toyota-button toyota-button-grey"
            onClick={() => setDeleteModalVisible(false)}
            style={{ marginRight: 16, width: 120 }}
          >
            Abbrechen
          </div>
          <div
            onClick={changeNumber}
            className="toyota-button toyota-button-red"
            style={{ width: 120 }}
          >
            Bestätigen
          </div>
        </div>
      </div>
    </div>
  );
}
