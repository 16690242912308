import React, { useState, useEffect } from "react";
import { useData } from "../../../../../utils/dataProvider";
import PreviewItem from "./PreviewItem";
import Loader from "../../../../commonComponents/Loading";
import { useArticleStore } from "../Articlestore";
export default function Preview({ modelID, modelName, countref }) {
  const { makeApiRequest } = useData();
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState(null);
  const [childComponentsFinished, setChildComponentsFinished] = useState(0);
  const { models } = useArticleStore();

  const handleAsyncFunctionComplete = () => {
    setChildComponentsFinished((prevCount) => prevCount + 1);
  };

  useEffect(() => {
    let idx = models.findIndex((ele) => ele.entryID == modelID);
    if (idx >= 0) {
      setItems(models[idx].articles);
    }
  }, []);
  useEffect(() => {
    if (items !== null && childComponentsFinished === items?.length) {
      setLoading(false);
    }
  }, [childComponentsFinished]);
  return (
    <div className="preview">
      <style
        dangerouslySetInnerHTML={{
          __html: ".fusion-article-upload{max-width:1800px;min-width:1600px}",
        }}
      ></style>
      <div className="model-head">{modelName}</div>
      <div className="model-content">
        <table cellSpacing="0   ">
          <thead>
            <tr>
              <th>Produktbild</th>
              <th>Produktname</th>
              <th>Bestellnummer</th>
              <th>UVP inkl. MwSt. </th>
              <th>WAP</th>
              <th>Sonder WAP </th>
              <th>Preisvorteil</th>
              <th>Beschreibung</th>
              <th>Inhalt</th>
              <th>Installation/Einbau</th>
              <th>Modelle</th>
              <th>TOS</th>
              <th></th>
            </tr>
          </thead>
          {loading && (
            <div style={{ padding: "1rem" }}>
              <Loader height="20px" width="20px" />
            </div>
          )}

          {items &&
            items.map((item, idx) => {
              return (
                <PreviewItem
                  countref={countref}
                  key={idx}
                  data={item}
                  setLoading={setLoading}
                  handleAsyncFunctionComplete={handleAsyncFunctionComplete}
                />
              );
            })}
        </table>
      </div>
    </div>
  );
}
