import React, { useState } from "react";
import "./style.scss";
import ModelModal from "../ArticleUpload/ModelModal/ModelModal";
import { useData } from "../../../../utils/dataProvider";
import { useParams } from "react-router-dom";
import Articles from "./Articles";
import { useEffect } from "react";
import Loader from "../../../commonComponents/Loading";
import ReactTooltip from "react-tooltip";

export default function ArticleUpload({ detail }) {
  const [showSetModal, setShowSetModal] = useState(false);
  const { makeApiRequest } = useData();
  const [models, setModels] = useState(null);
  const { id } = useParams();
  const [modelChanged, setModeChanged] = useState(false);
  const [studenSatz, setStundenSatz] = useState(null);
  async function getDetailModels() {
    const modelRequest = await makeApiRequest(`/models/${id}`);
    setModels(modelRequest?.data);
  }

  async function getStundenSatz() {
    const std_satz = await makeApiRequest(
      `/organization/6E69-7A61-7469-6F6E-0098-9681`
    );
    setStundenSatz(std_satz?.data[0].imputedHourlyRate);
  }

  useEffect(() => {
    getDetailModels();
    getStundenSatz();
  }, []);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [models]);

  if (!detail) {
    return;
  }

  return (
    <div className="fusion-article-upload">
      <ReactTooltip effect="solid" scrollHide={true} />
      <div className="action-header" style={{ padding: "1rem" }}>
        <h2>Sets</h2>
        <button
          style={{ margin: "0.5rem" }}
          type="button"
          onClick={() => setShowSetModal(true)}
          className="toyota-button toyota-button-blue"
        >
          Modell hinzufügen
        </button>
      </div>
      {showSetModal && (
        <ModelModal
          getDetailModels={getDetailModels}
          setShowModelModal={setShowSetModal}
        />
      )}
      {models ? (
        models.map((model, idx) => {
          return (
            <Articles
              studenSatz={studenSatz}
              discount={detail.valueDiscount}
              key={model.entryID}
              modelID={model.entryID}
              modelName={model.modelName}
              setModels={setModels}
              getDetailModels={getDetailModels}
            />
          );
        })
      ) : (
        <div style={{ paddingLeft: 16, margin: "12px 0" }}>
          <Loader />
        </div>
      )}
    </div>
  );
}
