import React, {
  useState,
  useContext,
  useImperativeHandle,
  forwardRef,
  Fragment,
  useEffect,
} from "react";

import { Plandata } from "./index";
import Event from "./event";
import { Link } from "react-router-dom";

export const SubPlandata = React.createContext();

function SubPlan(props, cref) {
  const { item, select, startDatum, endDatum, dayArray, days } =
    useContext(Plandata);
  const [visible, setVisible] = useState(deviceRedirect() ? false : true);
  const [width, setWidth] = useState();

  useImperativeHandle(cref, () => ({
    changeVisible: () => setVisible(!visible),
    itemVisible: () => {
      return visible;
    },
  }));

  function deviceRedirect() {
    if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    if (document.getElementsByClassName("month")[0]) {
      window.addEventListener("resize", handelMonthWidth);
    }
  }, [width]);

  useEffect(() => {
    handelMonthWidth();
  }, []);

  const handelMonthWidth = () => {
    let width;
    if (document.getElementsByClassName("month")[0]) {
      const months = document.getElementsByClassName("month")[0];
      width = months.clientWidth;
      setWidth(width);
    }
  };

  return (
    <div className={`plan-anime ${visible ? "open" : ""}`}>
      {item.child &&
        item.child
          .sort((a, b) => parseInt(a.order) - parseInt(b.order))
          .map((events, index) => {
            const exports = {
              events,
              select,
              startDatum,
              endDatum,
              dayArray,
              days,
            };

            return (
              <div className="plan-content" key={`plan-content-${index}`}>
                <div className="plan-sub-title">
                  <Link to={"/kampagne/" + events.entryID}>
                    {events.campaignName}
                  </Link>
                </div>

                <div
                  className="plan-content-sub"
                  style={{
                    position: "relative",
                    gridTemplateColumns: `repeat(${days},1fr)`,
                  }}
                >
                  <div
                    className="week-grid month"
                    style={{
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      display: "grid",
                      top: 0,
                      background: "white",
                    }}
                  >
                    <div
                      className="plan-layer hidden-mobile"
                      style={{
                        opacity: 0.2,
                        gridColumn: `${Math.round(days / 2 + 35)} / ${
                          days + 7
                        }`,
                      }}
                    ></div>

                    {dayArray &&
                      dayArray.map((day, idx) => {
                        const remain = (idx + 1) % 7;
                        if (remain === 0 || idx === 0) {
                          return (
                            <div
                              className="weekline"
                              style={{
                                borderLeft: "1px solid #CCC",
                                height: "100%",
                                gridColumnStart: idx === 0 ? 1 : idx + 2,
                                gridColumnEnd: idx === 0 ? 8 : idx + 9,
                              }}
                              key={`weekline-${idx}`}
                            ></div>
                          );
                        } else {
                          return null;
                        }
                      })}
                  </div>

                  <SubPlandata.Provider value={exports}>
                    <Event />
                  </SubPlandata.Provider>
                </div>
              </div>
            );
          })}
    </div>
  );
}

const SubPlan_ref = forwardRef(SubPlan);

export default SubPlan_ref;
