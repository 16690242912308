import {
  ActionForm,
  DatePicker,
  DateRangePicker,
  FieldGroup,
  Input,
  MutiSelect,
  Select,
  TextArea,
  UrlID,
  ArticleUpload,
  SetUpload,
  Checkbox,
  Upload,
  DownloadsList,
  Editor,
} from "./fields";
import { requiredFields } from "./data/requiredFIeld";
import { useLocation } from "react-router-dom";
import React from "react";

export default function FieldRenderer({
  detail,
  fields,
  id,
  values,
  noValidate,
}) {
  const location = useLocation();
  const checkRequired = (name) => {
    if (noValidate) {
      return false;
    }
    if (location.pathname.indexOf("detail")) {
      return requiredFields.detail.indexOf(name) > -1;
    } else if (location.pathname.indexOf("kampagne")) {
      return requiredFields.campaign.indexOf(name) > -1;
    } else {
      return false;
    }
  };

  return (
    fields &&
    fields.map((field, index) => {
      let required = checkRequired(field.name);
      if (field.type == "checkbox") {
        values[field.name] = values[field.name];
      }
      if (values[field.name] == "null") {
        values[field.name] = null;
      }
      switch (field.type) {
        case "text":
          return (
            <Input
              key={`field-${index}`}
              value={values[field.name] ? values[field.name] : ""}
              label={field.label}
              name={field.name}
              required={required}
            ></Input>
          );
          break;
        case "input":
          return (
            <Input
              key={`field-${index}`}
              value={values[field.name] ? values[field.name] : ""}
              label={field.label}
              name={field.name}
              required={required}
            ></Input>
          );
          break;
        case "number":
          return (
            <Input
              key={`field-${index}`}
              value={values[field.name] ? values[field.name] : ""}
              required={required}
              label={field.label}
              name={field.name}
              type={"number"}
            ></Input>
          );
          break;
        case "checkbox":
          return (
            <Checkbox
              key={`field-${index}`}
              value={values?.[field.name]}
              required={required}
              label={field.label}
              name={field.name}
              type={"checkbox"}
            ></Checkbox>
          );
          break;
        case "urlid":
          return (
            <UrlID
              key={`field-${index}`}
              required={required}
              label={field.label}
              name={field.name}
            ></UrlID>
          );
          break;
        case "select":
          return (
            <Select
              key={`field-${index}`}
              value={values?.[field.name]}
              required={required}
              label={field.label}
              name={field.name}
              options={field.options}
            ></Select>
          );
          break;
        case "daterange":
          return (
            <DateRangePicker
              key={`field-${index}`}
              value={values?.[field.name]}
              required={required}
              label={field.label}
              name={field.name}
            ></DateRangePicker>
          );
          break;
        case "datetime":
          return (
            <DatePicker
              key={`field-${index}`}
              value={values?.[field.name]}
              values={values}
              required={required}
              label={field.label}
              name={field.name}
            ></DatePicker>
          );
          break;
        case "textarea":
          return (
            <TextArea
              key={`field-${index}`}
              value={values?.[field.name]}
              required={required}
              id={field.name}
              label={field.label}
              name={field.name}
            ></TextArea>
          );
          break;
        case "fieldgroup":
          return (
            <FieldGroup
              key={`field-${index}`}
              data={field}
              values={values}
              noValidate={noValidate}
            />
          );
          break;
        case "multiselect":
          return (
            <MutiSelect
              key={`field-${index}`}
              source={field.dataSource}
              value={values?.[field.name]}
              required={required}
              label={field.label}
              options={field.options}
              name={field.name}
            ></MutiSelect>
          );
          break;
        case "actionForm":
          return (
            <ActionForm
              key={`field-${index}`}
              data={field.data}
              id={id}
            ></ActionForm>
          );
        case "articleUpload":
          return (
            <ArticleUpload
              key={`field-${index}`}
              data={field.data}
              detail={detail}
            ></ArticleUpload>
          );
        case "setUpload":
          return (
            <SetUpload
              detail={detail}
              key={`field-${index}`}
              data={field.data}
            ></SetUpload>
          );
        case "upload":
          return (
            <Upload
              key={`field-${index}`}
              value={values[field.name]}
              label={field.label}
              name={field.name}
              required={required}
            ></Upload>
          );
          break;
        case "downloadlist":
          return (
            <DownloadsList
              key={`field-${index}`}
              value={values[field.name]}
              label={field.label}
              name={field.name}
              location={field.location}
              required={required}
            ></DownloadsList>
          );
          break;
        case "editor":
          return (
            <Editor
              key={`field-${index}`}
              value={values?.[field.name]}
              required={required}
              id={field.name}
              label={field.label}
              name={field.name}
            ></Editor>
          );
          break;
        default:
          return null;
      }
    })
  );
}
