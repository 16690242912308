import "./index.scss";
import React, { useEffect, useState } from "react";
import Subplan from "./subplan";
import Loader from "../../commonComponents/Loading";
import { useData } from "../../../utils/dataProvider";
import { useParams } from "react-router-dom";
import "./array.js";
import DateModal from "./datemodal";

function Vertriebsplan() {
  const { getModifiedTime, makeApiRequest } = useData();
  const [data, setData] = useState([]);
  const [select, setSelect] = useState("");
  const [time, setTime] = useState(new Date());
  const [loading, setLoading] = useState(true);
  const [startDatum, setStartDatum] = useState("");
  const [endDatum, setEndDatum] = useState("");
  const [days, setDays] = useState([]);
  const [dayArray, setDayArray] = useState([]);
  const [monthArray, setArray] = useState();
  const [calendarWeek, setCalendarWeek] = useState([]);
  const [ie, setIe] = useState(false);
  const [titleAbsolute, setTitleAbsolute] = useState(false);
  const [title, setTitle] = useState("");

  const [modal, modalHandel] = useState();
  const { id } = useParams();
  useEffect(() => {
    setLoading(true);
    async function fetchData() {
      let apiData = await makeApiRequest("/msplan/" + id);
      apiData = apiData.data[0];
      setData(apiData.child);
      setStartDatum(Math.floor(new Date(apiData.mspFrom).getTime() / 1000));
      setEndDatum(Math.floor(new Date(apiData.mspTo).getTime() / 1000));
      setTime(apiData.mspFrom);
      setLoading(false);
      setTitle(apiData.msplanName);
    }
    fetchData();
  }, []);

  useEffect(() => {
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE ");
    var trident = ua.indexOf("Trident/");
    if (msie > 0 || trident > 0) {
      setIe(true);
    }
  }, []);

  function deviceRedirect() {
    if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    const wholeCalendarWeekName = [
      { name: "Jan", days: 31 },
      { name: "Feb", days: 28 },
      { name: "Mär", days: 31 },
      { name: "Apr", days: 30 },
      { name: "Mai", days: 31 },
      { name: "Jun", days: 30 },
      { name: "Jul", days: 31 },
      { name: "Aug", days: 31 },
      { name: "Sep", days: 30 },
      { name: "Okt", days: 31 },
      { name: "Nov", days: 30 },
      { name: "Dez", days: 31 },
    ];
    setDays(getOffset(startDatum, endDatum));
    let empty = [];
    for (let i = 0; i <= getOffset(startDatum, endDatum); i++) {
      empty.push({
        offset: i,
        date: new Date(startDatum * 1000 + i * 24 * 60 * 60 * 1000),
      });
    }
    setDayArray(empty);
    setArray(getMonthArray(empty, wholeCalendarWeekName));
    setCalendarWeek(getCalendarWeek(empty));
    setSelect(
      Array(getMonthArray(empty, wholeCalendarWeekName).length).fill(false)
    );
  }, [startDatum, endDatum]);

  function selectMonth(month) {
    let monthList = select;
    monthList[month] = !monthList[month];
    setSelect([...monthList]);
  }

  function getOffset(date0, date1) {
    const diffTime = Math.abs(date0 * 1000 - date1 * 1000);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  }

  const getMonthArray = (totaldays, monthName) => {
    if (totaldays[0]) {
      var month = [monthName[totaldays[0].date.getMonth()]];
      for (let i = 0; i < totaldays.length; i++) {
        if (totaldays[i + 1]) {
          let currentMonth = totaldays[i + 1].date.getMonth();
          let prevMonth = totaldays[i].date.getMonth();
          if (currentMonth !== prevMonth) {
            month.push(monthName[currentMonth]);
          }
        }
      }
    }

    return month;
  };
  const setMonthLength = (MonthArray) => {
    let gridStyle = "";
    for (let i = 0; i < MonthArray.length; i++) {
      gridStyle = gridStyle + ` ${MonthArray[i].days}fr`;
    }
    return gridStyle;
  };

  function MonthFixed() {
    if (window.pageYOffset > 220) {
      setTitleAbsolute(window.pageYOffset);
    } else {
      setTitleAbsolute(false);
    }
  }

  function addNull(num) {
    return num >= 10 ? num : "0" + num;
  }

  function getCalendarWeek(days) {
    let calendarWeek = [];
    let currentWeek = 0;
    days.map((day, index) => {
      let week = day.date.getWeek();
      if (week != currentWeek) {
        calendarWeek.push(week);
        currentWeek = week;
      }
    });
    return calendarWeek;
  }

  const print = () => {
    window.print();
  };

  if (loading) {
    return (
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Loader />
      </div>
    );
  } else {
    return (
      <div className="plan" id="marketing-plan">
        <div
          className="start-title"
          style={{ width: "1800px", margin: "1.5rem auto" }}
        >
          <h1>{title}</h1>

          <div className="start-time">
            <br />
          </div>
        </div>

        <div className="table">
          <div
            className="plan-content plan-big-title"
            style={
              titleAbsolute
                ? {
                    position: "absolute",
                    top: `${titleAbsolute - 263}px`,
                    left: "0",
                  }
                : {}
            }
          >
            <div className="plan-sub-title">Titel</div>
            <div
              className="month-title"
              style={{
                gridTemplateColumns: `${setMonthLength(monthArray)}`,
              }}
            >
              {monthArray && !ie ? (
                monthArray.map((month, index) => {
                  return (
                    <div
                      className={`month-list ${
                        select[index] ? "selected" : ""
                      }`}
                      onClick={() => selectMonth(index)}
                      key={`month-list-${index}`}
                    >
                      {month.name}
                    </div>
                  );
                })
              ) : (
                <div>Kampagnen</div>
              )}
            </div>
          </div>
          <div
            className="calendar-week-container"
            style={
              titleAbsolute
                ? {
                    position: "absolute",
                    top: `${titleAbsolute - 203}px`,
                    left: "0",
                  }
                : {}
            }
          >
            <div className="calendar-title">Kalenderwoche</div>
            <div className="calendar-week-list">
              {dayArray &&
                dayArray.map((day, idx) => {
                  const remain = (idx + 1) % 7;
                  if (remain === 0 || idx === 0) {
                    let week;
                    if (idx === 0) {
                      week = 1;
                    } else {
                      week = (idx + 8) / 7;
                    }
                    return (
                      <div
                        className="week-line"
                        style={{
                          borderLeft: "1px solid #CCC",
                          height: "100%",
                          gridColumnStart: idx === 0 ? 1 : idx + 2,
                          gridColumnEnd: idx === 0 ? 8 : idx + 9,
                        }}
                        key={`weekline-${idx}`}
                        onMouseOver={() => {
                          new Date().getWeek() === calendarWeek[week - 1] &&
                            week < 48 &&
                            modalHandel(true);
                        }}
                        onMouseLeave={() => modalHandel(false)}
                      >
                        {modal ? <DateModal /> : null}
                        <div
                          className={`week-point-square ${
                            new Date().getWeek() === calendarWeek[week - 1]
                              ? "active"
                              : ""
                          } `}
                        ></div>
                        <div
                          className={`week-point-arrow ${
                            new Date().getWeek() === calendarWeek[week - 1]
                              ? "active"
                              : ""
                          } `}
                        ></div>
                        <div className="week-number">
                          {addNull(calendarWeek[week - 1])}
                        </div>
                      </div>
                    );
                  } else {
                    return null;
                  }
                })}
            </div>
          </div>
          <div className="plan-main-content">
            {data &&
              data
                .sort((a, b) => parseInt(a.order) - parseInt(b.order))
                .map((item, index) => {
                  const childRef = React.createRef();
                  const updateChildState = () => {
                    childRef.current.changeVisible();
                  };
                  const itemVisible = () => {
                    return childRef.current.itemVisible;
                  };

                  return (
                    <Plandata.Provider
                      value={{
                        item,
                        select,
                        startDatum,
                        endDatum,
                        dayArray,
                        days,
                      }}
                      key={`plan-title-${index}`}
                    >
                      <div
                        className={"plan-title " + `${() => itemVisible()}`}
                        onClick={updateChildState}
                      >
                        <span>{item.divisionName}</span>
                      </div>

                      <Subplan ref={childRef} />
                    </Plandata.Provider>
                  );
                })}
          </div>
        </div>
      </div>
    );
  }
}
export const Plandata = React.createContext();
export default Vertriebsplan;
