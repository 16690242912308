import React, { useState } from "react";
import { useData } from "../../../../utils/dataProvider";
import { useEffect } from "react";

import SetModal from "./Modal";
import mock from "./mockSets";
import Delete from "../../../../assets/delete.svg";
import Edit from "../../../../assets/tools.svg";
import LoscheModal from "../../../commonComponents/LoscheModal";

export default function ArticleSetRow({
  item,
  modelName,
  modelID,
  getItems,
  discount,
  data,
  setData,
  update,
  setUpdate,
  studenSatz,
}) {
  function doMoney(float) {
    if (!float) {
      return 0;
    }
    return parseFloat(float).toLocaleString("de-DE", {
      style: "currency",
      currency: "EUR",
    });
  }
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <tr>
        <td>{item.name}</td>
        <td>{item.number}</td>
        <td>{doMoney(item.itemsetSalePrice)}</td>

        <td>
          <button type="button">
            <img
              src={Delete}
              alt="delete"
              onClick={() => setDeleteModalVisible(true)}
            />
          </button>
          <button
            type="button"
            onClick={() => {
              setShowModal(true);
            }}
            style={{
              marginLeft: "0.5rem",
            }}
          >
            <img src={Edit} alt="Edit" />
          </button>
        </td>
      </tr>
      {showModal && (
        <SetModal
          item={item}
          modelID={modelID}
          getArticles={getItems}
          discount={discount}
          setShowArticleModal={setShowModal}
          data={data}
          setSetData={setData}
          studenSatz={studenSatz}
          update={update}
          setUpdate={setUpdate}
          modelName={modelName}
        />
      )}
      {deleteModalVisible && (
        <LoscheModal
          setDeleteModalVisible={setDeleteModalVisible}
          kat="Set Item"
          name={item.name ? item.name : item.originalName}
          entryID={item.entryID}
          updated={update}
          setUpdated={setUpdate}
        />
      )}
    </>
  );
}
