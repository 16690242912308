import React, { useState, useEffect } from "react";
import "./style.scss";
import { useData } from "../../../../utils/dataProvider";
import { useParams } from "react-router-dom";
import Loader from "../../../commonComponents/Loading";
import ReactTooltip from "react-tooltip";
import Modal from "./hochladenModal";
import Item from "./Item";
import axios from "axios";

export default function DownloadsList({ location }) {
    const [modalVisible, setModalVisible] = useState(false);
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [update, setUpdate] = useState(false);

    const { id } = useParams();
    const { makeApiRequest } = useData();

    async function getItems() {
        setLoading(true);
        const results = await makeApiRequest(`/${location}/files/${id}`);
        setData(results.files);
    }

    useEffect(() => {
        getItems();
    }, [update]);

    return (
        <div>
            <div className='list'>
                {data &&
                    data.map((item, index) => (
                        <Item
                            key={`downloadlistitem-${index}`}
                            data={item}
                            id={id}
                            update={update}
                            location={location}
                            setUpdate={setUpdate}></Item>
                    ))}
            </div>
            <div
                style={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                }}>
                <div
                    onClick={() => setModalVisible(true)}
                    className='toyota-button toyota-button-blue'>
                    Datei hochladen
                </div>
            </div>
            {modalVisible && (
                <Modal
                    setModalVisible={setModalVisible}
                    id={id}
                    update={update}
                    location={location}
                    setUpdate={setUpdate}></Modal>
            )}
        </div>
    );
}
