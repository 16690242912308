import React, { useEffect, useState } from "react";
import "./index.scss";
import { useData } from "../../../utils/dataProvider";
import Sidebar from "./sidebar";
import Loader from "../../commonComponents/Loading";
import { useParams } from "react-router-dom";
import DOMPurify from "dompurify";

export default function Guideline() {
  const [title, setTitle] = useState("Einleitung");
  const [data, setData] = useState(null);
  const [post, setPost] = useState(null);
  const { makeApiRequest } = useData();
  const { id } = useParams();
  useEffect(() => {
    Init();
  }, []);

  async function Init() {
    let response = await makeApiRequest(
      "/api/toolbox/v1/fusionguides",
      "https://backend.toyota-toolbox.de"
    );
    setData(response);
    let Dpost = null;
    if (id) {
      Object.values(response)?.forEach((item) => {
        let idx = item?.posts?.findIndex((ele) => ele.id == id);
        if (idx >= 0) {
          Dpost = item.posts[idx];
          setPost(item.posts[idx]);
        }
      });
      if (!Dpost) {
        setPost(Object.values(response)[0]?.posts[0]);
      }
    } else {
      setPost(Object.values(response)[0]?.posts[0]);
    }
  }
  if (post) {
    return (
      <div id="guideline">
        <div className="subtitle">Guides</div>
        <div className="title">{title}</div>
        <div className="guide-content">
          <Sidebar data={data} post={post} setPost={setPost} />
          <div style={{ paddingLeft: 32, marginTop: 28 }}>
            <div style={{ fontSize: 24 }}>{post.title}</div>
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(post.content),
              }}
            ></div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 128,
        }}
      >
        <Loader />
      </div>
    );
  }
}
