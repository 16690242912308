const TabelHeadArray = [
  { label: "Bezeichnung", sort: "name", filterType: "string" },
  {
    label: "Teilenummer",
    sort: "number",
    filterType: "string",
  },
  { label: "Aktionsrabatt", sort: "discountPercent", filterType: "float" },
  { label: "UPE", sort: "grossSalePrice", filterType: "float" },
  { label: "Staffel", sort: "staffel" },
  { label: "WAP", sort: "retailPrice", filterType: "float" },
  { label: "TDG EK/CIF", sort: "tdg_ek", filterType: "float" },
  { label: "Aktion", sort: "aktion" },
  { label: "Preisvorteil", sort: "preisvorteil" },
  { label: "R A Code", sort: "code", filterType: "string" },
  { label: "TOS", sort: "inTOS", filterType: "bool" },
];

function sortFloat(direction, articles, type) {
  return articles.sort((a, b) => {
    if (direction) return parseFloat(a[type]) - parseFloat(b[type]);
    return parseFloat(b[type]) - parseFloat(a[type]);
  });
}

function sortString(direction, articles, type) {
  return articles.sort((a, b) => {
    if (a[type] < b[type]) {
      return direction ? -1 : 1;
    }
    if (a[type] > b[type]) {
      return direction ? 1 : -1;
    }
    return 0;
  });
}

function sortBool(direction, articles, type) {
  return articles.sort((a, b) => {
    const atype = JSON.parse(a[type]);
    const btype = JSON.parse(b[type]);
    if (direction) return atype > btype;
    return btype < atype;
  });
}

function sortArtikel(artikel, type, big) {
  if (type == "inTos") {
    return sortBool(big, artikel, type);
  }
  if (type == "discountPercent") {
    return sortFloat(big, artikel, type);
  }
  return sortString(big, artikel, type);
}

function filterFunction(article, filterValue, field) {
  const head = TabelHeadArray.find((item) => item.sort == field);
  if (!head) return true;
  switch (head.filterType) {
    case "bool":
      return article[field] === filterValue;
    case "string":
      if (typeof filterValue == "string") {
        return article[field]
          ?.toLowerCase()
          .includes(filterValue?.toLowerCase());
      }
    case "float":
      if (typeof filterValue == "object") {
        return (
          parseFloat(article[field]) >=
            (filterValue?.min || Number.MIN_SAFE_INTEGER) &&
          parseFloat(article[field]) <=
            (filterValue?.max || Number.MAX_SAFE_INTEGER)
        );
      }

    default:
      return true;
  }
}

export { filterFunction, TabelHeadArray, sortArtikel };
