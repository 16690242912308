import React, { useState } from "react";

export default function Partinfo({ data }) {
  const [isOpen, setIsOpen] = useState(false);
  if (!data) return "";
  return (
    <table className="info-table">
      <tr style={{ pointer: "touch" }} onClick={() => setIsOpen(!isOpen)}>
        <td style={{ width: "50px" }}>Modell Name</td>
        <td>
          {data.model_name} {data.prod_line_off}
        </td>
      </tr>
      {isOpen && (
        <>
          <tr>
            <td>Production Line</td>
            <td>{data.prod_line_off}</td>
          </tr>
          <tr>
            <td>Karosserie</td>
            <td>{data.body} </td>
          </tr>
          <tr>
            <td>Motor</td>
            <td>{data.engine} </td>
          </tr>
          <tr>
            <td>Schaltung</td>
            <td>{data.transmission} </td>
          </tr>
          <tr>
            <td>Lenkung</td>
            <td>{data.steering} </td>
          </tr>
          <tr>
            <td>Produktionsgrade</td>
            <td>{data.prod_grade} </td>
          </tr>
          <tr>
            <td>Fabrik</td>
            <td>{data.prod_plant} </td>
          </tr>
          <tr>
            <td>Einbauzeit</td>
            <td>{data.fitting_time} </td>
          </tr>
          <tr>
            <td>Einbau Kommentar</td>
            <td>{data.fitting_comment} </td>
          </tr>
          <tr>
            <td>Einbau Teile Kommentar</td>
            <td>{data.fitting_parts} </td>
          </tr>
          <tr>
            <td>Sonstige Teile</td>
            <td>{data.spare_parts} </td>
          </tr>
        </>
      )}
    </table>
  );
}
