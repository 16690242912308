import React, { useState, useEffect } from "react";
import Close from "../../../../assets/close.svg";
import { useData } from "../../../../utils/dataProvider";
import axios from "axios";
import { url } from "../../../../utils/configs";
import { useLocation } from "react-router-dom";
export default function TeilnummerModal({
    setDeleteModalVisible,
    kat,
    name,
    entryID,
    setData,
    updated,
    setUpdated,
    refreshfunction,
    currentPlan,
    item,
}) {
    const location = useLocation();

    const changeNumber = () => {
        let data = item;
        data.number = data.substituteNumber;
        var form_data = new FormData();
        for (var key in data) {
            form_data.append(key, data[key]);
        }
        const token = localStorage.getItem("token");
        axios
            .put(url + "/item/" + entryID, form_data, {
                headers: {
                    Authorization: "Bearer " + token,
                },
            })
            .then((res) => {
                setDeleteModalVisible(false);

                if (setUpdated) {
                    setUpdated(!updated);
                }
                if (refreshfunction) {
                    refreshfunction();
                }

                if (currentPlan) {
                    axios
                        .get(url + "/msplan/" + currentPlan.value, {
                            headers: {
                                Authorization: "Bearer " + token,
                            },
                        })
                        .then((response) => {
                            if (setData) {
                                setData(response.data?.data[0]?.child);
                            }
                        });
                }
            });
    };

    return (
        <div className='modal-wrapper'>
            <div className='modal-window'>
                <img
                    className='close'
                    src={Close}
                    onClick={() => setDeleteModalVisible(false)}></img>
                <h2>Teilenummer aktualisieren</h2>
                Möchten Sie die Teilnummer <b>{name}</b> durch die
                Substitutenumber ersetzen?
                <div
                    style={{
                        display: "flex",
                        marginTop: 32,
                        justifyContent: "flex-end",
                    }}>
                    <div
                        className='toyota-button toyota-button-grey'
                        onClick={() => setDeleteModalVisible(false)}
                        style={{ marginRight: 16, width: 120 }}>
                        Abbrechen
                    </div>
                    <div
                        onClick={changeNumber}
                        className='toyota-button toyota-button-red'
                        style={{ width: 120 }}>
                        Ersetzen
                    </div>
                </div>
            </div>
        </div>
    );
}
