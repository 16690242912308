import { create } from "zustand";
import axios from "axios";
import { url } from "utils/configs";

const useArticleStore = create((set, get) => ({
  models: null,
  loading: true,
  articleDetails: null,
  fetchData: async (id) => {
    set({ loading: true });
    let modelRequest = await makeApiRequest(`/models/${id}`);
    let models = modelRequest?.data;
    let data = [];
    models.forEach((element) => {
      data.push({ ...element, articles: null });
    });
    for (let i = 0; i < data.length; i++) {
      let result = await getArticles(data, i);
      data[i].articles = mapArticles(result);
    }
    set({ models: data, loading: false });
  },
  updateArticle: async (modelID) => {
    const articleRequest = await makeApiRequest(`/items/${modelID}`);
    let idx = get().models.findIndex((ele) => ele.entryID == modelID);
    let newModels = [...get().models];
    if (idx >= 0) {
      newModels[idx].articles = mapArticles(articleRequest.data);
      set({ models: [...newModels] });
    }
  },
}));

async function makeApiRequest(endpoint, newUrl = null) {
  const token = localStorage.getItem("token");
  const requestUrl = newUrl || url;
  const response = await axios
    .get(requestUrl + endpoint, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .catch((error) => {
      localStorage.removeItem("token");
    });
  if (response) {
    return response.data;
  } else {
    return {};
  }
}

function mapArticles(articles) {
  const mapped = articles.map((article) => {
    article.name = article.name || article.originalName;
    return article;
  });
  return mapped;
}

async function getArticles(data, i) {
  let articleRequest = await makeApiRequest(`/items/${data[i].entryID}`);
  return articleRequest.data;
}

export { useArticleStore };
