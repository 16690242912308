import React, { useState, useEffect } from "react";
import Kampagne from "./Kampagne";
import { useData } from "../../../utils/dataProvider";
import { url } from "../../../utils/configs";
import axios from "axios";
import ReactTooltip from "react-tooltip";

export default function Division({ data, setData, currentPlan, divisionID }) {
  const [menuOpen, setMenuOpen] = useState(true);
  const { copyKampagnen } = useData();
  const [pasteLoading, setPasteLoading] = useState(false);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [menuOpen]);

  function KampagnenPaste() {
    setPasteLoading(true);
    const token = localStorage.getItem("token");
    var form_data = new FormData();
    for (var key in copyKampagnen) {
      form_data.append(key, copyKampagnen[key]);
    }
    axios({
      method: "post",
      url: url + `/campaign/${data.entryID}`,
      data: form_data,
      headers: {
        Authorization: "Bearer " + token,
      },
    }).then((newKampagnen) => {
      if (copyKampagnen.child && copyKampagnen.child.length > 0) {
        let promises = [];

        copyKampagnen.child.forEach((aktionData) => {
          promises.push(
            AktionPaste(aktionData, newKampagnen.data.response.entryID)
          );
        });
        Promise.all(promises).then(() => {
          if (currentPlan) {
            axios
              .get(url + "/msplan/" + currentPlan.value, {
                headers: {
                  Authorization: "Bearer " + token,
                },
              })
              .then((response) => {
                if (setData) {
                  setData(response.data?.data[0]?.child);
                }
              });
          }
          setPasteLoading(false);
        });
      } else {
        setPasteLoading(false);
      }
    });
  }

  function AktionPaste(aktionData, entryID) {
    const token = localStorage.getItem("token");
    var form_data = new FormData();
    for (var key in aktionData) {
      form_data.append(key, aktionData[key]);
    }
    axios({
      method: "post",
      url: url + `/action/${entryID}`,
      data: form_data,
      headers: {
        Authorization: "Bearer " + token,
      },
    }).then((newAktion) => {
      if (aktionData.child && aktionData.child.length > 0) {
        let promises = [];

        aktionData.child.forEach((detailData) => {
          promises.push(
            DetailPaste(detailData, newAktion.data.response.entryID)
          );
        });
        Promise.all(promises).then(() => {});
      }
    });
  }

  function DetailPaste(detailData, entryID) {
    const token = localStorage.getItem("token");
    var form_data = new FormData();
    for (var key in detailData) {
      form_data.append(key, detailData[key]);
    }
    axios({
      method: "post",
      url: url + `/detail/${entryID}`,
      data: form_data,
      headers: {
        Authorization: "Bearer " + token,
      },
    }).then((res) => {
      setPasteLoading(false);
    });
  }

  return (
    <div className="division-container">
      <ReactTooltip effect="solid" scrollHide={true} />
      <div className="division-header">
        <div>{data.divisionName}</div>
        <div className="icon-bar">
          <div
            data-tip={menuOpen ? "Zuklappen" : "Ausklappen"}
            className={`icon-bar-icon arrow ${menuOpen && "down"} `}
            onClick={() => setMenuOpen(!menuOpen)}
          ></div>
        </div>
      </div>

      {menuOpen && (
        <div className="division-content">
          {data.child &&
            data.child
              ?.sort((a, b) => parseInt(a.order) - parseInt(b.order))
              .map((kampagne, index) => (
                <Kampagne
                  key={kampagne.entryID}
                  data={kampagne}
                  setData={setData}
                  currentPlan={currentPlan}
                  kampagneID={index}
                  divisionID={divisionID}
                ></Kampagne>
              ))}
        </div>
      )}
    </div>
  );
}
