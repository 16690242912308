import React, { useState, useEffect } from "react";

import { Formik, Form } from "formik";

import Save from "../../../../assets/save.svg";
import Close from "../../../../assets/close.svg";
import FieldRenderer from "../../../../components/forms/FieldRenderer";
import { useData } from "../../../../utils/dataProvider";

export default function ModalDetail({
  setDetailModalVisible,
  actionID,
  currentPlan,
  setData,
  updated,
  setUpdated,
}) {
  const fields = [
    {
      name: "detailName",
      label: "Bezeichnung",
      type: "text",
    },

    {
      name: "detailOwner",
      label: "Verantwortlich",
      type: "multiselect",
      dataSource: "fUser::employees:=MitarbeiterID",
      options: [
        {
          label: "Magnus Westhofen",
          value: "123",
        },
        {
          label: "Bastian Birkendahl",
          value: "1234",
        },
      ],
    },
  ];
  const { makeApiPost } = useData();

  function addDetail() {
    setDetailModalVisible(false);
  }
  const submitForm = (values) => {
    values.actionID = actionID;
    makeApiPost(`/detail/${actionID}`, values, currentPlan, setData);
    setDetailModalVisible(false);
    setUpdated(!updated);
  };

  return (
    <Formik initialValues={{}}>
      {({ values, errors }) => (
        <Form>
          <div className="modal-wrapper">
            <div className="modal-window">
              <img
                className="close"
                onClick={() => setDetailModalVisible(false)}
                src={Close}
              ></img>
              <div
                className="aktion-formular-content"
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <h1>Neuer Detail</h1>
                <div></div>
                <div className="fields">
                  <div className="section-block">
                    <div className="section-title">Stammdaten</div>
                    <div className="section-form">
                      <FieldRenderer fields={fields} values={values} />
                    </div>
                  </div>
                </div>
                <div
                  onClick={() => {
                    submitForm(values);
                  }}
                  className="toyota-button toyota-button-red"
                  style={{
                    marginLeft: 16,
                    marginTop: 16,
                    alignSelf: "end",
                  }}
                >
                  <img
                    style={{
                      marginRight: "12px",
                      height: "20px",
                    }}
                    src={Save}
                  ></img>
                  <div>Detail hinzufügen</div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
