import React, { useEffect, useState } from "react";

export default function PreviewSummary({ countref }) {
  const [total, setTotal] = useState({});

  useEffect(() => {
    const list = document.addEventListener("DOMNodeInserted", function () {
      const total = document.getElementsByClassName("preview-item");
      const images = document.getElementsByClassName("nf-images");
      const description = document.getElementsByClassName("nf-description");
      const content = document.getElementsByClassName("nf-content");
      const remark = document.getElementsByClassName("nf-remark");
      const models = document.getElementsByClassName("nf-models");
      setTotal({
        total: total.length,
        images: total.length - images.length,
        description: total.length - description.length,
        content: total.length - content.length,
        remark: total.length - remark.length,
        models: total.length - models.length,
      });
      return document.removeEventListener(list);
    });
  }, []);
  return (
    <div className="summary">
      <div className="summary-block">
        <div className="label">Produktbilder</div>
        <div className="value">
          {total.images}/{total.total}
        </div>
      </div>
      <div className="summary-block">
        <div className="label">Beschreibung</div>
        <div className="value">
          {total.description}/{total.total}
        </div>
      </div>
      <div className="summary-block">
        <div className="label">Inhalt</div>
        <div className="value">
          {total.content}/{total.total}
        </div>
      </div>
      <div className="summary-block">
        <div className="label">Installation/Einbau</div>
        <div className="value">
          {total.remark}/{total.total}
        </div>
      </div>
      <div className="summary-block">
        <div className="label">Modellzuordnung</div>
        <div className="value">
          {total.models}/{total.total}
        </div>
      </div>
    </div>
  );
}
