import React, { useEffect, useState } from "react";
import "./style.scss";

import Picker from "./components/Picker";
import UserActivity from "./components/UserActivity";
import Meldungen from "./components/Meldungen";
import Top from "./components/Top";

import Loader from "./components/Loader";
import DealerList from "./components/DealerList";
import { useDataStore } from "./utils/ZutsandStore";
function App() {
  const [activeTab, setActiveTab] = useState(0);

  const { data, previousData, getData, loading, startDate, endDate } =
    useDataStore();

  useEffect(() => {
    getData();
  }, [startDate, endDate]);

  const TabWrapper = () => {
    switch (activeTab) {
      case 0:
        return (
          <>
            <UserActivity />
            <Top />
            <Meldungen />
          </>
        );
      case 1:
        return (
          <>
            <DealerList />
          </>
        );
    }
  };

  if (!data || !previousData) {
    return <Loader />;
  }

  return (
    <div className="App">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          position: "relative",
        }}
      >
        <h1>Reporting Toyota Toolbox</h1>
        <Picker />
      </div>
      <div className="tab-header">
        <div
          style={{ cursor: "pointer" }}
          className={`tab ${activeTab === 0 && "active"}`}
          onClick={() => setActiveTab(0)}
        >
          Allgemein
        </div>
        <div
          style={{ cursor: "pointer" }}
          className={`tab ${activeTab === 1 && "active"}`}
          onClick={() => setActiveTab(1)}
        >
          Händler
        </div>
      </div>
      <div className="tab-wrapper">
        {!data || !previousData || loading ? <Loader /> : <>{TabWrapper()}</>}
      </div>
    </div>
  );
}

export default App;
