import React, { useState } from "react";
import { useDataStore } from "../utils/ZutsandStore";
import Tooltip from "./Tooltip";

export default function Meldungen({ usercount }) {
  const [showDetails1, setShowDetails1] = useState(false);
  const [showDetails2, setShowDetails2] = useState(false);
  const [showDetails3, setShowDetails3] = useState(false);
  const [showDetails4, setShowDetails4] = useState(false);
  const [showDetails5, setShowDetails5] = useState(false);

  const data = useDataStore((state) => state.data);
  return (
    <>
      <div className="kpi-section">
        <h2>Kampagnen</h2>
        <div className="report-table">
          <div className="table-row table-row-header table-row-bereiche">
            <div>ID</div>
            <div>Titel</div>
            <div>
              Aufrufe <Tooltip small text="Anzahl der Aufrufe der Meldung." />
            </div>
            <div>Eindeutig</div>
            <div>Händler</div>
          </div>
          {data.content?.campaigns &&
            data.content.campaigns
              .sort((a, b) => b.views - a.views)
              .map((meldung, idx) => {
                if (idx > 5 && !showDetails1) {
                  return "";
                }
                return (
                  <div className="table-row table-row-bereiche">
                    <div>{meldung.id}</div>
                    <div>{meldung.title}</div>
                    <div>{meldung.views}</div>
                    <div>{meldung.unique}</div>
                    <div>{meldung.dealers}</div>
                  </div>
                );
              })}{" "}
          <div
            onClick={() => setShowDetails1(!showDetails1)}
            className="table-row table-row-bereiche table-row-more"
          >
            <div>{showDetails1 ? "Weniger Anzeigen" : "Alle Anzeigen"}</div>
          </div>
        </div>
      </div>
      <div className="kpi-section">
        <h2>News</h2>
        <div className="report-table">
          <div className="table-row table-row-header table-row-bereiche">
            <div>ID</div>
            <div>Titel</div>
            <div>
              Aufrufe <Tooltip small text="Anzahl der Aufrufe der Meldung." />
            </div>
            <div>Eindeutig</div>
            <div>Händler</div>
          </div>
          {data.content?.news &&
            data.content.news
              .sort((a, b) => b.views - a.views)
              .map((meldung, idx) => {
                if (idx > 5 && !showDetails2) {
                  return "";
                }
                return (
                  <div className="table-row table-row-bereiche">
                    <div>{meldung.id}</div>
                    <div>{meldung.title}</div>
                    <div>{meldung.views}</div>
                    <div>{meldung.unique}</div>
                    <div>{meldung.dealers}</div>
                  </div>
                );
              })}{" "}
          <div
            onClick={() => setShowDetails2(!showDetails2)}
            className="table-row table-row-bereiche table-row-more"
          >
            <div>{showDetails2 ? "Weniger Anzeigen" : "Alle Anzeigen"}</div>
          </div>
        </div>
      </div>
      <div className="kpi-section">
        <h2>Sonstiger Content</h2>
        <div className="report-table">
          <div className="table-row table-row-header table-row-bereiche">
            <div>ID</div>
            <div>Titel</div>
            <div>
              Aufrufe <Tooltip small text="Anzahl der Aufrufe der Meldung." />
            </div>
            <div>Eindeutig</div>
            <div>Händler</div>
          </div>
          {data.content?.content &&
            data.content.content
              .sort((a, b) => b.views - a.views)
              .map((meldung, idx) => {
                if (idx > 5 && !showDetails3) {
                  return "";
                }
                return (
                  <div className="table-row table-row-bereiche">
                    <div>{meldung.id}</div>
                    <div>{meldung.title}</div>
                    <div>{meldung.views}</div>
                    <div>{meldung.unique}</div>
                    <div>{meldung.dealers}</div>
                  </div>
                );
              })}{" "}
          <div
            onClick={() => setShowDetails3(!showDetails3)}
            className="table-row table-row-bereiche table-row-more"
          >
            <div>{showDetails3 ? "Weniger Anzeigen" : "Alle Anzeigen"}</div>
          </div>
        </div>
      </div>

      <div className="kpi-section">
        <h2>Newsletter</h2>
        <div className="report-table">
          <div className="table-row table-row-header table-row-newsletters">
            <div>ID</div>
            <div>Titel</div>
            <div>Datum</div>
            <div>Versendet</div>
            <div>Geöffnet</div>
            <div>Geklickt</div>
          </div>
          {data.content?.newsletters?.Data &&
            data.content.newsletters?.Data.sort(
              (a, b) => b.SendTimeStart - a.SendTimeStart
            ).map((meldung, idx) => {
              const newsDate = new Date(
                meldung.SendTimeStart * 1000
              ).toLocaleDateString("de-DE");
              if (idx > 5 && !showDetails5) {
                return "";
              }
              return (
                <div className="table-row table-row-newsletters">
                  <div>{meldung.id}</div>
                  <div>{meldung.Subject}</div>
                  <div>{newsDate}</div>
                  <div>{meldung.DeliveredCount}</div>
                  <div>{meldung.OpenedCount}</div>
                  <div>{meldung.ClickedCount}</div>
                </div>
              );
            })}{" "}
          <div
            onClick={() => setShowDetails5(!showDetails5)}
            className="table-row table-row-bereiche table-row-more"
          >
            <div>{showDetails5 ? "Weniger Anzeigen" : "Alle Anzeigen"}</div>
          </div>
        </div>
      </div>
    </>
  );
}
