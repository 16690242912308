import React from "react";
import { Formik, Field, Form } from "formik";

export default function Input({
    label,
    name,
    type,
    placeholder,
    number,
    required,
    value,
    ...props
}) {
    return (
        <div
            className={`aktion-input ${
                required && !value ? "required-field" : ""
            }`}>
            <label htmlFor={name}>{label}</label>
            <Field
                id={name}
                name={name}
                type={type}
                placeholder={placeholder}
                {...props}
            />
        </div>
    );
}
