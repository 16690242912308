import React, { useEffect, useState } from "react";
import { useData } from "../../../../../utils/dataProvider";
import DOMPurify from "dompurify";

export default function PreviewItem({
  data,
  countref,
  handleAsyncFunctionComplete,
}) {
  const { makeApiRequest, makeApiPostRequest } = useData();
  const [info, setInfo] = useState(null);
  const [expand, setExpand] = useState(false);

  useEffect(() => {
    async function getDetails() {
      let info = await makeApiRequest("/accessories/search/" + data.number);
      let images = await makeApiRequest("/accessories/images/" + data.number);
      let models = await makeApiRequest("/accessories/models/" + data.number);
      const info2 = { images: images };
      const info3 = { models: models };
      setInfo({ ...info, ...info2, ...info3 });
      handleAsyncFunctionComplete();
    }
    getDetails();
  }, [data]);

  function getMoney(val) {
    const value = parseFloat(val).toFixed(2);
    return Number(value)?.toLocaleString("de-DE", {
      style: "currency",
      currency: "EUR",
    });
  }

  function isEnglish(string) {
    if (!string) return false;
    if (
      string.search(" is ") > -1 ||
      string.search(" a ") > -1 ||
      string.search(" the ") > -1
    ) {
      return true;
    }
    return false;
  }

  if (true) {
    return (
      <tr
        className="preview-item"
        ref={countref}
        onClick={() => setExpand(!expand)}
      >
        <td className="image">
          {!info?.images?.length > 0 ? (
            <div className="not-found nf-images">Nicht vorhanden</div>
          ) : (
            <img src={info?.images[0]?.imageUrl} alt="Preview" />
          )}
        </td>
        <td>{data.name || data.originalName}</td>
        <td>{data.number}</td>
        <td>{getMoney(data.grossSalePrice)}</td>
        <td>{getMoney(data.retailPrice)}</td>
        <td>
          {getMoney(
            data.retailPrice - (data.retailPrice * data.discountPercent) / 100
          )}
        </td>
        <td>{getMoney((data.retailPrice * data.discountPercent) / 100)}</td>
        <td style={{ width: "250px" }}>
          {!info?.description && (
            <div className="not-found nf-description">Nicht vorhanden</div>
          )}
          {isEnglish(info?.description) && (
            <div className="not-found description english">Englischer Text</div>
          )}
          <div
            className=""
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(
                info?.description?.substring(0, expand ? 10000 : 100) + "..."
              ),
            }}
          ></div>
        </td>
        <td style={{ width: "250px" }}>
          {!info?.content && (
            <div className="not-found nf-content">Nicht vorhanden</div>
          )}
          {isEnglish(info?.content) && (
            <div className="not-found type-content english">
              Englischer Text
            </div>
          )}
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(
                info?.content?.substring(0, expand ? 10000 : 100) + "..."
              ),
            }}
          ></div>
        </td>{" "}
        <td style={{ width: "250px" }}>
          {isEnglish(info?.remark) && (
            <div className="not-found remark english">Englischer Text</div>
          )}

          {!info?.remark && (
            <div className="not-found nf-remark">Nicht vorhanden</div>
          )}
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(
                info?.remark?.substring(0, expand ? 10000 : 100) + "..."
              ),
            }}
          ></div>
        </td>
        <td style={{ width: "300px", whiteSpace: "nowrap" }}>
          {!info?.models?.length > 0 && (
            <div className="not-found nf-models">Nicht vorhanden</div>
          )}

          <div style={{}}>
            {Array.isArray(info?.models) &&
              info?.models?.map((model, idx) => {
                if (!expand && idx === 4) return "...";

                if (!expand && idx > 3) return "";
                return (
                  <div>
                    {model.model_name} {model.prod_line_off}
                    <br />
                  </div>
                );
              })}
          </div>
        </td>
        <td>
          {info?.partInfo?.inTOS === "true" && (
            <a
              target="_blank"
              className="tos-link"
              href={`https://tos.toyota.de/zubehoer?search=${info.number}`}
            >
              Link
            </a>
          )}
        </td>
        <td>
          <svg
            className={expand ? "expand" : "notexpand"}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="13"
            viewBox="0 0 24 13"
          >
            <g id="surface713" transform="translate(-3 -8.001)">
              <path
                id="Pfad_69"
                data-name="Pfad 69"
                d="M27,18v2a1,1,0,0,1-1.633.773L15,12.293,4.633,20.773A1,1,0,0,1,3,20V18a1,1,0,0,1,.367-.773l11-9a1,1,0,0,1,1.266,0l11,9A1,1,0,0,1,27,18Zm0,0"
                fill="#d6d6d6"
              />
            </g>
          </svg>
        </td>
      </tr>
    );
  }
}
