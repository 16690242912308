import React, { useState, useEffect } from "react";

import { Formik, Form } from "formik";
import { useParams, useLocation } from "react-router-dom";
import { useData } from "../../../../utils/dataProvider";
import Save from "../../../../assets/save.svg";
import Close from "../../../../assets/close.svg";
import FieldRenderer from "../../../../components/forms/FieldRenderer";
import axios from "axios";

export default function ModalAktion({
  setZeitraumFormularVisible,
  campaignID,
  currentPlan,
  setData,
  setUpdated,
  updated,
  setMenuOpen,
}) {
  const fields = [
    {
      name: "actionName",
      label: "Bezeichnung",
      type: "text",
    },
    {
      name: "actFrom",
      label: "Startdatum",
      type: "datetime",
    },
    {
      name: "actTo",
      label: "Endatum",
      type: "datetime",
    },
  ];
  const { makeApiPost } = useData();
  function addAktion() {
    setZeitraumFormularVisible(false);
  }

  const submitForm = (values) => {
    values.campaignID = campaignID;
    makeApiPost(`/action/${values.campaignID}`, values, currentPlan, setData);
    if (setUpdated) {
      setUpdated(!updated);
    }

    setZeitraumFormularVisible(false);
  };

  return (
    <Formik initialValues={{}}>
      {({ values, errors }) => (
        <Form>
          <div className="modal-wrapper">
            <div className="modal-window">
              <img
                className="close"
                onClick={() => setZeitraumFormularVisible(false)}
                src={Close}
              ></img>
              <div
                className="aktion-formular-content"
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <h1>Neuer Aktionszeitraum</h1>
                <div></div>
                <div className="fields">
                  <div className="section-block">
                    <div className="section-title">Stammdaten</div>
                    <div className="section-form">
                      <FieldRenderer fields={fields} values={values} />
                    </div>
                  </div>
                </div>
                <div
                  onClick={() => submitForm(values)}
                  className="toyota-button toyota-button-red"
                  style={{
                    marginLeft: 16,
                    marginTop: 16,
                    alignSelf: "end",
                  }}
                >
                  <img
                    style={{
                      marginRight: "12px",
                      height: "20px",
                    }}
                    src={Save}
                  ></img>
                  <div>Aktionszeitraum hinzufügen</div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
