import React, { useEffect, useState } from "react";
import Loader from "./Loader";
import { useDataStore } from "../utils/ZutsandStore";
export default function DealerList() {
  const { dealerData, getDealerData, dealerLoading, startDate, endDate } =
    useDataStore();

  useEffect(() => {
    getDealerData();
  }, [startDate, endDate]);

  if (dealerLoading) return <Loader />;
  return (
    <div>
      <div className="report-table">
        <div className="table-row table-row-header table-row-dealers">
          <div>Nummer</div>
          <div>Name</div>
          <div>Logins</div>
          <div>letzer Login</div>
        </div>
        <div>
          {dealerData &&
            dealerData
              .sort((a, b) => {
                if (!a.last_login) return 1;
                if (!b.last_login) return -1;

                return (
                  new Date(b.last_login).getTime() -
                  new Date(a.last_login).getTime()
                );
              })
              .map((dealer, idx) => {
                let dateString = "";
                if (dealer.last_login) {
                  dateString = new Date(dealer.last_login).toLocaleDateString(
                    "de-DE"
                  );
                }

                return (
                  <div key={dealer.id} className="table-row  table-row-dealers">
                    <div>{dealer.id}</div>
                    <div>{dealer.name}</div>
                    <div>{dealer.logins}</div>
                    <div>{dateString}</div>
                  </div>
                );
              })}
        </div>
      </div>
    </div>
  );
}
