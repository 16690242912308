const requiredFields = {
    campaign: ["campaignName", "cmpFrom", "cmpTo", "cmpOwner", "parent"],
    detail: [
        "detailName",
        "unitCampaignTarget",
        "valueCampaignTarget",
        "unitPreviousTarget",
        "valuePreviousTarget",
        "unitPreviousGoal",
        "valuePreviousGoal",
        "forecastSubmittedDate",
        "forecastReportedBack",
        "forecastNumber",
        "npaCampaignCreationDate",
        "npaCampaignCode",
        "unitDiscount",
        "valueDiscount",
        "tipPoints",
        "tipDescription",
    ],
};
export { requiredFields };
