import React, { useState, useEffect } from "react";
import "./index.scss";
import FieldRenderer from "../../forms/FieldRenderer";

import { Formik, Field, Form } from "formik";
import SaveIcon from "../../../assets/save.svg";
import { useData } from "../../../utils/dataProvider";
import { useParams, Link, useLocation, useNavigate } from "react-router-dom";
import Loader from "../../commonComponents/Loading";
import { url } from "../../../utils/configs";
import axios from "axios";
import { requiredFields } from "../../forms/data/detailRequiredFields";
import HistoryChain from "../../commonComponents/historyChain";
import SaveAlertModal from "../../commonComponents/SaveAlertModal";
import ReactRouterPrompt from "react-router-prompt";
import LeavePageModal from "../../commonComponents/PageLeaveModal";
import Iframe from "../Iframe";
export default function AktionFormular() {
  const { id } = useParams();
  const [currentTabs, setTabs] = useState(null);
  const [actionInitialValue, setActionInitialValue] = useState(null);
  const [singleFormData, setSingleFormData] = useState(null);
  const [detail, setDetail] = useState({});
  const [formData, setFormData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [historyChain, setHistoryChain] = useState(null);
  const [saveModalVisible, setSaveModalVisible] = useState(false);
  const navigate = useNavigate();
  const {
    makeApiRequest,
    detailData,
    FormInitial,
    Refresh,
    setUpdate,
    update,
  } = useData();

  const location = useLocation();

  useEffect(() => {
    if (formData) {
      const tabName = decodeURI(location.hash?.replace("#", ""));
      const findTab = formData.tabs.find((tab) => tab.name == tabName);
      if (!findTab) {
        setSingleFormData(formData.tabs[0].sections);
        setTabs(formData.tabs[0].name);
      } else {
        setTabs(findTab.name);
        setSingleFormData(findTab?.sections);
      }
    }
  }, [location, formData]);

  function navigateTab(index) {
    navigate(`${location.pathname}#${formData.tabs[index].name}`);
  }

  async function fetchCampaign() {
    setLoading(true);
    const apiData = await makeApiRequest("/detail/" + id);
    setDetail(apiData.data);
    let initialvalues = apiData?.data[0];
    for (var key in initialvalues) {
      if (initialvalues[key] == null || initialvalues[key] == "null") {
        initialvalues[key] = "";
      }
    }
    setActionInitialValue(initialvalues ? initialvalues : "null");
    setLoading(false);
  }
  useEffect(() => {
    fetchCampaign();
    setUpdate(!update);
  }, [id]);

  useEffect(() => {
    if (detailData) {
      generateFormData(detailData);
    }
  }, [detailData]);

  function generateFormData(oldData) {
    let formdata = { tabs: [] };
    const data = oldData.map((item) => {
      let newItem = { ...item };
      if (!item.properties.tabName.length) {
        newItem.properties.tabName = "Allgemein";
      }
      if (!item.properties.sectionName) {
        newItem.properties.sectionName = "Allgemein";
      }
      return newItem;
    });
    formdata = FormInitial(formdata, data);
    formdata.tabs.push({
      name: "Artikel",
      sections: [
        {
          name: "Artikel",
          fields: [
            {
              label: "Artikelupload",
              name: "child",
              type: "articleUpload",
            },
          ],
        },
      ],
    });
    formdata.tabs.push({
      name: "Sets",
      sections: [
        {
          name: "Sets",
          fields: [
            {
              label: "Setupload",
              name: "child",
              type: "setUpload",
            },
          ],
        },
      ],
    });

    formdata.tabs.push({
      name: "Downloads",
      sections: [
        {
          name: "Downloads",

          fields: [
            {
              label: "Downloads",
              name: "download",
              type: "downloadlist",
              location: "detail",
              data: [],
            },
          ],
        },
      ],
    });

    setFormData(formdata);
    setTabs(formdata.tabs[0].name);
    setSingleFormData(formdata.tabs[0].sections);
    setLoading(false);
  }

  const submitDetail = (values) => {
    setLoading(true);
    const token = localStorage.getItem("token");
    var form_data = new FormData();
    for (var key in values) {
      form_data.append(key, values[key]);
    }
    axios({
      method: "put",
      url: url + "/detail/" + id,
      data: form_data,
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        fetchCampaign();
        Refresh();
        setSaveModalVisible(false);
      })
      .catch((error) => {
        alert("Fehler");
        setSaveModalVisible(false);
      });
  };

  const checkUnCompleteFieldInSection = (values, tabIndex) => {
    let number = 0;

    formData.tabs[tabIndex].sections.forEach((section) => {
      section.fields.forEach((field) => {
        if (
          requiredFields.indexOf(field.name) > -1 &&
          (!values[field.name] || values[field.name] == 0)
        ) {
          number++;
        }
      });
    });
    return number;
  };
  if (loading || !actionInitialValue || !formData) {
    return (
      <div
        style={{
          height: "100vh",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Loader />
      </div>
    );
  }
  if (actionInitialValue == "null") {
    return <div>Die Detail konnte nicht gefunden werden.</div>;
  }

  if (location?.state?.iframe) {
    return <Iframe url={location.state.iframe} />;
  }
  return (
    <Formik
      initialValues={actionInitialValue}
      onSubmit={(values) => {
        setSaveModalVisible(true);
      }}
    >
      {({ values, dirty }) => (
        <Form>
          <div className="aktion-formular">
            <HistoryChain id={id} />
            <div className="aktion-formular-header">
              <h1>Detail bearbeiten</h1>
              <button
                className="toyota-button toyota-button-red aktion-save"
                type="submit"
              >
                <img src={SaveIcon}></img>
                Detail speichern
              </button>
            </div>
            <div className="aktion-formular-form">
              <div
                className="aktion-formluar-tab-list"
                style={{
                  gridTemplateColumns: `repeat(${formData.tabs.length},1fr)`,
                }}
              >
                {formData.tabs.map((tabs, index) => (
                  <div
                    className="aktion-formular-tab-list-item"
                    onClick={() => navigateTab(index)}
                    key={tabs + index}
                  >
                    <div
                      className="aktion-formular-tab-list-item-text"
                      style={{
                        color:
                          checkUnCompleteFieldInSection(values, index) > 0
                            ? "#ff0022"
                            : "black",
                      }}
                    >
                      {tabs.name}
                      {checkUnCompleteFieldInSection(values, index) > 0 && (
                        <div className="section-uncom-number">
                          {checkUnCompleteFieldInSection(values, index)}
                        </div>
                      )}
                    </div>
                    <div
                      className="underline"
                      style={{
                        opacity:
                          currentTabs === formData.tabs[index].name ? 1 : 0,
                      }}
                    ></div>
                  </div>
                ))}
              </div>

              <div className="aktion-formular-content" id={currentTabs}>
                {formData.tabs.map((tab, idx) => {
                  return (
                    <div
                      key={`detail-tab-${idx}`}
                      style={{
                        display: tab.name == currentTabs ? "block" : "none",
                      }}
                    >
                      {tab.sections.map((section, idx) => (
                        <div key={`section-${idx}`} className="section-block">
                          <div className="section-title">{section.name}</div>
                          <div className="section-form">
                            <FieldRenderer
                              detail={detail[0]}
                              fields={section.fields}
                              values={values}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  );
                })}
              </div>
            </div>
            <ReactRouterPrompt when={dirty}>
              {({ isActive, onConfirm, onCancel }) =>
                isActive && (
                  <LeavePageModal onConfirm={onConfirm} onCancel={onCancel} />
                )
              }
            </ReactRouterPrompt>
            {saveModalVisible && (
              <SaveAlertModal
                kat={"Detail"}
                values={values}
                submit={submitDetail}
                setSaveModalVisible={setSaveModalVisible}
              />
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
}
