import React, { useState } from "react";
import { Field, useField } from "formik";
import { useEffect } from "react";
import Delete from "../../../../assets/delete.svg";
import axios from "axios";
import { url } from "../../../../utils/configs";

export default function SetRow({
  set,
  itemIndex,
  deleteSinglePart,
  StdSatz,
  setItems,
  items,
  EKTotal,
}) {
  const [rabatt, setRabatt] = useState(parseFloat(set.discountPercent));
  const [einbauzeit, setEinbauzeit] = useState(
    set.fitting_time ? set.fitting_time : 0
  );

  console.log(set);
  function doMoney(float) {
    if (!float) {
      return "0";
    }
    return float.toLocaleString("de-DE", {
      style: "currency",
      currency: "EUR",
    });
  }
  function calculateNetto(brutto) {
    return brutto - brutto * 0.19;
  }

  function removeRabattFromValue(rabatt, value) {
    if (typeof value === "number" && rabatt < 100) {
      return value - (value * rabatt) / 100;
    }
    return value;
  }

  useEffect(() => {
    setEinbauzeit(set.fitting_time ? set.fitting_time : 0);
  }, [items]);
  return (
    <tr>
      <td>{set.name ? set.name : set.originalName}</td>
      <td>{set.number}</td>
      <td>{doMoney(set.grossSalePrice)}</td>
      <td>{doMoney(set.grossSalePrice * 0.84)}</td>
      <td>{set["ndc%"]}</td>
      <td>{doMoney(set.retailPrice)}</td>
      <td>
        <input
          name="rabatt"
          value={rabatt}
          onChange={(e) => {
            let newSet = structuredClone(items);
            newSet[itemIndex].discountPercent = parseFloat(e.target.value);
            setItems([...newSet]);
            setRabatt(e.target.value);
          }}
          type="number"
        />
      </td>
      <td>{doMoney(removeRabattFromValue(rabatt, set.retailPrice))}</td>
      <td>
        {doMoney(set.tdg_ek) + " "}({(set.tdg_ek / EKTotal()).toFixed(2) * 100}
        %)
      </td>
      <td>{doMoney(set.tme_ek)}</td>
      <td>{einbauzeit}</td>
      <td>{doMoney(einbauzeit * StdSatz)}</td>
      <td>{doMoney(einbauzeit * StdSatz * 1.19)}</td>
      <td>
        <div
          className="tos-status"
          style={{
            background: set.inTOS == "false" ? "#ff0022" : "#48EC05",
            height: 12,
            width: 12,
            borderRadius: "50%",
          }}
        ></div>
      </td>
      <td>
        <button type="button" onClick={() => deleteSinglePart(itemIndex)}>
          <img src={Delete} alt="Löschen" />
        </button>
      </td>
    </tr>
  );
}
