import React, { useState, useEffect } from "react";
import "./style.scss";
import { TabelHeadArray } from "./TabelHeadArray";
import FilterLogo from "../../../../assets/filter.svg";
import Close from "../../../../assets/close.svg";
import FilterForm from "./FilterForm";
import { useLocation } from "react-router-dom";
export default function Filter({
  filterValue,
  setFilterValue,
  filterKat,
  setFilterKat,
}) {
  const location = useLocation();

  const [showModal, setShowModal] = useState(false);
  const [listVisible, setListVisible] = useState(true);
  function resetFilter() {
    setFilterValue(null);
    setFilterKat(null);
  }

  useEffect(() => {
    if (location.state?.itemNumber) {
      setListVisible(true);
    }
  }, [location]);

  return (
    <div className="filter">
      {showModal ? (
        <>
          <div className="input-container">
            <div
              className="input-container-close"
              onClick={() => {
                setShowModal(false);
              }}
            >
              <img src={Close}></img>
            </div>
            {(listVisible || showModal) && (
              <div className="filter-list">
                {TabelHeadArray.map((item, idx) => {
                  if (item.filterType) {
                    return (
                      <div className="filter-list-item">
                        <div className="filter-label">
                          <input
                            onChange={(e) =>
                              e.target.checked ? setFilterKat(item.sort) : ""
                            }
                            id={`filter-${idx}`}
                            type="radio"
                            checked={item.sort == filterKat ? true : false}
                          />
                          <label htmlFor={`filter-${idx}`}>{item.label}</label>
                        </div>
                        {item.sort == filterKat && (
                          <div className="filter-form">
                            <FilterForm
                              setFilterValue={setFilterValue}
                              filterValue={filterValue}
                              item={item}
                              itemNumber={location.state?.itemNumber}
                            />
                          </div>
                        )}
                      </div>
                    );
                  }
                })}
                <button
                  onClick={() => resetFilter()}
                  type="button"
                  className="reset-filter"
                >
                  Filter zurücksetzen
                </button>
              </div>
            )}
          </div>
        </>
      ) : (
        <button
          type="button"
          onClick={() => setShowModal(true)}
          data-tip={"Filter"}
          className="toyota-button toyota-button-blue"
        >
          <img src={FilterLogo} style={{ marginTop: 4 }} alt="Filter" />
        </button>
      )}
    </div>
  );
}
