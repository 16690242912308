import React from "react";

export default function Priceinfo({ data }) {
  let EURO = new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
  });
  if (!data) return "";
  return (
    <table className="info-table">
      <tr>
        <td>Brutto UPE</td>
        <td>{EURO.format(parseInt(data.grossSalePrice))} </td>
      </tr>
      <tr>
        <td>Netto UPE</td>
        <td>{EURO.format(parseInt(data.salePrice))} </td>
      </tr>
      <tr>
        <td>WAP</td>
        <td>{EURO.format(parseInt(data.retailPrice))} </td>
      </tr>
      <tr>
        <td>NDC</td>
        <td>{data.ndc} </td>
      </tr>
      <tr>
        <td>NDC %</td>
        <td>{(data["ndc%"] * 100).toLocaleString()} %</td>
      </tr>
      <tr>
        <td>TDG Einkaufspreis</td>
        <td>{EURO.format(parseInt(data.tdg_ek))} </td>
      </tr>
      <tr>
        <td>TME Einkaufspreis</td>
        <td>{EURO.format(parseInt(data.tme_ek))} </td>
      </tr>
    </table>
  );
}
