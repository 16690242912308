import React, { useState, useEffect } from "react";
import "./index.scss";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";

export default function KampagnenBox({ data }) {
  const generateKurzName = (name) => {
    if (name.split(" ").length > 1) {
      return name.split(" ")[0][0] + name.split(" ")[1][0];
    } else {
      return name.split(" ")[0][0] + name.split(" ")[0][1];
    }
  };
  function isJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
  function StatusColor(status) {
    switch (status) {
      case "Offen":
        return "#FC6969";
        break;
      case "in Arbeit":
        return "#FFC62B";
        break;
      case "Fertig":
        return "#52BA4A";
        break;
      default:
        return "#FC6969";
        break;
    }
  }

  function DatumFormat(datum) {
    let f = new Date(datum);
    let from = f.getDate() + "." + (f.getMonth() + 1) + "." + f.getFullYear();
    return from;
  }

  function linkText() {
    if (data.actionName) {
      return `/aktion/${data.entryID}`;
    }
    if (data.campaignName) {
      return `/kampagne/${data.entryID}`;
    }
    if (data.detailName) {
      return `/detail/${data.entryID}`;
    }
    return "/404";
  }

  function TypeText() {
    if (data.actionName) {
      return `Aktion`;
    }
    if (data.campaignName) {
      return `Kampagnen`;
    }
    if (data.detailName) {
      return `Detail`;
    }
  }

  return (
    <Link to={linkText()} className="kampagnen-box">
      <div className="kampagnen-box-header">
        <div className="name-bar"></div>
      </div>
      <div className="kampagnen-box-content">
        <div className="kamapagen-box-subtitle">{TypeText()}</div>

        <div className="kamapagen-box-title">{data.actionName}</div>
        <div className="kampagnen-box-footer">
          <div>
            {DatumFormat(
              data.actFrom ? data.actFrom : data.cmpFrom ? data.cmpFrom : "null"
            ) +
              " - " +
              DatumFormat(
                data.actTo ? data.actTo : data.cmpTo ? data.cmpTo : "null"
              )}
          </div>
        </div>
      </div>
      <ReactTooltip effect="solid" scrollHide={true} />
    </Link>
  );
}
