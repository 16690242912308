import React, { useState } from "react";
import { useData } from "../../../../utils/dataProvider";
import { useEffect } from "react";

import Loading from "../../../commonComponents/Loading";
import Tools from "../../../../assets/tools-white.svg";

import SetModal from "./Modal";
import Delete from "../../../../assets/delete_white.svg";
import ArticleSetRow from "./ArticleSetRow";
import LoscheModal from "../../../commonComponents/LoscheModal";
import ChainLoscheModal from "../../../commonComponents/ChainLoscheModal";
import ModelModal from "../ArticleUpload/ModelModal/ModelModal";
export default function Articles({
  modelID,
  modelName,
  discount,
  setModels,
  getDetailModels,
  studenSatz,
}) {
  const [showExcelModal, setShowExcelModal] = useState(false);
  const [showPFCModal, setShowPFCModal] = useState(false);
  const [showArticleModal, setShowArticleModal] = useState(false);
  const [data, setData] = useState([]);
  const [update, setUpdate] = useState(false);
  const [deleteArticleModalVisible, setDeleteArticleModalVisible] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [modelModalVisible, setModelModalVisible] = useState(false);

  const { makeApiRequest } = useData();
  async function getItems() {
    setLoading(true);
    const articleRequest = await makeApiRequest(`/itemsets/${modelID}`);
    const data = [...articleRequest.data];
    setData([...data]);
    setLoading(false);
  }
  useEffect(() => {
    getItems();
  }, [update]);

  function getDiscounted(val, discount) {
    return val - (val * discount) / 100;
  }

  function updateRabatt(index, value) {
    let newData = [...data];
    newData[index].rabatt = value;
    setData(newData);
  }

  function doMoney(float) {
    if (!float) {
      return 0;
    }
    return parseFloat(float).toLocaleString("de-DE", {
      style: "currency",
      currency: "EUR",
    });
  }

  return (
    <div className="model-block">
      <div className="model-row">
        <span>{modelName}</span>
        <div className="action-header">
          <div className="action-holder">
            <button
              type="button"
              data-tip={"Bearbeiten"}
              className="toyota-button toyota-button-blue"
              onClick={() => setModelModalVisible(true)}
            >
              <img src={Tools} alt="tools" />
            </button>
            <button
              type="button"
              onClick={() => setShowArticleModal(true)}
              data-tip={"Item hinzufügen"}
              style={{ fontSize: 18 }}
              className="toyota-button toyota-button-blue"
            >
              +
            </button>
            <button
              type="button"
              data-tip={"Model löschen"}
              onClick={() => setDeleteModalVisible(true)}
              className="toyota-button toyota-button-grey"
            >
              <img src={Delete} alt="Delete" />
            </button>
          </div>
        </div>
      </div>
      {modelModalVisible && (
        <ModelModal
          getDetailModels={getDetailModels}
          setShowModelModal={setModelModalVisible}
          arbeit={true}
          modelID={modelID}
          modelName={modelName}
        />
      )}
      {showArticleModal && (
        <SetModal
          modelID={modelID}
          getItems={getItems}
          discount={discount}
          setShowArticleModal={setShowArticleModal}
          setModels={setModels}
          data={data}
          setSetData={setData}
          modelName={modelName}
          studenSatz={studenSatz}
          update={update}
          setUpdate={setUpdate}
        />
      )}
      {deleteModalVisible && (
        <LoscheModal
          setDeleteModalVisible={setDeleteModalVisible}
          kat="Set Model"
          name={modelName}
          entryID={modelID}
          refreshfunction={getDetailModels}
        />
      )}
      {loading && (
        <div style={{ padding: "1rem" }}>
          <Loading height="20px" width="20px" />
        </div>
      )}
      {data.length ? (
        <>
          <table>
            <thead>
              <tr>
                <th>Bezeichnung</th>
                <th>Set-Nr.:</th>
                <th>Setpreis Brutto</th>
                <th>Aktionen</th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((item, idx) => {
                  const discounted = getDiscounted(item.wap, item.rabatt);
                  return (
                    <ArticleSetRow
                      studenSatz={studenSatz}
                      item={item}
                      key={idx + modelID}
                      modelID={modelID}
                      getItems={getItems}
                      discount={discount}
                      data={data}
                      setData={setData}
                      update={update}
                      setUpdate={setUpdate}
                      modelName={modelName}
                    />
                  );
                })}
            </tbody>
          </table>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <div
              style={{ margin: 12, marginBottom: 12 }}
              onClick={() => setDeleteArticleModalVisible(true)}
              className="toyota-button toyota-button-grey"
            >
              Alle Sets löschen
            </div>
          </div>
          {deleteArticleModalVisible && (
            <ChainLoscheModal
              setDeleteModalVisible={setDeleteArticleModalVisible}
              kat="Alle Sets Items"
              name={modelName}
              items={data}
              update={update}
              setUpdate={setUpdate}
            />
          )}
        </>
      ) : (
        !loading && <div style={{ padding: "1rem" }}>Keine Sets vorhanden.</div>
      )}
    </div>
  );
}
