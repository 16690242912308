import React from "react";
import { Formik, Field, useFormikContext } from "formik";
import RSelect from "react-select";

export default function Select({
    label,
    name,
    options,
    placeholder,
    value,
    required,
    field,
    values,
    ...props
}) {
    const { setFieldValue } = useFormikContext();

    const fieldStyles = {
        control: () => ({
            width: "100%",
            height: "42px",
            display: "flex",
            padding: "0px 3px",
            border:
                required && (!value || value.length == 0)
                    ? "1px solid #ff0022"
                    : "1px solid #ccc",
        }),
        dropdownIndicator: () => ({ color: "black", paddingLeft: "8px" }),
    };

    function isJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
    return (
        <div
            className={`aktion-input ${
                required && !value ? "required-field" : ""
            }`}>
            <label htmlFor={name}>{label}</label>
            <RSelect
                name={name}
                id={name}
                placeholder={""}
                styles={fieldStyles}
                {...props}
                options={options}
                className='basic-multi-select'
                value={isJsonString(value) && JSON.parse(value)}
                onChange={(val) => {
                    setFieldValue(name, JSON.stringify(val));
                }}
            />
        </div>
    );
}
