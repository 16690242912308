import React, { useState } from "react";
import "./index.scss";

import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import {
  Dashboard,
  Login,
  Kampagnen,
  Vertriebsplan,
  DetailForm,
  CampaignForm,
  ActionForm,
  KampagnenFormular,
  Search,
  PFCSearch,
  Article,
  Iframe,
  Nichtgefunden,
  Reporting,
  Guideline,
} from "./components/pages";
import { useData } from "./utils/dataProvider";
import Layout from "./components/commonComponents/Layout";
import Loader from "./components/commonComponents/Loading";

import ErrorBoundary from "./ErrorHandel";

function App() {
  const { authorized, loading } = useData();

  return (
    <div className="App">
      {loading && (
        <div className="loading-screen">
          <Loader></Loader>
        </div>
      )}
      <Router>
        {authorized ? (
          <Layout>
            <ErrorBoundary>
              <Routes>
                <Route
                  path="/kampagnen"
                  element={<Kampagnen project={"toolbox"} />}
                ></Route>
                <Route path="/reporting" element={<Reporting />}></Route>
                <Route path="/guides" element={<Guideline />}></Route>
                <Route path="/guides/:id" element={<Guideline />}></Route>
                <Route
                  path="/generalrelease"
                  element={<Kampagnen project={"release"} />}
                ></Route>
                <Route path="/search/:string" element={<Search />}></Route>
                <Route path="/pfc/:string" element={<PFCSearch />}></Route>
                <Route path="/article/:string" element={<Article />}></Route>
                <Route
                  path="/top-orders"
                  element={
                    <Iframe url="https://www.toyota-fusion.de/flux/topOrders.php" />
                  }
                ></Route>

                <Route path="/detail/:id" element={<DetailForm />}></Route>

                <Route path="/aktion/:id" element={<ActionForm />}></Route>
                <Route
                  path="/kampagne/:id"
                  element={<KampagnenFormular />}
                ></Route>
                <Route path="/404" element={<Nichtgefunden />}></Route>
                <Route path="/vorschau/:id" element={<Vertriebsplan />}></Route>
                <Route path="/" element={<Dashboard />}></Route>
              </Routes>
            </ErrorBoundary>
          </Layout>
        ) : (
          <Routes>
            <Route path="*" element={<Login />} />
            <Route path="/" element={<Login />} />
          </Routes>
        )}
      </Router>
    </div>
  );
}

export default App;
