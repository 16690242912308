import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { url } from "./configs";

export const DataContext = React.createContext();
export const useData = () => useContext(DataContext);
export const DataProvider = ({ children, ...initOptions }) => {
  const [authorized, setAuthorized] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(null);
  const [fieldData, setFieldData] = useState(null);
  const [campaignData, setCampaignData] = useState(null);
  const [detailData, setDetailData] = useState(null);
  const [aktionData, setAktionData] = useState(null);
  const [planData, setPlanData] = useState(null);
  const [token, setToken] = useState(null);
  const [error, setError] = useState(null);
  const [msPlanData, setMsPlanData] = useState(null);

  const [data, setData] = useState(null);
  const [generalData, setGeneralData] = useState([]);
  const [plans, setPlans] = useState([]);
  const [generalPlans, setGeneralPlans] = useState([]);
  const [currentGeneralPlan, setCurrentGeneralPlan] = useState([]);
  const [currentPlan, setCurrentPlan] = useState(null);
  const [update, setUpdate] = useState(false);
  const [copyKampagnen, setCopyKampagnen] = useState(null);
  const [kampagnenOpenState, setKampagnenOpenState] = useState([]);

  async function makeApiRequest(endpoint, newUrl = null) {
    const token = localStorage.getItem("token");
    const requestUrl = newUrl ? newUrl : url;
    const response = await axios
      .get(requestUrl + endpoint, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .catch((error) => {
        localStorage.removeItem("token");
        setAuthorized(false);
      });
    if (response) {
      return response.data;
    } else {
      return {};
    }
  }
  async function makeApiDeleteRequest(endpoint, set) {
    const token = localStorage.getItem("token");
    const response = await axios
      .delete(url + endpoint, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .catch((error) => {
        localStorage.removeItem("token");
        setAuthorized(false);
      });
    if (response) {
      return response.data;
    } else {
      return {};
    }
  }

  const makeApiPutRequest = async (endpoint, data) => {
    var form_data = new FormData();
    for (var key in data) {
      form_data.append(key, data[key]);
    }
    const token = localStorage.getItem("token");
    const response = await axios
      .put(url + endpoint, form_data, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .catch((error) => {
        console.error(error);
      });
    if (response) {
      return response.data;
    } else {
      return {};
    }
  };

  const makeApiPostRequest = async (endpoint, data) => {
    var form_data = new FormData();
    for (var key in data) {
      form_data.append(key, data[key]);
    }
    const token = localStorage.getItem("token");
    const response = await axios
      .post(url + endpoint, form_data, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .catch((error) => {
        console.error(error);
      });
    if (response) {
      return response.data;
    } else {
      return {};
    }
  };

  const makeApiPost = (endpoint, data, currentPlan, setDatLocal) => {
    const token = localStorage.getItem("token");
    var form_data = new FormData();
    for (var key in data) {
      form_data.append(key, data[key]);
    }
    axios({
      method: "post",
      url: url + endpoint,
      data: form_data,
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        if (currentPlan) {
          axios
            .get(url + "/msplan/" + currentPlan.value, {
              headers: {
                Authorization: "Bearer " + token,
              },
            })
            .then((response) => {
              setDatLocal(response.data?.data[0]?.child);
            });
        }
      })
      .catch((error) => {
        alert("Fehler");
      });
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      setToken(localStorage.getItem("token"));
      const token = localStorage.getItem("token");
      setAuthorized(true);
      axios
        .get(url + "/campaign/fields", {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((res) => {
          setCampaignData(res.data);
        })
        .catch(() => {
          setAuthorized(false);
          localStorage.removeItem("token");
        });
      axios
        .get(url + "/detail/fields", {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((res) => {
          setDetailData(res.data);
        });
      axios
        .get(url + "/action/fields", {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((res) => {
          setAktionData(res.data);
        });
      axios
        .get(url + "/activeUser", {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((response) => {
          if (Array.isArray(response.data)) {
            setUserData(response.data[0]);
          } else {
            setUserData(response.data);
          }
        });
    }
  }, [localStorage.getItem("token")]);

  useEffect(() => {
    if (currentPlan && generalPlans) {
      async function fetchData() {
        const apiData = await makeApiRequest("/msplan/" + currentPlan.value);
        setData(apiData?.data[0]?.child);
        setMsPlanData(apiData?.data[0]);
        const apiGeneralData = await makeApiRequest(
          "/msplan/" + currentGeneralPlan.value
        );
        setGeneralData(apiGeneralData?.data[0]?.child);
        setLoading(false);
      }
      fetchData();
    }
  }, [currentPlan, currentGeneralPlan, update]);

  useEffect(() => {
    async function fetchPlans() {
      if (localStorage.getItem("token")) {
        const apiData = await makeApiRequest(
          "/msplans/0070-726F-6A65-6374-07BF-A488"
        );
        setPlans(apiData?.data);
        const generalApiData = await makeApiRequest(
          "/msplans/0070-726F-6A65-6374-07BF-A489"
        );
        setGeneralPlans(generalApiData?.data);

        if (localStorage.getItem("currentPlan")) {
          setCurrentPlan({
            value: apiData?.data[localStorage.getItem("currentPlan")].entryID,
            label:
              apiData?.data[localStorage.getItem("currentPlan")].msplanName,
          });
        } else {
          setCurrentPlan({
            value: apiData?.data[0].entryID,
            label: apiData?.data[0].msplanName,
          });
        }

        if (localStorage.getItem("currentGeneralPlan")) {
          setCurrentGeneralPlan({
            value:
              generalApiData?.data[localStorage.getItem("currentGeneralPlan")]
                .entryID,
            label:
              generalApiData?.data[localStorage.getItem("currentGeneralPlan")]
                .msplanName,
          });
        } else {
          setCurrentGeneralPlan({
            value: generalApiData?.data[0].entryID,
            label: generalApiData?.data[0].msplanName,
          });
        }
      }
    }
    fetchPlans();
  }, [authorized]);

  function Refresh() {
    if (currentPlan) {
      axios
        .get(url + "/msplan/" + currentPlan.value, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((response) => {
          if (setData) {
            setData(response.data?.data[0]?.child);
          }
        });
    }
  }

  function doLoginWithToken(token) {
    const form = new FormData();
    form.append("token", token);
    axios
      .post("https://api.toyota-fusion.de/v1/authenticate", {
        data: form,
      })
      .then((response) => {
        setToken(response.data.token.jwt);
        localStorage.setItem("token", response.data.token.jwt);
        if (Array.isArray(response.data.user)) {
          setUserData(response.data.user[0]);
        } else {
          setUserData(response.data.user);
        }

        setLoading(false);
      })
      .catch((error) => {
        setAuthorized(false);
      });
  }

  const doLogin = (data) => {
    if (data.data.access_token) {
      setToken(data.data.access_token);
      setAuthorized(true);
      setLoading(false);
      localStorage.setItem("token", data.data.access_token);
    } else {
      setLoading(false);
      setError("Die eingegebenen Daten sind nicht korrekt.");
    }
  };

  const getModifiedTime = (unparsedTime, withYear) => {
    let modified = new Date(unparsedTime * 1000);
    let day = modified.getDate();
    let month = modified.getMonth() + 1;
    let year = modified.getFullYear();
    let time = modified.toLocaleTimeString();
    if (withYear) {
      return `${day}.${month}.${year} | ${time}`;
    } else {
      return `${day}.${month}.${year}`;
    }
  };

  const doLogout = () => {
    setToken(null);
    localStorage.removeItem("token");
    setUserData(null);
    setAuthorized(false);
  };

  const FormInitial = (formdata, data) => {
    formdata.tabs.push({ name: "Allgemein", sections: [] });
    for (let i = 0; i < data.length; i++) {
      if (data[i].properties.private) continue;
      let tabIndx = formdata.tabs.findIndex(
        (tab) => tab.name === data[i].properties.tabName
      );

      if (tabIndx === -1) {
        let tab = { name: data[i].properties.tabName, sections: [] };
        formdata.tabs.push(tab);
      }
      formdata.tabs.forEach((tab) => {
        let sectionIndx = tab.sections.findIndex(
          (section) => section.name === data[i].properties.sectionName
        );
        if (data[i].properties.tabName == tab.name) {
          const optionArray = data[i].properties.fieldOptions.split(";");
          let options = [];
          optionArray.forEach((option) => {
            options.push({ value: option, label: option });
          });
          if (sectionIndx === -1) {
            if (data[i].properties.fieldGroup.length > 0) {
              let section = {
                name: data[i].properties.sectionName,
                fields: [
                  {
                    type: "fieldgroup",
                    label: data[i].properties.fieldGroup,
                    name: data[i].properties.fieldGroup,
                    subfields: [
                      {
                        name: data[i].apiName,
                        type: data[i].properties.fieldType,
                        label: data[i].properties.name,
                        options: options,
                        dataSource: data[i].properties.dataSource,
                      },
                    ],
                  },
                ],
              };
              tab.sections.push(section);
            } else {
              let section = {
                name: data[i].properties.sectionName,
                fields: [
                  {
                    name: data[i].apiName,
                    type: data[i].properties.fieldType,
                    label: data[i].properties.name,
                    options: options,
                    dataSource: data[i].properties.dataSource,
                  },
                ],
              };
              tab.sections.push(section);
            }
          } else {
            if (data[i].properties.fieldGroup.length > 0) {
              let fieldGroupIndx = tab.sections[sectionIndx].fields.findIndex(
                (ele) => ele.label == data[i].properties.fieldGroup
              );
              if (fieldGroupIndx >= 0) {
                tab.sections[sectionIndx].fields[fieldGroupIndx].subfields.push(
                  {
                    name: data[i].apiName,
                    type: data[i].properties.fieldType,
                    label: data[i].properties.name,
                    options: options,
                    dataSource: data[i].properties.dataSource,
                  }
                );
              } else {
                tab.sections[sectionIndx].fields.push({
                  type: "fieldgroup",
                  label: data[i].properties.fieldGroup,
                  name: data[i].properties.fieldGroup,
                  subfields: [
                    {
                      name: data[i].apiName,
                      type: data[i].properties.fieldType,
                      label: data[i].properties.name,
                      options: options,
                      dataSource: data[i].properties.dataSource,
                    },
                  ],
                });
              }
            } else {
              tab.sections[sectionIndx].fields.push({
                name: data[i].apiName,
                type: data[i].properties.fieldType,
                label: data[i].properties.name,
                options: options,
                dataSource: data[i].properties.dataSource,
              });
            }
          }
        }
      });
    }

    return formdata;
  };

  const exports = {
    authorized,
    error,
    setError,
    loading,
    setLoading,
    userData,
    doLogin,
    makeApiRequest,
    getModifiedTime,
    doLogout,
    campaignData,
    makeApiPost,
    makeApiPostRequest,
    makeApiDeleteRequest,
    detailData,
    makeApiPutRequest,
    aktionData,
    FormInitial,
    msPlanData,
    setMsPlanData,
    data,
    setData,
    currentPlan,
    setCurrentPlan,
    plans,
    error,
    setUpdate,
    update,
    generalPlans,
    generalData,
    currentGeneralPlan,
    setCurrentGeneralPlan,
    setGeneralData,
    copyKampagnen,
    setCopyKampagnen,
    Refresh,
    kampagnenOpenState,
    setKampagnenOpenState,
  };
  return (
    <DataContext.Provider value={exports}>{children}</DataContext.Provider>
  );
};
