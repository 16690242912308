import React, { useState, useEffect } from "react";
import "./index.scss";
import formData from "../../forms/data/campaign";

import { Formik, Form } from "formik";
import Loader from "../../commonComponents/Loading";
import SaveIcon from "../../../assets/save.svg";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useData } from "../../../utils/dataProvider";
import FieldRenderer from "../../forms/FieldRenderer";
import axios from "axios";
import { url } from "../../../utils/configs";
import { requiredFields } from "../../forms/data/requiredFIeld";
import { Link } from "react-router-dom";
import HistoryChain from "../../commonComponents/historyChain";
import SaveAlertModal from "../../commonComponents/SaveAlertModal";
import ReactRouterPrompt from "react-router-prompt";
import LeavePageModal from "../../commonComponents/PageLeaveModal";

export default function DetailForm() {
  const location = useLocation();
  const [currentTabs, setTabs] = useState(null);
  const [singleFormData, setSingleFormData] = useState(null);
  const [formData, setFormData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [saveModalVisible, setSaveModalVisible] = useState(false);

  const {
    makeApiRequest,
    aktionData,
    FormInitial,
    msPlanData,
    setUpdate,
    update,
  } = useData();
  const [initialValue, setInitialValue] = useState(null);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if (formData) {
      const tabName = decodeURI(location.hash?.replace("#", ""));
      setTabs(tabName);
      const findTab = formData.tabs.find((tab) => tab.name == tabName);
      if (!findTab) {
        setSingleFormData(formData.tabs[0].sections);
        setTabs(formData.tabs[0].name);
      }
    }
  }, [location, formData]);

  function navigateTab(index) {
    navigate(`${location.pathname}#${formData.tabs[index].name}`);
  }

  async function fetchCampaign() {
    setLoading(true);
    const apiData = await makeApiRequest("/action/" + id);
    setInitialValue(apiData?.data[0] ? apiData.data[0] : "null");
    setLoading(false);
  }

  useEffect(() => {
    fetchCampaign();
    setUpdate(!update);
  }, [id]);

  useEffect(() => {
    if (aktionData) {
      generateFormData(aktionData);
    }
  }, [aktionData]);

  function findAction(data) {
    if (data.child) {
      for (let i = 0; i < data.child.length; i++) {
        if (data.child[i].entryID == id) {
          return data.child[i];
          break;
        } else {
          findAction(data.child[i]);
        }
      }
    }
  }

  function generateFormData(data) {
    let formdata = { tabs: [] };
    data = data.map((item) => {
      let newItem = { ...item };
      if (!item.properties.tabName.length) {
        newItem.properties.tabName = "Allgemein";
      }
      if (!item.properties.sectionName) {
        newItem.properties.sectionName = "Allgemein";
      }
      return newItem;
    });
    formdata = FormInitial(formdata, data);
    setFormData(formdata);
    setLoading(false);
  }

  const submitAction = (values) => {
    setLoading(true);
    const token = localStorage.getItem("token");
    var form_data = new FormData();
    for (var key in values) {
      form_data.append(key, values[key]);
    }
    axios({
      method: "put",
      url: url + "/action/" + id,
      data: form_data,
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        fetchCampaign();
        setSaveModalVisible(false);
      })
      .catch((error) => {
        alert("Fehler");
        setSaveModalVisible(false);
      });
  };
  if (loading || !initialValue || !formData || !singleFormData || !aktionData) {
    return (
      <div
        style={{
          height: "100vh",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      ></div>
    );
  }
  if (initialValue == "null") {
    return <div>Die Aktion konnte nicht gefunden werden.</div>;
  }

  return (
    <Formik
      initialValues={initialValue}
      onSubmit={(values) => {
        setSaveModalVisible(true);
      }}
    >
      {({ values, dirty }) => (
        <Form>
          <div className="aktion-formular">
            <HistoryChain id={id} />
            <div className="aktion-formular-header">
              <h1>Aktion bearbeiten</h1>
              <button
                className="toyota-button toyota-button-red aktion-save"
                type="submit"
              >
                <img src={SaveIcon}></img>
                Aktion speichern
              </button>
            </div>
            <div className="aktion-formular-form">
              <div
                className="aktion-formluar-tab-list"
                style={{
                  gridTemplateColumns: `repeat(${formData.tabs.length},1fr)`,
                }}
              >
                {formData.tabs.map((tabs, index) => (
                  <div
                    className="aktion-formular-tab-list-item"
                    onClick={() => {
                      navigateTab(index);
                    }}
                    key={tabs + index}
                  >
                    <div className="aktion-formular-tab-list-item-text">
                      {tabs.name}
                    </div>
                    <div
                      className="underline"
                      style={{
                        opacity:
                          currentTabs === formData.tabs[index].name ? 1 : 0,
                      }}
                    ></div>
                  </div>
                ))}
              </div>

              <div className="aktion-formular-content">
                {singleFormData.map((section, idx) => (
                  <div className="section-block" key={`section-block-${idx}`}>
                    <div className="section-title">{section.name}</div>
                    <div className="section-form">
                      <FieldRenderer values={values} fields={section.fields} />
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <ReactRouterPrompt when={dirty}>
              {({ isActive, onConfirm, onCancel }) =>
                isActive && (
                  <LeavePageModal onConfirm={onConfirm} onCancel={onCancel} />
                )
              }
            </ReactRouterPrompt>
            {saveModalVisible && (
              <SaveAlertModal
                kat={"Aktion"}
                values={values}
                submit={submitAction}
                setSaveModalVisible={setSaveModalVisible}
              />
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
}
