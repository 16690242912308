import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useData } from "../../../utils/dataProvider";
import Articles from "./Articles";
import Loader from "../../commonComponents/Loading";
import "./style.scss";

export default function SearchIndex() {
  const { makeApiPostRequest, makeApiRequest } = useData();
  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(true);

  const { string } = useParams();
  useEffect(() => {
    const searchTerm = decodeURI(string);

    async function fetchData() {
      setLoading(true);
      setResults(null);
      if (searchTerm.length === 3) {
        const searchRequest = await makeApiRequest(`/parts/pfc/` + searchTerm);
        setResults(searchRequest);
      } else {
        const searchRequest = await makeApiPostRequest(`/itemSearch`, {
          var: searchTerm,
        });
        const data =
          searchRequest &&
          searchRequest.map((item) => {
            return mapItem(item);
          });

        setResults(data);
      }

      setLoading(false);
    }
    fetchData();
  }, [string]);

  function mapItem(item) {
    const { part } = item;

    if (item.accessory) {
      const { accessory } = item;

      return {
        partNumber: accessory.number,
        partInfo: accessory.partInfo,
        priceInfo: accessory.priceInfo,
      };
    } else if (item.part) {
      return {
        info: part,
        partNumber: part.number,
        partInfo: part.partinfo,
        priceInfo: part.priceInfo,
      };
    }
  }
  return (
    <div>
      <h1>Ergebnisse für {decodeURI(string)}</h1>
      {loading ? (
        <Loader width="20px" height="20px" />
      ) : (
        <Articles data={results} />
      )}
    </div>
  );
}
