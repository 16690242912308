import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import Close from "../../../../../assets/close.svg";
import RSelect from "react-select";
import { useParams } from "react-router-dom";
import { useData } from "../../../../../utils/dataProvider";
import { useArticleStore } from "../Articlestore";
export default function ModelModal({
  setShowModelModal,
  getDetailModels,
  arbeit,
  modelID,
  modelName,
}) {
  const { id } = useParams();
  const { makeApiRequest, makeApiPutRequest, makeApiPostRequest } = useData();
  const [models, setModels] = useState([]);
  const { fetchData } = useArticleStore();
  useEffect(() => {
    async function getData() {
      const cars = [{ value: "Alle Fahrzeuge", label: "Alle Fahrzeuge" }];
      const data = await makeApiRequest("/cars/models");
      data &&
        data.forEach((carline, idx) => {
          carline.models &&
            carline.models.forEach((car) => {
              cars.push({
                label:
                  car.modelName +
                  " " +
                  car.productionLineOffDate +
                  " (" +
                  car.modelID +
                  ")",
                value: idx,
                modelID: car.modelID,
                modelName: car.modelName,
                productionLineOffDate: car.productionLineOffDate,
              });
            });
        });
      setModels(cars);
    }
    getData();
  }, []);

  async function addModel(values) {
    const data = {
      modelName: arbeit
        ? modelName.split(" ").slice(0, 2).join(" ") +
          " " +
          values?.params?.join(" ")
        : values?.selected?.modelName
        ? values.selected.modelName +
          " " +
          values.selected.productionLineOffDate +
          " " +
          values?.params?.join(" ")
        : "Alle Fahrzeuge",
      modelID: values.selected.modelID ? values.selected.modelID : modelID,
      detailID: id,
    };

    let finish;

    if (arbeit) {
      finish = await makeApiPutRequest(`/model/${modelID}`, data);
    } else {
      finish = await makeApiPostRequest(`/model/${id}`, data);
    }

    if (finish) {
      setShowModelModal(false);
      fetchData(id);
    }
  }
  const fieldStyles = {
    control: () => ({
      width: "100%",
      height: "42px",
      display: "flex",
      padding: "0px 3px",
      border: "1px solid #ccc",
    }),
    dropdownIndicator: () => ({ color: "black", paddingLeft: "8px" }),
  };

  return (
    <Formik initialValues={{ selected: [], params: ["", "", ""] }}>
      {(props) => (
        <div className="modal-wrapper">
          <div className="modal-window">
            <img
              className="close"
              onClick={() => setShowModelModal(false)}
              src={Close}
            ></img>
            <div
              className="aktion-formular-content"
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <h1>Modell {arbeit ? "bearbeiten" : "hinzufügen"}</h1>
              {arbeit ? null : (
                <div className={`aktion-input`}>
                  <RSelect
                    defaultValue={"Alle Fahrzeuge"}
                    options={models}
                    styles={fieldStyles}
                    className="basic-multi-select"
                    onChange={(val) => props.setFieldValue("selected", val)}
                    placeholder="Modell auswählen"
                  />
                </div>
              )}

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: 24,
                }}
              >
                <div className="modal-input">
                  <label htmlFor={`params.${0}`}>Baureihe</label>
                  <Field name={`params.${0}`} id={`params.${0}`}></Field>
                </div>
                <div className="modal-input">
                  <label htmlFor={`params.${1}`}>Motor</label>
                  <Field name={`params.${1}`} id={`params.${1}`}></Field>
                </div>
                <div className="modal-input">
                  <label htmlFor={`params.${2}`}>Klimaanlage</label>
                  <Field name={`params.${2}`} id={`params.${2}`}></Field>
                </div>
              </div>
            </div>
            <button
              onClick={() => addModel(props.values)}
              type="button"
              className="toyota-button toyota-button-red"
            >
              Modell {arbeit ? "speichern" : "hinzufügen"}
            </button>
          </div>
        </div>
      )}
    </Formik>
  );
}
