import React from "react";

export default function FilterForm({ item, setFilterValue, filterValue }) {
  function updateSpread(value, type) {
    let current = { ...filterValue };
    current[type] = value;
    setFilterValue(current);
  }

  switch (item.filterType) {
    case "string":
      return (
        <input
          type="text"
          onChange={(e) => setFilterValue(e.target.value)}
          value={filterValue ? filterValue : ""}
        />
      );
    case "float":
      return (
        <div className="float-wrap">
          <input
            placeholder="Min"
            type="number"
            onChange={(e) => updateSpread(e.target.value, "min")}
            value={filterValue?.min || ""}
          />
          <input
            placeholder="max"
            type="number"
            onChange={(e) => updateSpread(e.target.value, "max")}
            value={filterValue?.max || ""}
          />
        </div>
      );
    case "bool":
      return (
        <div className="float-wrap">
          <input
            type="checkbox"
            checked={filterValue !== "false"}
            onChange={(e) =>
              setFilterValue(e.target.checked ? "true" : "false")
            }
            value={filterValue}
          />
          Ist vorhanden
        </div>
      );
  }
  return;
}
