import React, { useEffect, useState } from "react";
import { Formik, Field, Form } from "formik";

import { useNavigate } from "react-router-dom";
import { useData } from "../../../utils/dataProvider";
import ActionModal from "../../pages/Kampagnen/modals/Aktion";
import ModalDetail from "../../pages/Kampagnen/modals/Detail";
import LoscheModal from "../../commonComponents/LoscheModal";
import ActionFormDetail from "./ActionFormDetail";
import ReactTooltip from "react-tooltip";

export default function ActionForm({ id }) {
  const navigate = useNavigate();
  const { makeApiRequest } = useData();
  const [data, setData] = useState(null);
  const [actionModalVisible, setAktionFormularVisible] = useState(false);
  const [detailModalVisible, setDetailModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  const [updated, setUpdated] = useState(true);

  function getTime(d) {
    let f = new Date(d);
    return f.getDate() + "." + (f.getMonth() + 1) + "." + f.getFullYear();
  }
  useEffect(() => {
    async function getDetails(id, actions, idx) {
      const apiData = await makeApiRequest("/details/" + id);
      actions[idx].child = [];
      if (apiData.data?.length > 0) {
        apiData.data.forEach((detail) => {
          actions[idx].child.push(detail);
        });
      }

      setData([...actions]);
    }

    async function test() {
      const apiData = await makeApiRequest("/actions/" + id);
      if (apiData.data?.length > 0) {
        apiData.data?.forEach((item, idx) => {
          getDetails(item.entryID, apiData.data, idx);
        });
      }
    }
    test();
  }, [updated]);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [data]);

  if (data) {
    return (
      <>
        <ReactTooltip effect="solid" scrollHide={true} />
        {data.map((item, idx) => {
          return (
            <div
              key={`action-${idx}`}
              style={{
                background: "#EEEEEE",
                padding: "16px",
                marginBottom: 12,
              }}
            >
              <div className="kampagnen-content">
                <div
                  className="kampagnen-content-header"
                  style={{
                    borderBottom: "1px solid #ccc",
                    paddingBottom: 16,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    {item.actionName} :{" "}
                    {getTime(item.actFrom) + " - " + getTime(item.actTo)}
                  </div>
                  <div className="icon-bar" style={{ justifySelf: "end" }}>
                    <div
                      data-tip={"Aktion löschen"}
                      className="icon-bar-icon delete"
                      onClick={() => setDeleteModalVisible(true)}
                    ></div>
                    <div
                      data-tip={"Aktion bearbeiten"}
                      className="icon-bar-icon tools"
                      onClick={() => navigate("/aktion/" + item.entryID)}
                    ></div>
                  </div>
                </div>
                <div
                  className="kampagnen-content-container"
                  style={{ marginTop: 16 }}
                >
                  {item.child &&
                    item.child.map((detail, index) => (
                      <ActionFormDetail
                        detail={detail}
                        item={item}
                        index={index}
                        updated={updated}
                        setUpdated={setUpdated}
                        key={index}
                      ></ActionFormDetail>
                    ))}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: 12,
                }}
              >
                <div
                  onClick={() => setDetailModalVisible(item.entryID)}
                  data-tip={"Detail hinzufügen"}
                  style={{
                    height: 32,
                    width: 32,
                    borderRadius: 32,
                    paddingBottom: 4,
                    backgroundColor: "white",
                    fontSize: 24,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  +
                </div>
              </div>
              {deleteModalVisible && (
                <LoscheModal
                  setDeleteModalVisible={setDeleteModalVisible}
                  kat={"Aktion"}
                  updated={updated}
                  setUpdated={setUpdated}
                  setData={setData}
                  entryID={item.entryID}
                  name={item.actionName}
                ></LoscheModal>
              )}
            </div>
          );
        })}
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: 12,
          }}
        >
          <div
            onClick={() => setAktionFormularVisible(true)}
            data-tip={"Aktion hinzufügen"}
            style={{
              height: 32,
              width: 32,
              borderRadius: 32,
              paddingBottom: 4,
              backgroundColor: "#eee",
              fontSize: 24,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            +
          </div>
        </div>
        {actionModalVisible && (
          <ActionModal
            setZeitraumFormularVisible={setAktionFormularVisible}
            campaignID={id}
            updated={updated}
            setUpdated={setUpdated}
          />
        )}
        {detailModalVisible && (
          <ModalDetail
            setDetailModalVisible={setDetailModalVisible}
            actionID={detailModalVisible}
            updated={updated}
            setUpdated={setUpdated}
          />
        )}
      </>
    );
  } else {
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: 12,
          }}
        >
          <div
            onClick={() => setAktionFormularVisible(true)}
            style={{
              backgroundColor: "#ccc",
              fontSize: 16,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              padding: "8px 16px",
              color: "white",
            }}
          >
            Neue Aktionszeitraum
          </div>
        </div>
        {actionModalVisible && (
          <ActionModal
            setZeitraumFormularVisible={setAktionFormularVisible}
            campaignID={id}
            updated={updated}
            setUpdated={setUpdated}
            setData={setData}
          />
        )}
      </>
    );
  }
}
