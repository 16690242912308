import { create } from "zustand";
import { addDays, set } from "date-fns";
import axios from "axios";

const useDataStore = create((set, get) => ({
  data: null,
  previousData: null,
  loading: true,
  startDate: new Date(),
  endDate: new Date(),
  key: "selection",
  startDate: addDays(new Date(), -1),
  endDate: addDays(new Date(), -1),
  dealerData: null,
  dealerLoading: true,
  getData: async (state) => {
    set({
      loading: true,
    });
    let res = await getData(get().startDate, get().endDate);
    set({
      data: await res.data1,
      previousData: await res.data2,
      loading: false,
    });
  },
  getDealerData: async (state) => {
    let res = await getDealers(get().startDate, get().endDate);
    set({
      dealerData: await res,
      dealerLoading: false,
    });
  },
  handelTime: (time) => {
    set({ startDate: time.startDate, endDate: time.endDate });
  },
}));

async function getData(startDate, endDate) {
  const siteUrl = window?.siteUrl || "https://backend.toyota-toolbox.de";
  const diff = Math.floor(
    (Date.parse(endDate) - Date.parse(startDate)) / 86400000
  );
  const prevStartDate = addDays(startDate, -1 - diff);
  const prevEndDate = addDays(startDate, -1);
  let one = `${siteUrl}/api/toolbox/v1/analytics?auth=fusion&start=${toTimeStamp(
    startDate
  )}&end=${toTimeStamp(endDate)}`;

  let two = `${siteUrl}/api/toolbox/v1/analytics?auth=fusion&start=${toTimeStamp(
    prevStartDate
  )}&end=${toTimeStamp(prevEndDate)}`;

  let response1 = await axios
    .get(one, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
    .catch((error) => {
      return error.response;
    });
  let response2 = await axios
    .get(two, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
    .catch((error) => {
      return error.response;
    });

  return { data1: response1.data, data2: response2.data };
}

function toTimeStamp(time) {
  let t = time;
  return new Date(t).getTime() / 1000;
}

async function getDealers(startDate, endDate) {
  const siteUrl = window?.siteUrl || "https://backend.toyota-toolbox.de";

  let one = `${siteUrl}/api/toolbox/v1/analytics/dealers?auth=fusion&start=${toTimeStamp(
    startDate
  )}&end=${toTimeStamp(endDate)}`;

  let response = await axios
    .get(one, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    })
    .catch((error) => {
      return error.response;
    });
  return response.data;
}

export { useDataStore };
