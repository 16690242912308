import React, { useState, useEffect } from "react";

import { useData } from "../../utils/dataProvider";
import { useLocation, Link } from "react-router-dom";

export default function HistoryChain({ id }) {
    const [historyChain, setHistoryChain] = useState(null);

    const { data, currentPlan, generalData, currentGeneralPlan } = useData();
    const returnName = (name) => {
        return name.divisionName
            ? name.divisionName
            : name.campaignName
            ? name.campaignName
            : name.actionName
            ? name.actionName
            : name.detailName;
    };

    const routeChain = (data, parent = []) => {
        if (data) {
            for (let i = 0; i < data.length; i++) {
                if (data[i].entryID == id) {
                    parent.push({
                        name: returnName(data[i]),
                        id: data[i].entryID,
                    });
                    return parent;
                } else if (
                    data[i].child &&
                    Array.isArray(data[i].child) &&
                    data[i].child.length > 0
                ) {
                    parent.push({
                        name: returnName(data[i]),
                        id: data[i].entryID,
                    });
                    let names = routeChain(data[i].child, parent);
                    if (names) return names;
                } else {
                    parent.push({
                        name: returnName(data[i]),
                        id: data[i].entryID,
                    });
                }
                parent.pop();
            }
        }
        return "";
    };

    useEffect(() => {
        if (data && generalData) {
            if (routeChain(data)) {
                setHistoryChain(routeChain(data));
            } else {
                setHistoryChain(routeChain(generalData));
            }
        }
    }, [data, generalData]);

    return (
        <>
            {historyChain && (
                <>
                    <Link
                        to={
                            routeChain(data) ? "/kampagnen" : "/generalrelease"
                        }>
                        {routeChain(data)
                            ? currentPlan.label
                            : currentGeneralPlan.label}
                    </Link>
                    {historyChain.map((item, idx) => (
                        <Link
                            key={item.name + idx}
                            to={
                                idx == 0
                                    ? "/kampagnen"
                                    : idx == 1
                                    ? "/kampagnen"
                                    : idx == 2
                                    ? "/aktion/" + item.id
                                    : "/detail/" + item.id
                            }>
                            {" "}
                            > {item.name}
                        </Link>
                    ))}
                </>
            )}
        </>
    );
}
