import React, { useEffect, useState } from "react";
import { Formik, Field, useField, useFormikContext } from "formik";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { registerLocale } from "react-datepicker";
import de from "date-fns/locale/de";
registerLocale("de", de);

export default function NeuDatePicker({
    label,
    value,
    values,
    required,
    ...props
}) {
    const { setFieldValue } = useFormikContext();

    const [field] = useField(props);

    const [startDate, setStartDate] = useState(
        field?.value && field?.value !== "null" ? new Date(field.value) : ""
    );

    const [error, setError] = useState(null);

    function formatDate(date) {
        var d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return [year, month, day].join("-");
    }

    useEffect(() => {
        if (!field.value) {
            setFieldValue(field.name, "");
        }
    }, []);

    useEffect(() => {
        let words = field.name.split("To");
        if (words.length < 2) {
            words = field.name.split("From");
        }

        const From = new Date(values?.[words[0] + "From"]);
        const To = new Date(values?.[words[0] + "To"]);

        if (To < From) {
            setError("Enddatum muss nach dem Startdatum liegen");
        } else {
            setError(null);
        }
    }, [values]);

    return (
        <div
            className={`aktion-input ${
                required && (!value || value.length == 0)
                    ? "required-field"
                    : ""
            }`}>
            <label htmlFor={field.name}>{label}</label>

            <DatePicker
                className='calendar-input'
                dateFormat='P'
                locale='de'
                selected={startDate}
                onChange={(val) => {
                    setStartDate(val);
                    setFieldValue(field.name, formatDate(val));
                }}
            />
            <div style={{ color: "#ff0022" }}>{error}</div>
        </div>
    );
}
