import React, { useState } from "react";
import Close from "../../../../../assets/close.svg";
import { useData } from "../../../../../utils/dataProvider";

import Loader from "../../../../commonComponents/Loading";
export default function DiscountModal({
  setDiscountModalVisible,
  discountAll,

  update,
  setUpdate,
  articleChecked,
  articles,
  setArticleChecked,
}) {
  const { makeApiPutRequest } = useData();
  const [loading, setLoading] = useState(false);

  function Update() {
    setLoading(true);
    let array = [];
    for (let i = 0; i < articles.length; i++) {
      if (articleChecked[i]) {
        array.push(
          makeApiPutRequest(`/item/${articles[i].entryID}`, {
            discountPercent: parseFloat(discountAll).toFixed(2),
          })
        );
      }
    }
    Promise.all(array).then((res) => {
      setUpdate(!update);
      setDiscountModalVisible(false);
      setArticleChecked([]);
      setLoading(false);
    });
  }

  return (
    <div className="modal-wrapper">
      <div className="modal-window">
        <img
          className="close"
          src={Close}
          onClick={() => setDiscountModalVisible(false)}
        ></img>
        <h2>Aktionsrabatt ändern</h2>
        <div>
          Möchtest du ausgewählt Artikels Aktionsrabatt mit {discountAll}%
          ersetzen?
        </div>

        <div
          style={{
            display: "flex",
            marginTop: 32,
            justifyContent: "flex-end",
          }}
        >
          <div
            className="toyota-button toyota-button-grey"
            onClick={() => setDiscountModalVisible(false)}
            style={{ marginRight: 16, width: 120 }}
          >
            Abbrechen
          </div>
          <div
            onClick={Update}
            className="toyota-button toyota-button-red"
            style={{ width: 120 }}
          >
            {loading ? <Loader height={8} width={8} /> : "Bestätigen"}
          </div>
        </div>
      </div>
    </div>
  );
}
