import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LoscheModal from "../../commonComponents/LoscheModal";
import ReactTooltip from "react-tooltip";

export default function Detail({ data, length, index, setData, currentPlan }) {
  const navigate = useNavigate();
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [topSign, setTopSign] = useState(false);
  const [tosSign, setTosSign] = useState(false);

  function countVacancies() {
    let size = 0;
    for (let k in data) {
      if (data[k]) {
        size++;
      }
    }
    return size;
  }

  useEffect(() => {
    if (data.tosRelevant == "true") {
      setTosSign(true);
    }
    if (data.topRelevant == "true") {
      setTopSign(true);
    }
  }, []);

  const generateKurzName = (name) => {
    if (name.split(" ").length > 1) {
      return name.split(" ")[0][0] + name.split(" ")[1][0];
    } else {
      return name.split(" ")[0][0] + name.split(" ")[0][1];
    }
  };

  function isJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  function StatusColor(status) {
    switch (status) {
      case "Offen":
        return "#FC6969";
        break;
      case "in Arbeit":
        return "#FFC62B";
        break;
      case "Fertig":
        return "#52BA4A";
        break;
      default:
        return "#FC6969";
        break;
    }
  }
  if (data.detailOwner == "null") {
    data.detailOwner = null;
  }

  return (
    <div
      className="action-container"
      style={{
        borderTop: length > 1 && index != 0 ? "1px solid #ccc" : "none",
      }}
    >
      <ReactTooltip effect="solid" scrollHide={true} />
      <div className="kampagnen-header">
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ maxWidth: 200 }}>{data.detailName}</div>
          {topSign && <div className="top-sign">TOP</div>}
          {tosSign && <div className="tos-sign">TOS</div>}
        </div>
        <div className="kampagnen-info-bar">
          <div className="name-bar">
            {data.detailOwner &&
              JSON.parse(data.detailOwner).map((person, idx) => (
                <div
                  className="name-bar-name"
                  key={person.label + data.entryID}
                  data-tip={person.label}
                >
                  {generateKurzName(person.label)}
                </div>
              ))}
          </div>
          <div
            className="status-bar"
            style={{
              backgroundColor: StatusColor(
                isJsonString(data.currentStatusCampaign) &&
                  JSON.parse(data.currentStatusCampaign).value
              ),
            }}
          >
            {isJsonString(data.currentStatusCampaign)
              ? JSON.parse(data.currentStatusCampaign)?.value
              : "Offen"}
          </div>
          <div className="progress-bar">
            <div>
              {countVacancies()}/{Object.keys(data)?.length}
            </div>
            <div className="progress">
              <div
                className="progress-done"
                style={{
                  width: `${
                    (countVacancies() / Object.keys(data)?.length) * 100
                  }%`,
                }}
              ></div>
            </div>
          </div>
        </div>
        <div className="icon-bar" style={{ justifySelf: "end" }}>
          <div
            className="icon-bar-icon delete "
            data-tip={"Detail löschen"}
            onClick={() => setDeleteModalVisible(true)}
          ></div>
          <div
            className="icon-bar-icon tools"
            data-tip={"Detail bearbeiten"}
            onClick={() => {
              navigate("/detail/" + data.entryID, {
                state: { iframe: data.iFrameUrl },
              });
            }}
          ></div>
        </div>
      </div>
      {deleteModalVisible && (
        <LoscheModal
          setDeleteModalVisible={setDeleteModalVisible}
          kat={"Detail"}
          entryID={data.entryID}
          setData={setData}
          currentPlan={currentPlan}
          name={data.detailName}
        ></LoscheModal>
      )}
    </div>
  );
}
