import React, { useState } from "react";
import Detail from "./Detail";
import ModalDetail from "./modals/Detail";
import { useNavigate } from "react-router-dom";
import LoscheModal from "../../commonComponents/LoscheModal";
import { useEffect } from "react";
import ReactTooltip from "react-tooltip";

export default function Aktion({ data, setData, currentPlan }) {
    const navigate = useNavigate();
    const [detailModalVisible, setDetailModalVisible] = useState(false);
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);

    let f = new Date(data.actFrom);
    let from = f.getDate() + "." + (f.getMonth() + 1) + "." + f.getFullYear();

    let t = new Date(data.actTo);
    let to = t.getDate() + "." + (t.getMonth() + 1) + "." + t.getFullYear();

    useEffect(() => {
        ReactTooltip.rebuild();
    }, [data]);

    return (
        <div key={data.entryID}>
            <div className='kampagnen-content-header'>
                <div style={{ display: "flex" }}>
                    <div>
                        {data.actionName} : {from + " - " + to}
                    </div>
                </div>

                <div className='icon-bar' style={{ justifySelf: "end" }}>
                    <div
                        data-tip={"Aktion löschen"}
                        className='icon-bar-icon delete'
                        onClick={() => setDeleteModalVisible(true)}></div>
                    <div
                        className='icon-bar-icon tools'
                        data-tip={"Aktion bearbeiten"}
                        onClick={() =>
                            navigate("/aktion/" + data.entryID)
                        }></div>
                </div>
            </div>
            <div className='kampagnen-content-container'>
                {data.child &&
                    data.child.map((detail, index) => (
                        <Detail
                            key={detail.entryID}
                            data={detail}
                            setData={setData}
                            currentPlan={currentPlan}
                            length={data.child.length}
                            index={index}></Detail>
                    ))}
            </div>
            <div style={{ height: 16 }}></div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    paddingBottom: 12,
                }}>
                <div
                    onClick={() => setDetailModalVisible(!detailModalVisible)}
                    data-tip={"Detail hinzufügen"}
                    style={{
                        height: 32,
                        width: 32,
                        borderRadius: 32,
                        paddingBottom: 4,
                        backgroundColor: "white",
                        fontSize: 24,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                    }}>
                    +
                </div>
            </div>
            {detailModalVisible && (
                <ModalDetail
                    setDetailModalVisible={setDetailModalVisible}
                    actionID={data.entryID}
                    setData={setData}
                    currentPlan={currentPlan}
                />
            )}
            {deleteModalVisible && (
                <LoscheModal
                    setDeleteModalVisible={setDeleteModalVisible}
                    kat={"Aktion"}
                    setData={setData}
                    entryID={data.entryID}
                    currentPlan={currentPlan}
                    name={data.actionName}></LoscheModal>
            )}
        </div>
    );
}
