import React, { useState, useEffect } from "react";
import Close from "../../assets/close.svg";
import { useData } from "../../utils/dataProvider";
import axios from "axios";
import { url } from "../../utils/configs";
import { useLocation } from "react-router-dom";
export default function SaveAlertModal({
    setSaveModalVisible,
    kat,
    submit,
    values,
}) {
    const location = useLocation();

    return (
        <div className='modal-wrapper'>
            <div className='modal-window'>
                <img
                    className='close'
                    src={Close}
                    onClick={() => setSaveModalVisible(false)}></img>
                <h2>{kat} speichern</h2>
                Möchten Sie die Änderungen wirklich speichern?
                <div
                    style={{
                        display: "flex",
                        marginTop: 32,
                        justifyContent: "flex-end",
                    }}>
                    <div
                        className='toyota-button toyota-button-grey'
                        onClick={() => setSaveModalVisible(false)}
                        style={{ marginRight: 16, width: 120 }}>
                        Abbrechen
                    </div>
                    <div
                        onClick={() => submit(values)}
                        className='toyota-button toyota-button-red'
                        style={{ width: 120 }}>
                        Bestätigen
                    </div>
                </div>
            </div>
        </div>
    );
}
