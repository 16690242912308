import React, { useEffect, useState } from "react";
import { Formik, Field, Form, useFormikContext } from "formik";
import { Editor } from "react-draft-wysiwyg";
import { convertToRaw, ContentState, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
export default function Textarea({
  label,
  name,
  type,
  placeholder,
  required,
  value,
  ...props
}) {
  const { setFieldValue } = useFormikContext();

  function prepareDraft(value) {
    if (value) {
      const draft = htmlToDraft(value);
      const contentState = ContentState.createFromBlockArray(
        draft.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      return editorState;
    }
  }

  const [editorState, setEditorState] = useState(null);

  useEffect(() => {
    setEditorState(value ? prepareDraft(value) : EditorState.createEmpty());
  }, []);

  const onEditorStateChange = (editorState) => {
    const forFormik = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    setFieldValue(name, forFormik);
    setEditorState(editorState);
  };

  return (
    <div
      className={`aktion-input ${required && !value ? "required-field" : ""}`}
    >
      <label htmlFor={name}>{label}</label>
      <Editor
        editorState={editorState}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        onEditorStateChange={onEditorStateChange}
        defaultSize={800}
      />
    </div>
  );
}
