import React, { useState } from "react";
import { useData } from "../../../../utils/dataProvider";
import { useEffect } from "react";
import ExcelModal from "./ArticleImportModal/ExcelModal";
import PFCModal from "./ArticleImportModal/PFCModal";
import Excel from "../../../../assets/excel.svg";
import Database from "../../../../assets/database.svg";
import Loading from "../../../commonComponents/Loading";
import ArticleModal from "./ArticleImportModal/ArticleModal";
import Article from "./Article";
import LoscheModal from "../../../commonComponents/LoscheModal";
import Delete from "../../../../assets/delete_white.svg";
import ChainLoscheModal from "../../../commonComponents/ChainLoscheModal";
import Tools from "../../../../assets/tools-white.svg";
import DiscountModal from "./ArticleModal/DiscountModal";
import Toolsbar from "./Toolsbar";
import { TabelHeadArray, sortArtikel, filterFunction } from "./TabelHeadArray";
import DownArrow from "../../../../assets/down-triangle.svg";
import Filter from "./ArticleFilter";
import ModelModal from "./ModelModal/ModelModal";
import { useLocation } from "react-router-dom";
import { Waypoint } from "react-waypoint";
import { useArticleStore } from "./Articlestore";

export default function Articles({
  modelID,
  modelName,
  discount,
  model,
  getDetailModels,
}) {
  const location = useLocation();
  const [showExcelModal, setShowExcelModal] = useState(false);
  const [showPFCModal, setShowPFCModal] = useState(false);
  const [showArticleModal, setShowArticleModal] = useState(false);
  const [articles, setArticles] = useState(null);

  const [renderCount, setRenderCount] = useState(0);

  const [sortedArtikel, setSortedArtikel] = useState(null);
  const [sortStatusArray, setSortStatusArray] = useState(
    Array.from({ length: 11 }, (i) => false)
  );
  const [update, setUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [articleDiscountArray, setArticleDiscountArray] = useState([]);
  const [articleChecked, setArticleChecked] = useState([]);
  const [checkedAll, setCheckedAll] = useState(false);
  const [discountAll, setDiscountAll] = useState(0);
  const [filterValue, setFilterValue] = useState(null);
  const [filterKat, setFilterKat] = useState(null);
  const [discountModalVisible, setDiscountModalVisible] = useState(false);
  const [modelModalVisible, setModelModalVisible] = useState(false);
  const [deleteArticleModalVisible, setDeleteArticleModalVisible] =
    useState(false);
  const [modelInView, setModelInView] = useState(false);
  const [firstfetch, setFirstFetch] = useState(true);
  const { models, updateArticle } = useArticleStore();

  async function getItems() {
    setLoading(true);
    await updateArticle(modelID);
    let idx = models.findIndex((ele) => ele.entryID == modelID);
    if (idx >= 0) {
      setArticles(models[idx].articles);
      setSortedArtikel(models[idx].articles);
    }
    setLoading(false);
  }

  useEffect(() => {
    let idx = models.findIndex((ele) => ele.entryID == modelID);
    if (idx >= 0) {
      setArticles(models[idx].articles);
      setSortedArtikel(models[idx].articles);
    }
    setFirstFetch(false);
    setLoading(false);
  }, []);

  useEffect(() => {
    if (!firstfetch) {
      getItems();
    }
  }, [update]);

  useEffect(() => {
    if (filterKat == "inTOS") {
      setFilterValue("true");
    } else {
      setFilterValue(null);
    }
  }, [filterKat]);

  useEffect(() => {
    if (articles) {
      let array = [];
      articles.forEach((item, idx) => {
        array.push({
          entryID: item.entryID,
          discountPercent: parseFloat(item.discountPercent),
        });
      });
      setArticleDiscountArray(array);
    }
  }, [articles, sortedArtikel]);

  useEffect(() => {
    if (articles && location.state?.itemNumber) {
      setFilterKat("number");
      setFilterValue(location.state?.itemNumber);
    }
  }, [articles]);

  useEffect(() => {
    let array = [];
    if (sortedArtikel) {
      if (checkedAll) {
        sortedArtikel.forEach((element) => {
          if (filterFunction(element, filterValue, filterKat)) {
            array.push(true);
          } else {
            array.push(false);
          }
        });
        setArticleChecked(array);
      } else {
        sortedArtikel.forEach((element) => {
          array.push(false);
        });
        setArticleChecked(array);
      }
    }
  }, [checkedAll, filterValue, filterKat]);

  function artikelSort(type, idx) {
    let status = [...sortStatusArray];
    let array = [...sortedArtikel];
    for (let i = 0; i < status.length; i++) {
      if (i !== idx) {
        status[i] = false;
      }
    }
    if (!status[idx]) {
      sortArtikel(array, type, true);
      setSortedArtikel([...array]);
      status[idx] = "big";
      setSortStatusArray([...status]);
    } else if (status[idx] == "big") {
      sortArtikel(array, type, false);
      setSortedArtikel([...array]);
      status[idx] = "small";
      setSortStatusArray([...status]);
    } else if ((status[idx] = "small")) {
      setSortedArtikel([...articles]);
      status[idx] = false;
      setSortStatusArray([...status]);
    }
  }
  return (
    <Waypoint
      scrollableAncestor={window}
      bottomOffset={window.innerHeight + 1}
      onEnter={() => setModelInView(true)}
      onLeave={() => setModelInView(false)}
    >
      <div className="model-block">
        <div className="model-row">
          <span>{modelName}</span>
          <div className="action-header">
            <div className="action-holder">
              {!loading && articles?.length && (
                <Filter
                  filterValue={filterValue}
                  setFilterValue={setFilterValue}
                  filterKat={filterKat}
                  setFilterKat={setFilterKat}
                />
              )}
              <button
                type="button"
                data-tip={"Bearbeiten"}
                className="toyota-button toyota-button-blue"
                onClick={() => setModelModalVisible(true)}
              >
                <img src={Tools} alt="tools" />
              </button>
              <button
                type="button"
                data-tip={"Excel importieren"}
                className="toyota-button toyota-button-blue"
                onClick={() => setShowExcelModal(true)}
              >
                <img src={Excel} alt="Excel" />
              </button>

              <button
                type="button"
                onClick={() => setShowPFCModal(true)}
                data-tip={"Artikel wählen"}
                className="toyota-button toyota-button-blue"
              >
                <img src={Database} alt="PFC" />
              </button>
              <button
                type="button"
                data-tip={"Artikel geben"}
                onClick={() => setShowArticleModal(true)}
                style={{ fontSize: 18 }}
                className="toyota-button toyota-button-blue"
              >
                +
              </button>

              <button
                type="button"
                data-tip={"Model löschen"}
                onClick={() => setDeleteModalVisible(true)}
                className="toyota-button toyota-button-grey"
              >
                <img src={Delete} alt="Delete" />
              </button>
            </div>
          </div>
        </div>
        {showExcelModal && (
          <ExcelModal
            modelID={modelID}
            getArticles={getItems}
            discount={discount}
            setShowExcelModal={setShowExcelModal}
          />
        )}
        {showPFCModal && (
          <PFCModal
            modelID={modelID}
            getArticles={getItems}
            discount={discount}
            setShowPFCModal={setShowPFCModal}
          />
        )}
        {showArticleModal && (
          <ArticleModal
            modelID={modelID}
            getArticles={getItems}
            discount={discount}
            setShowArticleModal={setShowArticleModal}
          />
        )}
        {deleteModalVisible && (
          <LoscheModal
            setDeleteModalVisible={setDeleteModalVisible}
            kat="Artikel Model"
            name={modelName}
            entryID={modelID}
            refreshfunction={getDetailModels}
          />
        )}
        {modelModalVisible && (
          <ModelModal
            getDetailModels={getDetailModels}
            setShowModelModal={setModelModalVisible}
            arbeit={true}
            modelID={modelID}
            modelName={modelName}
          />
        )}
        {discountModalVisible && (
          <DiscountModal
            discountAll={discountAll}
            setDiscountAll={setDiscountAll}
            setDiscountModalVisible={setDiscountModalVisible}
            articleChecked={articleChecked}
            update={update}
            setUpdate={setUpdate}
            articles={articles}
            setArticleChecked={setArticleChecked}
          />
        )}

        {loading && (
          <div style={{ padding: "1rem" }}>
            <Loading height="20px" width="20px" />
          </div>
        )}
        {!loading && articles?.length ? (
          <div>
            {articleChecked.includes(true) && (
              <Toolsbar
                sortedArtikel={sortedArtikel}
                articleChecked={articleChecked}
                discountAll={discountAll}
                setDiscountAll={setDiscountAll}
                setDiscountModalVisible={setDiscountModalVisible}
                setDeleteArticleModalVisible={setDeleteArticleModalVisible}
              />
            )}

            {!loading && (
              <table>
                {!modelInView && (
                  <thead>
                    <tr>
                      <th>
                        <input
                          type="checkbox"
                          checked={checkedAll}
                          onChange={() => setCheckedAll(!checkedAll)}
                        />
                      </th>
                      {TabelHeadArray &&
                        TabelHeadArray.map((header, idx) => {
                          return (
                            <th
                              key={`th-${idx}`}
                              style={{
                                background:
                                  sortStatusArray[idx] !== false
                                    ? "#eee"
                                    : "white",
                              }}
                              id={`tabelheader-${idx}`}
                              onClick={() => artikelSort(header.sort, idx)}
                              className="artikel-title"
                            >
                              <span>{header.label}</span>
                              {sortStatusArray[idx] && (
                                <img
                                  style={{
                                    transform:
                                      sortStatusArray[idx] == "big"
                                        ? "rotate(180deg)"
                                        : "rotate(0deg)",
                                  }}
                                  src={DownArrow}
                                />
                              )}
                            </th>
                          );
                        })}

                      <th>Tools</th>
                    </tr>
                  </thead>
                )}
                {modelInView && (
                  <thead
                    style={{ position: "sticky", top: 48, background: "white" }}
                  >
                    <tr>
                      <th>
                        <input
                          type="checkbox"
                          checked={checkedAll}
                          onChange={() => setCheckedAll(!checkedAll)}
                        />
                      </th>
                      {TabelHeadArray &&
                        TabelHeadArray.map((header, idx) => {
                          return (
                            <th
                              key={`th-${idx}`}
                              style={{
                                background:
                                  sortStatusArray[idx] !== false
                                    ? "#eee"
                                    : "white",
                              }}
                              id={`tabelheader-${idx}`}
                              onClick={() => artikelSort(header.sort, idx)}
                              className="artikel-title"
                            >
                              <span>{header.label}</span>
                              {sortStatusArray[idx] && (
                                <img
                                  style={{
                                    transform:
                                      sortStatusArray[idx] == "big"
                                        ? "rotate(180deg)"
                                        : "rotate(0deg)",
                                  }}
                                  src={DownArrow}
                                />
                              )}
                            </th>
                          );
                        })}

                      <th>Tools</th>
                    </tr>
                  </thead>
                )}

                <tbody>
                  {sortedArtikel &&
                    sortedArtikel.map((item, idx) => {
                      if (
                        !filterValue ||
                        (filterValue &&
                          filterFunction(item, filterValue, filterKat))
                      ) {
                        return (
                          <Article
                            index={idx}
                            key={`articlegroup-${idx}`}
                            item={item}
                            update={update}
                            setUpdate={setUpdate}
                            articleDiscountArray={articleDiscountArray}
                            setArticleDiscountArray={setArticleDiscountArray}
                            articleChecked={articleChecked}
                            setArticleChecked={setArticleChecked}
                            setSortedArtikel={setSortedArtikel}
                            sortedArtikel={sortedArtikel}
                            setArticles={setArticles}
                          />
                        );
                      }
                    })}
                </tbody>
              </table>
            )}

            {deleteArticleModalVisible && (
              <ChainLoscheModal
                setDeleteModalVisible={setDeleteArticleModalVisible}
                kat="Ausgewählte Artikel Items"
                name={modelName}
                items={articles}
                update={update}
                setUpdate={setUpdate}
                articleChecked={articleChecked}
                setArticleChecked={setArticleChecked}
              />
            )}
          </div>
        ) : (
          !loading && (
            <div style={{ padding: "1rem" }}>Keine Artikel vorhanden.</div>
          )
        )}
      </div>
    </Waypoint>
  );
}
