import React from "react";

const Loader = ({ height, width }) => {
    return (
        <div
            className='loading-anime'
            style={{
                height: height ? height : "80px",
                width: width ? width : "80px",
            }}
        />
    );
};

export default Loader;
