import React, { useState } from "react";
import "../index.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";

export default function RightMenu({ title, url }) {
  const location = useLocation();
  const navigate = useNavigate();

  return <div className="sub-menu-item">{title}</div>;
}
