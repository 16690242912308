import React, { useEffect, useState } from "react";
import "./index.scss";
import { useData } from "../../../utils/dataProvider";
import List from "../../../assets/list.svg";
import { motion, AnimatePresence } from "framer-motion";

export default function Sidebar({ data, post, setPost }) {
  return (
    <div className="guide-menu">
      <div style={{ marginBottom: 12, display: "flex" }}>
        <img src={List} style={{ marginRight: 12 }} />
        <div>Inhaltverzeichnis</div>
      </div>
      {data &&
        Object.values(data).map((bigitem, index) => {
          return (
            <div style={{ marginTop: 24 }} key={`bigtitle-${index}`}>
              <div
                style={{
                  color:
                    bigitem.posts.findIndex((ele) => ele.id == post.id) >= 0
                      ? "black"
                      : "#727272",
                }}
                className="big-menu-title"
                onClick={() =>
                  setPost(bigitem.posts ? bigitem.posts[0] : bigitem.post)
                }
              >
                {bigitem.label}
              </div>
              <AnimatePresence initial={false}>
                {bigitem.posts.findIndex((ele) => ele.id == post.id) >= 0 && (
                  <motion.div
                    style={{ borderLeft: "1px solid #aaa", marginTop: 12 }}
                    initial="collapsed"
                    animate="open"
                    exit="collapsed"
                    variants={{
                      open: {
                        opacity: 1,
                        height: "auto",
                      },
                      collapsed: {
                        opacity: 0,
                        height: 0,
                      },
                    }}
                    transition={{
                      duration: 0.3,
                      ease: [0.04, 0.62, 0.23, 0.98],
                    }}
                  >
                    {bigitem.posts &&
                      bigitem.posts.map((smallitem, idx) => {
                        return (
                          <div
                            className="small-menu-title"
                            key={`smallTitle-${idx}`}
                            style={{
                              color:
                                smallitem.id == post.id ? "black" : "#727272",
                            }}
                            onClick={() => setPost(smallitem)}
                          >
                            {smallitem.title}
                          </div>
                        );
                      })}
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          );
        })}
    </div>
  );
}
