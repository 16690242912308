import React from "react";
import { useNavigate } from "react-router-dom";

export default function Articles({ data }) {
  const navigate = useNavigate();
  let EURO = new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
  });
  const getPart = (partNumber) => {
    let search = partNumber.replace("-", "");
    search = search.replace("-", "");
    navigate(`/article/${search}`);
  };
  return (
    <div>
      <table className="info-table">
        <thead>
          <tr>
            <td>Teilenummer</td>
            <td>Bezeichnung</td>
            <td>PFC Beschreibung</td>
            <td>Servicetype</td>
            <td>UPE Brutto</td>
            <td>UPE Netto</td>
            <td>WAP</td>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.length &&
            data.map((article, idx) => {
              return (
                <tr onClick={() => getPart(article.partNumber)}>
                  <td>{article.partNumber}</td>
                  <td>{article.partInfo?.originalName}</td>
                  <td>{article.partInfo?.pfcDescription}</td>
                  <td>{article.partInfo?.servicetype_name}</td>
                  <td>{EURO.format(article.priceInfo?.grossSalePrice)}</td>
                  <td>{EURO.format(article.priceInfo?.salePrice)}</td>
                  <td>{EURO.format(article.priceInfo?.retailPrice)}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
}
