import React from "react";

export default function Partinfo({ data }) {
  if (!data) return "";

  return (
    <table className="info-table">
      <tr>
        <td>Original Name</td>
        <td>{data.originalName}</td>
      </tr>
      <tr>
        <td>PFC</td>
        <td>{data.pfc}</td>
      </tr>
      <tr>
        <td>PFC Beschreibung</td>
        <td>{data.pfcDescription} </td>
      </tr>
      <tr>
        <td>Order Stop</td>
        <td>{data.orderstop} </td>
      </tr>
      <tr>
        <td>R A Code</td>
        <td>
          {data.r_a_code && data.r_a_code + " (" + data.racDescription + ")"}
        </td>
      </tr>
      <tr>
        <td>Substitute Nummer</td>
        <td>{data.substituteNumber}</td>
      </tr>
      <tr>
        <td>Servicetype Name</td>
        <td>{data.servicetype_name} </td>
      </tr>
      <tr>
        <td>In TOS</td>
        <td
          style={{
            color: data.inTOS == "false" ? "#ff0022" : "#58595b",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            className="tos_status"
            style={{
              backgroundColor: data.inTOS == "false" ? "#ff0022" : "#58595b",
            }}
          ></div>
          {data.inTOS == "false" ? "Nein" : "Ja"}
        </td>
      </tr>
    </table>
  );
}
